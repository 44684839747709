import { put, takeEvery, select } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";
import { loginRequestApi } from "../../services/apiCalls";
import { ILoginSuccessResponse } from "../../types";

export function* adminLogin(param: object) {
  try {
    const response: ILoginSuccessResponse = yield loginRequestApi(param);

    if (response && !response.message) {
      localStorage.setItem("currentUserId", JSON.stringify(response.id));
      localStorage.setItem("currentUser", JSON.stringify(response.email));
      localStorage.setItem("currentToken", JSON.stringify(response.token));
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(response.refreshToken)
      );
      yield put({ type: redTypes.ADMIN_LOGIN_SUCCESS, payload: response });
    } 
    else if (response.message){
      yield put({
        type: redTypes.ADMIN_LOGIN_FAILURE,
        errorMessage:response.message
        });
    }
    else{
      yield put({
        type: redTypes.ADMIN_LOGIN_FAILURE,
        errorMessage: "Something went wrong"
        });
    }
  } catch (e) {
    yield put({
      type: redTypes.ADMIN_LOGIN_FAILURE,
      errorMessage: "Something went wrong",
    });
  }
}

export default function* adminLoginSaga(): any {
  return yield takeEvery(redTypes.ADMIN_LOGIN_REQUEST, adminLogin);
}
