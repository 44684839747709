/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import './index.css'

const NotificationHeader: React.FC = () => {
  const location = useLocation()

  return (
    <div
    className='d-flex flex-column rounded-top'
   
  >
    <h3 className='fw-bold px-9 mt-10 mb-6'style={{color:'#fff'}}>
      Notifications <span className='fs-8 opacity-75 ps-3'>24 reports</span>
    </h3>
        <div className='d-flex '>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder px-4 flex-nowrap'>
          <li className='nav-item'style={{color:'#fff',paddingLeft:'45px'}}>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4'
            data-bs-toggle='tab'
          >
            charts <span className="badge badge-circle badge-info badge-sm">5</span>

          </a>
        </li>
        <li className='nav-item'style={{color:'#fff',paddingLeft:'45px'}}>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4'
            data-bs-toggle='tab'
          >
            Bills <span className="badge badge-circle badge-success badge-sm">7</span>


          </a>
        </li>
            {/* <li className='nav-item fs-5'style={{color:'#fff',paddingLeft:'45px'}}>
              <Link
                className={
                  `nav-link text-white text-active-primary me-6 ` +
                  (location.pathname === '' && 'active')
                }
                to=''
              >
                Alerts
              </Link>
            </li>
            <li className='nav-item fs-5'style={{color:'#fff',paddingLeft:'45px'}}>
              <Link
                className={
                  `nav-link text-white text-active-primary me-6 ` +
                  (location.pathname === '' && 'active')
                }
                to=''
              >
                Logs
              </Link>
            </li> */}
          </ul>
        </div>
        </div>
  )
}

export {NotificationHeader}
