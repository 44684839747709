// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import { useDispatch, useSelector } from "react-redux"
import { redTypes } from '../../../../modules/constants/reduxTypes'
import { Customerchart } from '../../../../modules/components/dashboard/chartMixWidget14'
type Props = {
  className: string
  chartHeight: string
  backGroundColor: string
}

const MixedWidget14: React.FC<Props> = ({className, backGroundColor, chartHeight = '150px'}) => {
  const dispatch = useDispatch();
  const chartRef = useRef<HTMLDivElement | null>(null)
  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const customerDetailData: any = useSelector<any>(
    (state) => state.billListReducer?.customerOverall
  );
// console.log('customerDetailData',customerDetailData);

  // React.useEffect(() => {
  //   dispatch({
  //     type: redTypes.CUSTOMER_OVERALL_DETAIL_REQUEST,
  //     payload: {id:loginSuccess.id,dummyData:'no data'},
  //   });
  // }, []);
  const {mode} = useThemeMode()

  // const refreshChart = () => {
  //   if (!chartRef.current) {
  //     return
  //   }

  //   const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight))
  //   if (chart) {
  //     chart.render()
  //   }

  //   return chart
  // }

  // useEffect(() => {
  //   const chart = refreshChart()

  //   return () => {
  //     if (chart) {
  //       chart.destroy()
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chartRef, mode])

  return (
    <div
      className={`card ${className} theme-dark-bg-body`}
      style={{backgroundColor: backGroundColor}}
    >
      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        {/* begin::Wrapper */}
        <div className='d-flex flex-column flex-grow-1'>
          {/* begin::Title                    */}
          <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
            Customers
          </a>

          {/* end::Title */}

          <div
            className='mixed-widget-14-chart'
            style={{height: chartHeight, minHeight: chartHeight}}
          >
            <Customerchart/>
          </div>
        </div>
        {/* end::Wrapper */}

        {/* begin::Stats */}
        <div className='pt-5'>
          {!customerDetailData ?<div className='fw-semi-bold fs-6'>No data found</div>:
          <span className='text-dark fw-bolder fs-3x me-2 lh-0'>{customerDetailData.count}</span>}
          {/* end::Number */}

          {/* begin::Text */}
          <span className='text-dark fw-bolder fs-6 lh-0'>this month</span>
          {/* end::Text */}
        </div>
        {/* end::Stats */}
      </div>
    </div>
  )
}

// const chartOptions = (chartHeight: string): ApexOptions => {
//   const labelColor = getCSSVariableValue('--bs-gray-800')

//   return {
//     series: [
//       {
//         name: 'Inflation',
//         data: [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,],
//       },
//     ],
//     chart: {
//       fontFamily: 'inherit',
//       height: chartHeight,
//       type: 'bar',
//       toolbar: {
//         show: false,
//       },
//     },
//     grid: {
//       show: false,
//       padding: {
//         top: 0,
//         bottom: 0,
//         left: 0,
//         right: 0,
//       },
//     },
//     colors: ['#ffffff'],
//     plotOptions: {
//       bar: {
//         borderRadius: 2.5,
//         dataLabels: {
//           position: 'top', // top, center, bottom
//         },
//         columnWidth: '20',
//       },
//     },
//     dataLabels: {
//       enabled: false,
//       formatter: function (val) {
//         return val + ''
//       },
//       offsetY: -20,
//       style: {
//         fontSize: '12px',
//         colors: ['#304758'],
//       },
//     },
//     xaxis: {
//       labels: {
//         show: false,
//       },
//       categories:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
//       //  [
//       //   'Jan',
//       //   'Feb',
//       //   'Mar',
//       //   'Apr',
//       //   'May',
//       //   'Jun',
//       //   'Jul',
//       //   'Aug',
//       //   'Sep',
//       //   'Oct',
//       //   'Nov',
//       //   'Dec',
//       //   'Jan',
//       //   'Feb',
//       //   'Mar',
//       // ],
//       position: 'top',
//       axisBorder: {
//         show: false,
//       },
//       axisTicks: {
//         show: false,
//       },
//       crosshairs: {
//         show: false,
//       },
//       tooltip: {
//         enabled: false,
//       },
//     },
//     yaxis: {
//       // min: 0,
//       // max: 10,
//       show: false,
//       axisBorder: {
//         show: false,
//       },
//       axisTicks: {
//         show: false,
//         background: labelColor,
//       },
//       labels: {
//         show: false,
//         formatter: function (val) {
//           return val + ''
//         },
//       },
//     },
//   }
// }

export {MixedWidget14}
