import React from "react";
import { useEffect,useState } from "react";
import { lazy, FC, Suspense } from "react";
import { Navigate, useNavigate, Route, Routes, Outlet } from "react-router-dom";
import "./App.css";
import { Login } from "./modules/components/login";
//import { PrivateRoutes } from './modules/routing/privateRouters';
import { Registration } from "./modules/components/registration";
import { useSelector } from "react-redux";
import { VerificationCode } from "./modules/components/verifyCode/verifycode";
import { Spinner } from "./modules/components/spinner";
import { BillListTable } from "./modules/components/pages/billsList/billList";
import { DashboardWrapper } from "./modules/components/dashboard";
import { Sidebar } from "./_metronic/layout/components/sidebar";
import { Content } from "./_metronic/layout/components/content";
import { HeaderWrapper } from "./_metronic/layout/components/header";
import { ToolbarWrapper } from "./_metronic/layout/components/toolbar";
import { CustomerDetails } from "./modules/components/pages/customerList";
import { BillListPopUp } from "./modules/components/pages/billsList/billListPopUp";
import { AdminUser } from "./modules/components/pages/adminUser";
import { Success } from "./modules/components/pages/adminUser/success";
import { Roles } from "./modules/components/pages/roles";
import { AdminLogin } from "./modules/components/login/adminLogin";
import { Error403 } from "./modules/errors/Error403";
import { Error404 } from "./modules/errors/Error404";
import { ErrorBoundary } from "./modules/errors/ErrorBoundary";
import { Profile } from "./modules/components/pages/profile";
import { CustomerPopUp } from "./modules/components/pages/customerList/customerPopUp";
import { CustomerBills } from "./modules/components/pages/customerList/customerBills";
import { Chat } from "./modules/components/pages/chat";
import { Stores } from "./modules/components/pages/store";
import { Settings } from "./modules/components/pages/settings";
import { ApiKeys } from "./modules/components/pages/apiKeys";
import { Offers } from "./modules/components/pages/offers_ui";
import { onMessage, isSupported, MessagePayload } from "firebase/messaging";
import { firebaseConfig } from "../src/modules/components/firebaseConfig/firebaseConfig";
import { initializeApp } from "firebase/app";
import { getMessaging, onBackgroundMessage } from "firebase/messaging/sw";
import {  messaging } from "./FirebaseConfig";
import Review from "./modules/components/pages/store/review";
// import { ChatView } from "./modules/components/pages/store/chatView";
import { StoreHeader } from "./modules/components/pages/store/storeHeader";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Modal, Button } from "react-bootstrap";
import {ChatViewForAll} from '../src/_metronic/layout/chat/index'
import { NotificationChart } from "./modules/components/pages/notification/notificationChart";
// console.log("app.js")

//console.log("foregrou ndHandler")
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app)
// isSupported().then((val)=>{
//   console.log("isSupport",val)
//   if (val) {
//     console.log("messaging",messaging)
//     onMessage(messaging, (payload) => {
//       console.log("message from foreground", payload)
//       window.alert("hello")
//     });
//   }
//   else{
//     console.log("notSupported")
//   }
// })





const PrivateRoutes = () => {
  console.log("privateRoutes called successfully");
  const currentUser: any = useSelector<any>(
    (state) => state.authReducer?.isAuthenticated
  );
  // console.log("currentUser", currentUser);

  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  // console.log('AppLoginsucces',loginSuccess);

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  } else {
    return (
      <>
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
          <div
            className="app-page flex-column flex-column-fluid "
            id="kt_app_page"
          >
            <HeaderWrapper />
            <div className="separator separator-dashed border-secondary hide-on-print"></div>
            <div
              className="app-wrapper flex-column flex-row-fluid  "
              style={{ backgroundColor: "#f4f4f4" }}
              id="kt_app_wrapper"
            >
              <Sidebar />

              <div
                className="app-main flex-column flex-row-fluid"
                id="kt_app_main"
              >
                <div className="d-flex flex-column flex-column-fluid">
                  {/* <ToolbarWrapper/> */}
                  <Content>
                    <Outlet />
                  </Content>
                </div>
                {/* <FooterWrapper /> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};
const App: FC = () => {
  console.log("App called successfully");
  const navigate =useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [newMessageContent, setNewMessageContent] = useState("");
  const [chatMessage, setChatMessage] = useState("");

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onMessageListener = (async () => {
    console.log("foreground handler")
    const messagingResolve = await messaging;
    if (messagingResolve) {
      onMessage(messagingResolve, (payload: MessagePayload) => {
        console.log("message",payload)
        // alert("New message arrived");
        const chatmessage:any=payload?.data
        const message:any=payload?.notification?.body
        setNewMessageContent(message);
        setChatMessage(chatmessage); 
        handleShowModal();
        // setNotificationPayload([{ data: payload, open: true }]);
        // setTimeout(() => setNotificationPayload([{ open: false }]), 6000);
      });
    }
  })();

  // const onMessageListener = (async () => {
  //   console.log("foreground listner")
  //   const app = initializeApp(firebaseConfig);
  //   const messaging = getMessaging(app)
  //   if (messaging) {
  //     console.log("Messaging", messaging)
  //     onMessage(messaging, (payload: MessagePayload) => {
  //       console.log("message from foreground", payload)
  //       window.alert("hello")
  //     });
  //   } else {
  //     console.log("foregroundError")
  //   }
  // })();
  const currentUser: any = useSelector<any>(
    (state) => state.authReducer?.isAuthenticated
  );
  //console.log("currentUser", currentUser);
  const rolesSuccess: any = useSelector<any>(
    (state) => state.authReducer?.roles
  );
  // const customerDetailData: any = useSelector<any>(
  //   (state) => state.billListReducer?.customerOverall
  // );
  // console.log('customerData',customerDetailData);
  // for (var j = 0; j < customerDetailData?.getUserMerchant?.length; j++) {
  //   var dataItem = customerDetailData?.getUserMerchant[j];
  //   // console.log('id',dataItem);
  //   var id = dataItem.id;
  //   console.log('id',id);

  // }
  // console.log('idcustomer',id);

  // const customerView =`/customer/view/${id}`
  // const customerBills =`/customer/bills/${id}`

  let rolesArray = [];
  if (rolesSuccess) {
    rolesArray = rolesSuccess.split(",");
  }

  return (
    <>
    
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="*" element={<Navigate to="/error/404" />} />
      <Route path="/error/404" element={<Error404 />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/verifyCode" element={<VerificationCode />} />
      <Route path="/spinner" element={<Spinner />} />

      <Route element={<PrivateRoutes />}>
        <Route path='/login' element={<Navigate to='/dashboard' />} />
        {rolesSuccess ? (
          <>
            <Route index element={<Navigate to="/dashboard" />} />
            {rolesArray.includes("dashboard") ? (
              <Route path="/dashboard" element={<DashboardWrapper />} />
            ) : null}
            {rolesArray.includes("bills") ? (
              <Route path="/bills" element={<BillListTable />} />
            ) : null}
            {rolesArray.includes("bills") ? (
              <Route path="/bills/invoice/:id" element={<BillListPopUp />} />
            ) : null}
            {rolesArray.includes("customers") ? (
              <Route path="/customers" element={<CustomerDetails />} />
            ) : null}
            {rolesArray.includes("customers") ? (
              <Route path="/customer/view" element={<CustomerPopUp />} />
            ) : null}
            {rolesArray.includes("customers") ? (
              <Route path="/customer/bills" element={<CustomerBills />} />
            ) : null}
            {rolesArray.includes("profile") ? (
              <Route path="/Profile" element={<Profile />} />
            ) : (
              <Route path="/Profile" element={<Profile />} />
            )}
            <Route path="/adminUser" element={<Navigate to="/error/403" />} />
            <Route path="/error/403" element={<Error403 />} />
          </>
        ) : (
          <>
            <Route index element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<DashboardWrapper />} />
            <Route path="/bills" element={<BillListTable />} />
            <Route path="/bills/invoice/:id" element={<BillListPopUp />} />
            <Route path="/customers" element={<CustomerDetails />} />
            <Route path="/customer/view" element={<CustomerPopUp />} />
            <Route path="/customer/bills" element={<CustomerBills />} />
            <Route path="/adminUsers" element={<AdminUser />} />
            <Route path="/success" element={<Success />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/stores" element={<Stores />} />
            <Route path="/stores/view" element={<Review />} />
            <Route path="/stores/review" element={<Review />} />
           
            <Route path="/apiKeys" element={<ApiKeys />} />
            <Route path="/offers" element={<Offers />} />
            <Route path="/chat/view" element={<Chat />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/chats" element={<ChatViewForAll/>} />
            <Route path="/notification/chart" element={<NotificationChart/>} />
          </>
        )}
        
      </Route>
    </Routes>
    <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>New Message Received</Modal.Title>
          </Modal.Header>
          <Modal.Body>{newMessageContent}</Modal.Body>
          <Modal.Footer>
          <Button
    variant="primary"
    onClick={() => {
      handleCloseModal();
      navigate('/chat/view', { state: { messagePayload: chatMessage } }); // Navigate to the desired route
    }}
  >
    Open
  </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            {/* Add any additional buttons or actions here */}
          </Modal.Footer>
        </Modal>

    </>
  );
};

export default App;
