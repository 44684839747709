/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, useLocation} from 'react-router-dom'


const LoginHeader: React.FC = () => {
  const location = useLocation()

  return (

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder px-4 flex-nowrap'>
            <li className='nav-item fs-2'style={{color:'#009ef7'}}>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/login' && 'active')
                }
                to='/login'
              >
                Merchant Login 
              </Link>
            </li>
            <li className='nav-item fs-2'style={{color:'#009ef7',paddingLeft:'45px'}}>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/admin/login' && 'active')
                }
                to='/admin/login'
              >
                Admin Login
              </Link>
            </li>
           
          </ul>
        </div>

  )
}

export {LoginHeader}
