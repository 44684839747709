import { FC } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { redTypes } from "../../../constants/reduxTypes";

const ErrorBoundaryOffers: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const errorOffers: any = useSelector<any>((state) => state.billListReducer?.errorOffers);
  // console.log("errorAdminUser", errorAdminUser);
  const errorCodeOffers: any = useSelector<any>(
    (state) => state.billListReducer?.errorCodeOffers
  );
  const handleClick = () => {
    // dispatch({ type: redTypes.RESET_CUSTOMER_DATA });
    // dispatch({ type: redTypes.RESET_SET_STATE });
    // navigate("/billList");
  };
  const handleReturnClick = () => {
    dispatch({ type: redTypes.RESET_SET_STATE });
    navigate("/dashboard");
  };

  return (
    <>
      <div className="d-flex  justify-content-center align-item-center text-center p-10">
        <div className="card card-flush  w-lg-650px py-5">
          <div className="card-body py-15 py-lg-20">
            {/* begin::Title */}
            <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Error!</h1>
            {/* end::Title */}
            {errorOffers ? (
              <div className="fw-semibold fs-4 text-gray-500 mb-5">
              ErrorCode - {errorCodeOffers} : {errorOffers}
            </div>):(
            <div className="fw-semibold fs-4 text-gray-500 mb-5">
              Data not found !!
            </div>)}
            <div className="mb-3">
              <img
                src={require("../../../images/error.png")}
                className="mw-50 mh-150px theme-light-show"
                alt=""
              />
            </div>
            <div className="mb-0">
            
              <button
                type="button"
                className="btn btn-bg-success"
                style={{ color: "white" }}
                onClick={handleReturnClick}
              >
                Return Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ErrorBoundaryOffers };
