// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState,useCallback} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import { useDispatch, useSelector } from "react-redux"
import { IBillListSuccessResponse } from '../../../../modules/types/commonTypes'
import { redTypes } from '../../../../modules/constants/reduxTypes'
import { Billschart } from '../../../../modules/components/dashboard/chartMixWidget13'


type Props = {
  className: string
  chartHeight: string
  backGroundColor: string
}

const MixedWidget13: React.FC<Props> = ({className, backGroundColor, chartHeight}) => {
  const dispatch = useDispatch();
  // const chartRef = useRef<HTMLDivElement | null>(null)
  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
//   const [dateCreate, setDateCreate] = useState<string | undefined>(
//     "previous-month"
//   );
//   const[graphDataAmount,setGraphDataAmount] = useState<any>([])
//  // console.log('group',graphDataAmount);
  
//   const[items,setItems] = useState<any>([])
  //console.log('itemgroup',items);
  const billListData: any = useSelector<any>(state => state.billListReducer?.response);
  const billsDashboardData: any = useSelector<any>(
    (state) => state.billListReducer?.billsDashboard
  );
 // console.log("billsDashboardData", billsDashboardData);
  React.useEffect(() => {
    dispatch({
      type: redTypes.BILL_LIST_TABLE_REQUEST,
      payload: {domain:loginSuccess.domain,dummyData:'no data'},
    });
  }, []);


  
//   const date = new Date();
//   const month = date.getMonth() + 1;
//   const currentYear = date.getFullYear();
//   const days = new Date(currentYear, month, 0).getDate();
//   const previousMonth =month -1;
//   // console.log('previousMonth',previousMonth);
//   const previousDays = new Date(currentYear, previousMonth, 0).getDate();
// //  console.log('previousDays',previousDays);

//   var year = date.getFullYear();

//   React.useEffect(() => { 
//     //console.log('called useeffect')
//   var datesArray = [];

//   date.setDate(1);
//   while (date.getMonth() === previousMonth) {
//     var dates = date.getDate();
//     //  console.log('date',date);
//     var formattedDate =
//        year + "-" + ("0" + previousMonth).slice(-2) + "-" + ("0" + dates).slice(-2);
//      // year + '-' + previousMonth.toString().padStart(2, '0') + '-' + date.toString().padStart(2, '0');
//     datesArray.push(formattedDate);
//     date.setDate(dates + 1);
//   }

//  //console.log('datesArrays',datesArray);

//   var graphAmountData: any = [];
//   for (var k = 0; k < datesArray.length; k++) {
//     graphAmountData[k] = 0;
//   }
//   // console.log("graphAmountData", graphAmountData);
//   for (var j = 0; j < billsDashboardData.length; j++) {
//     var dataItem = billsDashboardData[j];
//     var groupDay = dataItem.group_day;
//     //console.log('groupDay',groupDay);
//     var totalValue = parseInt(dataItem.total_value);
//     var dateIndex = datesArray.findIndex(function (date: any) {
//       // console.log('dateIndex1',date);
//       return date === groupDay;
//     });
//     if (dateIndex !== -1) {
//       graphAmountData[dateIndex] = totalValue;
//     }
//   }
//   console.log("graphAmountData", graphAmountData);
//   if(graphAmountData.length>0){
//   //  console.log('change states')
//     setGraphDataAmount(graphAmountData)
//   }
//   else{
//    // console.log('null accept')
//   }
   
//   let itemData: any = [];
//   for (let i = 1; i <= previousDays; i++) {
//     itemData.push(i);
// }
// //console.log('item',itemData);
//  setItems(itemData)
// }, [billsDashboardData]);


//   React.useEffect(() => {
//     dispatch({
//       type: redTypes.BILLS_DASHBOARD_REQUEST,
//       payload: {
//         domain: loginSuccess.domain,
//         data: dateCreate,
//         downloadCsv: false,
//       },
//     });
//   }, [dateCreate]);


let totalAmount = 0;
const datas = billListData.getBillAmount?.forEach(element => {
  var amount = element.bill_amounts?.total_invoice_value
  if (amount) {
    totalAmount += parseFloat(amount);
    // console.log('element',totalAmount);
  }
});
totalAmount = totalAmount.toFixed(2);
//console.log('totalAmount',totalAmount);
  const {mode} = useThemeMode()

//   useEffect(() => {
//     const chart = refreshChart()

//     return () => {
//       if (chart) {
//         chart.destroy()
//       }
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [chartRef, mode])

//   const refreshChart = () => {
//     if (!chartRef.current) {
//       return
//     }
// console.log('call the apexchart');
// if(graphDataAmount){
//   const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight,items,graphDataAmount))
//   if (chart) {
//     chart.render()
//   }

//   return chart
// }
// }
    

  return (
    <div
      className={`card ${className} theme-dark-bg-body`}
      style={{backgroundColor: backGroundColor}}
    >
      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        {/* begin::Wrapper */}
        <div className='d-flex flex-column flex-grow-1'>
          {/* begin::Title                    */}
          <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
            Total Sales
          </a>
          {/* end::Title */}

          <div
            className='mixed-widget-13-chart'
            style={{height: chartHeight, minHeight: chartHeight}}
          >
            <Billschart/>
          </div>
        </div>
        {/* end::Wrapper */}

        {/* begin::Stats */}
        <div className='pt-12'>
          {/* begin::Symbol */}
          {/* <span className='text-dark fw-bolder fs-2x lh-0'>k</span> */}
          {/* end::Symbol */}

          {/* begin::Number */}
          {/* {billListData &&
                            billListData.bill_amounts?.map((val: IBillListSuccessResponse) => {
                              console.log('values1', val)
                              return ( */} <span className='text-dark fw-bolder fs-2x lh-0'>$</span>
          <span className='text-dark fw-bolder fs-3x me-2 lh-0'>{totalAmount}</span>
         
                            {/* )}
                             )}  */}

          {/* begin::Text */}
          {!billListData ?<div className='fw-semi-bold fs-6'>No data found</div>:
           <span className='text-dark fw-bolder px-2 fs-6 lh-0'>({billListData?.getBillAmount?.length} New Bills) </span>}
          {/* end::Text */}
        </div>
        {/* end::Stats */}
      </div>
    </div>
  )
}

// const chartOptions = (chartHeight: string,items,graphDataAmount): ApexOptions => {
//   const labelColor = getCSSVariableValue('--bs-gray-800')
//   const strokeColor = getCSSVariableValue('--bs-gray-300') as string
// //console.log('graphAmountData34',graphDataAmount);
// //console.log('item34',items);
//   return {
//     series: [
//       {
//         name: 'Net Profit',
//         data: graphDataAmount,
//         // data:[20,30,0,50,5,60,2,34,0,98,0,0]
//       },
//     ],
//     grid: {
//       show: false,
//       padding: {
//         top: 0,
//         bottom: 0,
//         left: 0,
//         right: 0,
//       },
//     },
//     chart: {
//       fontFamily: 'inherit',
//       type: 'area',
//       height: chartHeight,
//       toolbar: {
//         show: false,
//       },
//       zoom: {
//         enabled: false,
//       },
//       sparkline: {
//         enabled: true,
//       },
//     },
//     plotOptions: {},
//     legend: {
//       show: false,
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     fill: {
//       type: 'gradient',
//       gradient: {
//         opacityFrom: 0.4,
//         opacityTo: 0,
//         stops: [20, 120, 120, 120],
//       },
//     },
//     stroke: {
//       curve: 'smooth',
//       show: true,
//       width: 3,
//       colors: ['#FFFFFF'],
//     },
//     xaxis: {
//        //categories: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
//       categories: items,
//       axisBorder: {
//         show: false,
//       },
//       axisTicks: {
//         show: false,
//       },
//       labels: {
//         show: false,
//         style: {
//           colors: labelColor,
//           fontSize: '12px',
//         },
//       },
//       crosshairs: {
//         show: false,
//         position: 'front',
//         stroke: {
//           color: strokeColor,
//           width: 1,
//           dashArray: 3,
//         },
//       },
//       tooltip: {
//         enabled: true,
//         formatter: undefined,
//         offsetY: 0,
//         style: {
//           fontSize: '12px',
//         },
//       },
//     },
//     yaxis: {
//       min: 0,
//       max: 70000,
//       labels: {
//         show: false,
//         style: {
//           colors: labelColor,
//           fontSize: '12px',
//         },
//       },
//     },
//     states: {
//       normal: {
//         filter: {
//           type: 'none',
//           value: 0,
//         },
//       },
//       hover: {
//         filter: {
//           type: 'none',
//           value: 0,
//         },
//       },
//       active: {
//         allowMultipleDataPointsSelection: false,
//         filter: {
//           type: 'none',
//           value: 0,
//         },
//       },
//     },
//     tooltip: {
//       style: {
//         fontSize: '12px',
//       },
//       y: {
//         formatter: function (val) {
//           return '$' + val + ' thousands'
//         },
//       },
//     },
//     colors: ['#ffffff'],
//     markers: {
//       colors: [labelColor],
//       strokeColor: [strokeColor],
//       strokeWidth: 3,
//     },
//   }
// }

export {MixedWidget13}
