import { useSelector,useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { Settings } from "../../../../modules/components/pages/settings";
// import { redTypes } from '../../../../modules/constants/reduxTypes'
import { Modal } from 'react-bootstrap';
// import { SidebarMenuItem } from "../../../layout/components/sidebar/sidebar-menu/SidebarMenuItem";

type Props = {
  show: boolean
  handleClose: () => void
}
const SwitchSettingPage = ({show, handleClose}: Props) => {
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const currentUser :any= useSelector<any>(state=> state.authReducer?.response);
 
  const leavepage=()=>{
    navigate('/settings');
    handleClose();
  }
    return (
 
      <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-350px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
     <div className='modal-title fs-6 fw-bold d-flex justify-content-center'>
     <span style={{paddingTop:8}}> Are you sure want to leave to Settings page?</span>
     </div>
     <div  className="modal-body d-flex justify-content-end fw-semibold py-3">
     <a href="#"  className='btn'style={{color:'#1976d2'}} onClick={leavepage}>Leave</a>
     <a href="#"  className='btn'style={{color:'#1976d2'}}onClick={handleClose}>Stay</a>
     </div>
    </Modal>
    )

}

export {SwitchSettingPage}
