import { put, takeEvery, select } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";
import { apiKeysApi } from "../../services/commonApiCall";
import { IApiKeys } from "../../types/commonTypes";

export function* apiKeysGenerate(param: object) {
console.log('apiKeysGenerate',param);

  try {
    const response: IApiKeys = yield apiKeysApi(param);

    if (response && !response.message) {

      yield put({ type: redTypes.API_KEYS_GENERATE_SUCCESS, payload: response });
    } else if (response.message) {
      yield put({
        type: redTypes.API_KEYS_GENERATE_FAILURE,
        errorMessage: response.message,
      });
    }
    else {
      yield put({
        type: redTypes.API_KEYS_GENERATE_FAILURE,
        errorMessage: "Something went wrong",
      });
    }

  } catch (e) {
    yield put({
      type: redTypes.API_KEYS_GENERATE_FAILURE,
      errorMessage: "Something went wrong",
    });
  }
}

export default function* apiKeysGenerateSaga(): any {
  return yield takeEvery(redTypes.API_KEYS_GENERATE_REQUEST, apiKeysGenerate);
}
