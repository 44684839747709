import { deleteRoleResponse } from "../../types/commonTypes";
import { deleteRoleApi } from "../../services/apiCalls";
import { put, takeEvery, call, select } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";

function* deleteRolesSaga(param: object) {
  try {
    const response: deleteRoleResponse = yield deleteRoleApi(param);
    if (response) {
      // console.log(" delete  response", response);
      yield put({ type: redTypes.DELETE_ROLE_SUCCESSFULLY, payload: response });
    }
  } catch (e) {
    console.log("error", e);
  }
}
export default function* deleteRoleSaga(): any {
  return yield takeEvery(redTypes.DELETE_ROLES, deleteRolesSaga);
}
