import React,{ FC } from 'react'
import {useState,useRef} from 'react'
import {  useSelector,useDispatch } from 'react-redux';
import { useNavigate,useLocation } from 'react-router-dom';
import moment from "moment";
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {KTIcon} from '../../../../_metronic/helpers'
import { adminCreation } from '../../../actions/apiRequests';
import { IAdminUser, IAdminUserCreate, IStores, getRolesResponse } from '../../../types/commonTypes';
import { redTypes } from '../../../constants/reduxTypes';
import { getNames } from "country-list";



type Props = {
  show: boolean
  data?:any
  handleClose: () => void
}


const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
   phone: Yup.string()
    .min(10, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  // password: Yup.string()
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols'),
  //   // .required('Password is required'),
  username: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('username  is required'),
  address1: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('address1  is required'),
  address2: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('address2  is required'),
  locality: Yup.string()
    .required('locality  is required'),
  city: Yup.string()
    .required('city  is required'),
  state: Yup.string()
    .required('state  is required'),
  country: Yup.string()
    .required('country  is required'),
    role:Yup.number()
    .required('Role  is required'),
    store_id: Yup.string().required("Store  is required"),

})

export const CreateAdminUser = ({show, handleClose,data}: Props) => {
const dispatch = useDispatch();
const navigate = useNavigate();
const countries = getNames();
const loginSuccess: any = useSelector<any>(state => state.authReducer?.loginSuccess);
const adminCreationSuccess: any = useSelector<any>(state => state.billListReducer?.adminCreationSuccess);
//console.log('adminCreationSuccess',adminCreationSuccess)
const roleSuccess: any = useSelector<any>(state => state.billListReducer?.roleSuccess);
//console.log('roleSuccess',roleSuccess)
const storeData: any = useSelector<any>(
  (state) => state.billListReducer?.storeSuccess
);
const errorAdminCreate: any = useSelector<any>((state) => state.billListReducer?.errorAdminCreate);
const errorCodeAdminCreate: any = useSelector<any>(
  (state) => state.billListReducer?.errorCodeAdminCreate
);

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const[input,setInput]=useState<IAdminUserCreate>(data?data:{
    id:0,
    firstname: '',
    lastname: '',
    username:'',
    phone:'',
    email: '',
    password: '',
    address1:'',
    address2:'',
    locality:'',
    city:'',
    state:'',
    country:'',
    role:'',
    store_id: "",
    created_by: "",
    updated_by: "",
})
  const { handleSubmit, values, handleChange, errors, touched, handleBlur, status, setStatus } = useFormik({
     initialValues:{
                id:input.id,
                username:input.username,
                firstname: input.firstname,
                lastname: input.lastname,
                email:input.email,
                phone:input.phone,
                password:  input.password,
                address1:input.address1,
                address2:input.address2,
                locality:input.locality,
                city:input.city,
                state:input.state,
                country:input.country,
                role:input.role,
                store_id: input.store_id,
                // created_by:loginSuccess.domain ||input.created_by,
                // updated_by: input.updated_by,
    },
    validationSchema: registrationSchema,
    onSubmit: async (values:any) => {
   if(values?.id == 0){
    console.log('adminCreate');
    const data ={
      id:values.id,
            email:values.email,
            phone:values.phone,
            username:values.username,
            lastname: values.lastname,
            firstname: values.firstname,
            password:  values.password,
            address1:values.address1,
            address2:values.address2,
            locality:values.locality,
            city:values.city,
            state:values.state,
            country:values.country,
            role:parseInt(values.role),
            store_id: parseInt(values.store_id),
            // store_id:1,
            status:true,
            created_by:loginSuccess.id,
            subdomains:loginSuccess.domain

      }
      dispatch(adminCreation(data))
    }
    else{
      console.log('adminUpdate');
      
      const updateData ={
        // id:data.id,
        id:values.id,
        email:values.email,
        phone:values.phone,
        username:values.username,
        lastname: values.lastname,
        firstname: values.firstname,
        address1:values.address1,
        address2:values.address2,
        locality:values.locality,
        city:values.city,
        state:values.state,
        country:values.country,
        role:parseInt(values.role),
        store_id: parseInt(values.store_id),
        // store_id:1,
        status:true,
        updated_by: loginSuccess.id,
        subdomains:loginSuccess.domain

  }
  dispatch(adminCreation(updateData))

    }

        },
  })
  React.useEffect(() => {
    console.log("successfully adminCreation !!");
    if(adminCreationSuccess){
      // navigate('/success')  
      handleClose();
      dispatch({ type: redTypes.RESET_SET_STATE });
       dispatch({ type: redTypes.RESET_ADMIN_USER  });   
        }
      },[adminCreationSuccess])
      React.useEffect(() => {
        if (errorCodeAdminCreate) {
          setTimeout(() => {
            dispatch({
            type: redTypes.RESET_SET_STATE,
          });
          }, 5000);
        }
      }, [errorCodeAdminCreate]);
return(
    <>

    <Modal
      id='kt_modal_create_admin'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      backdrop={true}
    >
      
 <div className='modal-header'>
        {data?<h2>Admin Update</h2>:<h2>Admin creation</h2>}
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-20 '>
      {/* <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      // onSubmit={handleSubmit}
    > */}
        {/* <div className='fv-row mb-7'> */}
        <div className='row g-9 mb-7'>
          <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>UserName</label>
          <input type="text" placeholder='username'name='username'
          className={clsx('form-control form-control-solid',
           {'is-invalid': touched.username && errors.username},
           {
             'is-valid': touched.username && !errors.username,
           }
         )}
           value={values.username}
           onChange={handleChange}
           onBlur={handleBlur}
           />
           {touched.username && errors.username && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.username}</span>
            </div>
          </div>
        )}
          </div>
          <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>Email</label>
          <input type="email" name='email' placeholder='email' className={clsx('form-control form-control-solid',
           {'is-invalid': touched.email && errors.email},
           {
             'is-valid': touched.email && !errors.email,
           }
         )}
           value={values.email}
           onChange={handleChange}
           onBlur={handleBlur}
           />
           {touched.email && errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.email}</span>
            </div>
          </div>
        )}
          </div>
        </div>

        <div className='row g-9 mb-7'>
          <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>Phone</label>
          <input type="text" name='phone' placeholder='phone' className={clsx('form-control form-control-solid',
           {'is-invalid': touched.phone && errors.phone},
           {
             'is-valid': touched.phone && !errors.phone,
           }
         )}
           value={values.phone}
           onChange={handleChange}
           onBlur={handleBlur}
           />
           {touched.phone && errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.phone}</span>
            </div>
          </div>
        )}
          </div>
        {!data?  <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>Password</label>
          <input type="password" name='password' placeholder='password' className={clsx('form-control form-control-solid',
           {'is-invalid': touched.password && errors.password},
           {
             'is-valid': touched.password && !errors.password,
           }
         )}
           value={values.password}
           onChange={handleChange}
           onBlur={handleBlur}
           />
           {touched.password && errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.password}</span>
            </div>
          </div>
        )}
          </div>:
              <div className='col-md-6 fv-row fv-plugins-icon-container'>
              <label className=' fs-6 fw-semibold mb-2'>Store</label>
              <select  aria-label="Select example"name='store_id' className={clsx("form-select form-select-solid",
                {'is-invalid': touched.store_id && errors.store_id},
                {
                  'is-valid': touched.store_id && !errors.store_id,
                }
              )}
                value={values.store_id}
                onChange={handleChange}
                onBlur={handleBlur}
                >
                <option value='' disabled selected hidden>Store</option>
                    {storeData.getStores
                      ? storeData.getStores?.map((survey: IStores) => (
                          <option key={survey.name} value={survey.id}>
                            {survey.name}
                          </option>
                        ))
                      : null}
              </select>
              {touched.store_id && errors.store_id && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                <span role='alert '>{errors.store_id}</span>
                </div>
              </div>
            )}   
              </div>
          }
        </div>

        <div className='row g-9 mb-7'>
          <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>FirstName</label>
          <input type="text" placeholder='firstname'name='firstname'
           className={clsx('form-control form-control-solid',
           {'is-invalid': touched.firstname && errors.firstname},
           {
             'is-valid': touched.firstname && !errors.firstname,
           }
         )}
           value={values.firstname}
           onChange={handleChange}
           onBlur={handleBlur}
           />
              {touched.firstname && errors.firstname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.firstname}</span>
            </div>
          </div>
        )}
          </div>
          <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>LastName</label>
          <input type="text" placeholder='lastname'name='lastname' className={clsx('form-control form-control-solid',
           {'is-invalid': touched.lastname && errors.lastname},
           {
             'is-valid': touched.lastname && !errors.lastname,
           }
         )}
           value={values.lastname}
           onChange={handleChange}
           onBlur={handleBlur}
           />
              {touched.lastname && errors.lastname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.lastname}</span>
            </div>
          </div>
        )}
          </div>
        </div>
     
        <div className='row g-9 mb-7'>
          <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>Address1</label>
          <input type="text" placeholder='address1' name='address1' className={clsx('form-control form-control-solid',
           {'is-invalid': touched.address1 && errors.address1},
           {
             'is-valid': touched.address1 && !errors.address1,
           }
         )}
           value={values.address1}
           onChange={handleChange}
           onBlur={handleBlur}
           />
              {touched.address1 && errors.address1 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.address1}</span>
            </div>
          </div>
        )}
          </div>
          <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>Address2</label>
          <input type="text" placeholder='address2'name='address2' className={clsx('form-control form-control-solid',
           {'is-invalid': touched.address2 && errors.address2},
           {
             'is-valid': touched.address2 && !errors.address2,
           }
         )}
           value={values.address2}
           onChange={handleChange}
           onBlur={handleBlur}
           />
              {touched.address2 && errors.address2 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.address2}</span>
            </div>
          </div>
        )}
          </div>
        </div>

        <div className='row g-9 mb-7'>
          <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>Locality</label>
          <input type="text" placeholder='locality' name='locality' className={clsx('form-control form-control-solid',
           {'is-invalid': touched.locality && errors.locality},
           {
             'is-valid': touched.locality && !errors.locality,
           }
         )}
           value={values.locality}
           onChange={handleChange}
           onBlur={handleBlur}
           />
              {touched.locality && errors.locality && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.locality}</span>
            </div>
          </div>
        )}
          </div>
          <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>City</label>
          <select aria-label="Select example"placeholder='city' name='city' className={clsx("form-select form-select-solid",
           {'is-invalid': touched.city && errors.city},
           {
             'is-valid': touched.city && !errors.city,
           }
         )}
           value={values.city}
           onChange={handleChange}
           onBlur={handleBlur}
           >
            <option value='' disabled selected hidden>city</option>
            <option value="Madurai">Madurai</option>
            <option value="Dindigul">Dindigul</option>
            <option value="Covai">Covai</option>
          </select>   
          {touched.city && errors.city && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.city}</span>
            </div>
          </div>
        )}   
          </div>
        </div>

        <div className='row g-9 mb-7'>
          <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>State</label>
          <select aria-label="Select example"name='state' className={clsx("form-select form-select-solid",
           {'is-invalid': touched.state && errors.state},
           {
             'is-valid': touched.state && !errors.state,
           }
         )}
           value={values.state}
           onChange={handleChange}
           onBlur={handleBlur}
           >
            <option value='' disabled selected hidden>state</option>
            <option value="TamilNadu">TamilNadu</option>
            <option value="Kerla">Kerla</option>
            <option value="Karnataka">Karnataka</option>
          </select> 
          {touched.state && errors.state && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.state}</span>
            </div>
          </div>
        )}   
          </div>
          <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>Country</label>
          <select  aria-label="Select example"name='country' className={clsx("form-select form-select-solid",
           {'is-invalid': touched.country && errors.country},
           {
             'is-valid': touched.country && !errors.country,
           }
         )}
           value={values.country}
           onChange={handleChange}
           onBlur={handleBlur}
           >
            <option value='' disabled selected hidden>country</option>
            <option value="India">India</option>
            <option value="Australia">Australia</option>
            <option value="America">America</option>
          </select>
          {touched.country && errors.country && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.country}</span>
            </div>
          </div>
        )}   
          </div>
        </div>
        <div className='row g-9 mb-7'>
        <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>Role</label>
          <select  aria-label="Select example"name='role' className={clsx("form-select form-select-solid",
           {'is-invalid': touched.role && errors.role},
           {
             'is-valid': touched.role && !errors.role,
           }
         )}
           value={values.role}
           onChange={handleChange}
           onBlur={handleBlur}
           >
            <option value='' disabled selected hidden>role</option>
            {roleSuccess.getRoles &&
         roleSuccess.getRoles?.map((survey:getRolesResponse) => (
            <option key={survey.name} value={survey.id}>
                {survey.name}
            </option>
        ))
     }
          </select>
          {touched.role && errors.role && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.role}</span>
            </div>
          </div>
        )}   
          </div> 
          {!data ?  <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>Store</label>
          <select  aria-label="Select example"name='store_id' className={clsx("form-select form-select-solid",
           {'is-invalid': touched.store_id && errors.store_id},
           {
             'is-valid': touched.store_id && !errors.store_id,
           }
         )}
           value={values.store_id}
           onChange={handleChange}
           onBlur={handleBlur}
           >
           <option value='' disabled selected hidden>Store</option>
                {storeData.getStores
                  ? storeData.getStores?.map((survey: IStores) => (
                      <option key={survey.name} value={survey.id}>
                        {survey.name}
                      </option>
                    )) : null}
          </select>
          {touched.store_id && errors.store_id && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.store_id}</span>
            </div>
          </div>
        )}   
          </div>:null}
          {/* {!data ? <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>CreatedBy</label>
          <input type="text" placeholder='created_by' name='created_by' className='form-control form-control-solid'
           
         value={values.created_by}
         onChange={handleChange}
       />
          </div>:
          <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>UpdatedBy</label>
          <input type="text" placeholder='updated_by' name='updated_by'className="form-control form-control-solid"

         
         value={values.updated_by}
         onChange={handleChange}
       />
      
          </div>} */}
         </div>
         <div className='row g-9 mb-7'>
         {/* {!data ?  <div className='col-md-6 fv-row fv-plugins-icon-container'>
          <label className=' fs-6 fw-semibold mb-2'>Store</label>
          <select  aria-label="Select example"name='store_id' className={clsx("form-select form-select-solid",
           {'is-invalid': touched.store_id && errors.store_id},
           {
             'is-valid': touched.store_id && !errors.store_id,
           }
         )}
           value={values.store_id}
           onChange={handleChange}
           onBlur={handleBlur}
           >
           <option>Store</option>
                {storeData.getStores
                  ? storeData.getStores?.map((survey: IStores) => (
                      <option key={survey.name} value={survey.id}>
                        {survey.name}
                      </option>
                    ))
                  : null}
          </select>
          {touched.store_id && errors.store_id && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.store_id}</span>
            </div>
          </div>
        )}   
          </div>:null} */}
         </div>
         {errorAdminCreate  &&  (
                      <div className='notice d-flex rounded border-warning border border-dashed p-3'style={{backgroundColor:'#FFF8DD'}}>
                      <span style={{color: "#deb232" }}>
                        <KTIcon iconName='information-5' className='fs-1 me-4' />
                        </span>
                        <div className='d-flex flex-stack flex-grow-1'>
                          <div className='fw-bold'>
                            <div className='fs-6'style={{color:'#b5b5c3'}}>
                           
                            ErrorCode - {errorCodeAdminCreate} : {errorAdminCreate}
                            </div>
                          </div>
                        </div>
                      </div>
                  )}
        </div>

        <div className='modal-footer flex-center'>
          <button  onClick={()=>handleSubmit()} className='btn btn-bg-primary fw-bold' style={{color:'white'}}>{data ? "Update":"Create"}</button>
          <button  className='btn fw-bold'onClick={handleClose} style={{color:'#7E8299',backgroundColor:'#F9F9F9'}}>Cancel</button>
        </div>
        

    </Modal>
    </>
)
}

