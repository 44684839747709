import {put, takeEvery, call,select} from 'redux-saga/effects';
import {redTypes} from '../../constants/reduxTypes';
import { Ioffers } from '../../types/commonTypes';
import {  offersCreationRequestApi } from '../../services/commonApiCall';


function* offersCreation(param:object) {
  // console.log('offerCretionSaga',param);

try {
   
 const response:Ioffers =yield offersCreationRequestApi(param)
// console.log('responseVerify',response);


if (response && !response.message) {
  // console.log('billnumber',response);
yield put({type: redTypes.OFFERS_CREATION_SUCCESS, payload: response});

}else if (response.message) {
  console.log('Error',response.message);
 yield put({
   type: redTypes.OFFERS_CREATION_FAILURE,
   errorMessage: response.message,
   errorCode: response.errorCode,
 });
}

else {
  console.log('Something Error');
 yield put({
   type: redTypes.OFFERS_CREATION_FAILURE,
   errorMessage: "Something went wrong",
 });
}
}  catch (e) {
yield put({
type: redTypes.OFFERS_CREATION_FAILURE,
errorMessage: 'Something went wrong',
});
}

}
export default function* offersCreationSaga():any {
     return yield takeEvery (redTypes.OFFERS_CREATION_REQUEST, offersCreation);
  }