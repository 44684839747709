import React from 'react';
import './spinner.css'

interface LoadingIndicatorProps {
  //loading: boolean;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = () => {
//   if (!loading) {
//     return null; // Don't render anything if loading is false
//   }

  return (
    <div className="loading-indicator">
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingIndicator;
