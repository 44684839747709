/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import { useDispatch, useSelector } from 'react-redux';
import {Link,useNavigate} from 'react-router-dom'
import { AuthUserModel } from '../../types';
import { verifyCode } from '../../actions/apiRequests';
import React, { useEffect } from 'react';
import { redTypes } from '../../constants/reduxTypes'
import axios from 'axios';
import { ColorRing } from  'react-loader-spinner'
import { Spinner } from '../spinner';


const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
    verificationCode: Yup.string()
    .required('VerificationCode is required'),
})

const initialValues :AuthUserModel = {
  email: '',
  verificationCode: '',
}


export function VerificationCode() {
    const verificationCode:any = useSelector<any>(state => state.authReducer?.verificationCode);
    const errorCodeVerify: any = useSelector<any>((state) => state.authReducer?.errorCodeVerify);

    
    const spinner = useSelector<any>(state => state.authReducer?.spinner);
    // console.log('spinner',spinner);
    const result:any  = useSelector<any>(state => state.authReducer?.response);
    // console.log('verify',result.status);
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [loading, setLoading] = useState(false)



 React.useEffect(() => {
  
    if(verificationCode.status===true){
         dispatch({type:redTypes.MERCHANTS_CREATE_REQUEST,payload:{email:verificationCode.email,
         timezone:{label:'thursday',value:'1567083463'}}})

          }
  }, [verificationCode]);

  if(result.status===true){
navigate('/dashboard')

  }
// React.useEffect(() => {
//   console.log("successfully login !!");

//   if (loginSuccess) {
//     navigate("/dashboard");
//   }
// }, [loginSuccess]);

const { handleSubmit, values, handleChange, errors, touched, handleBlur, status, setStatus } = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      console.log('on submit')
      setLoading(true)
      const data ={
        email:values.email,
        verificationCode:values.verificationCode
      }
       dispatch(verifyCode(data));

    },
  })

  // const logout=()=>{
  //   localStorage.clear();
  //   dispatch({ type: redTypes.SIGN_OUT_SUCCESS  });
  
  // }
  return (
        //   {spinner && }  
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
      <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
    <form
      className='form w-100'
      onSubmit={handleSubmit}
      noValidate
      id='kt_login_signup_form2'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>VerifyCode</h1>
      </div>
     
      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
           placeholder='Enter your email'
           name='email'
           type='email'
           className={clsx(
            'form-control bg-transparent',
            {'is-invalid': touched.email && errors.email},
            {
              'is-valid': touched.email && !errors.email,
            }
          )}
           value={values.email}
           onChange={handleChange}
           onBlur={handleBlur}
          // className= {errors.email && touched.email ? errors.email : null}
          autoComplete='off'
        />
        {touched.email && errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>VerifyCode</label>
        <input
          autoComplete='off'
          placeholder='VerifyCode'
          name='verificationCode'
          type='text'
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': touched.verificationCode && errors.verificationCode},
            {
              'is-valid': touched.verificationCode && !errors.verificationCode,
            }
          )}
          value={values.verificationCode}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {touched.verificationCode && errors.verificationCode && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert '>{errors.verificationCode}</span>
            </div>
          </div>
        )}
      </div>
      {/* {errorCodeVerify &&  !loading &&  (
                  <div className="alert alert-dismissible bg-secondary justify-content-center d-flex flex-column flex-sm-row p-3 mb-5 px-5"style={{}}>
                        <div className="">
                       <span className="text-center fw-bolder fs-6"style={{color:'#813636'}}>
                        {errorCodeVerify}
                        </span> 
                      </div>
                    </div>
                  )} */}
{/* 
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-bg-primary mb-5'
          //disabled={formik.isSubmitting || !formik.isValid}
        > */}
        <div className='text-center'>
        <button
          type='submit'
          id='kt_verify_submit'
          className='btn btn-lg btn-bg-primary w-100 mb-5'
          // onClick={()=>navigate('/dashboard')}
        //  disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
         {!loading && <span className='indicator-label'>Submit</span>}
          {/* {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )} */}
        </button>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-bg-secondary w-100 mb-5'
            onClick={()=>navigate('/login')}
           // onClick={logout}
          >
            Cancel
          </button>
      </div>
      {/* end::Action */}

      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        <Link to='/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>

    </div>
    </div>
    </div>
  )
}

