import { redTypes } from "../constants/reduxTypes";

import { api } from "../constants/api";

import { SignupPayload, SignupRequest } from "../types";

export const signupRequest = (payload: SignupPayload): SignupRequest => {
  return {
    type: redTypes.SIGNUP_REQUEST,
    payload,
  };
};

export const verifyCode = (param: object) => {
  return {
    type: redTypes.CODE_VERIFY_REQUEST,
    payload: param,
  };
};

export const login = (param: object) => {
  return {
    type: redTypes.LOGIN_REQUEST,
    payload: param,
  };
};
export const adminLogin = (param: object) => {
  return {
    type: redTypes.ADMIN_LOGIN_REQUEST,
    payload: param,
  };
};
export const adminCreation = (param: object) => {
  console.log('param',param);
  
  return {
    type: redTypes.ADMIN_USER_CREATION_REQUEST,
    payload: param,
  };
};

export const settingUpdation = (param: object) => {
  return {
    type: redTypes.SETTING_UPDATION_REQUEST,
    payload: param,
  };
};

export const createRoles = (param: object) => {
  return {
    type: redTypes.ADD_ROLES,
    payload: param,
  };
};
export const StoreCreation = (param: object) => {
  return {
    type: redTypes.STORE_CREATION_REQUEST,
    payload: param,
  };
};
export const apiKeysCreation = (param: object) => {
  return {
    type: redTypes.API_KEYS_GENERATE_REQUEST,
    payload: param,
  };
};

export const offersCreation = (param: object) => {
  console.log("paramoFFER", param);

  return {
    type: redTypes.OFFERS_CREATION_REQUEST,
    payload: param,
  };
};
