import { useSelector,useDispatch } from 'react-redux'
import { redTypes } from '../../../../modules/constants/reduxTypes'
import { Modal } from 'react-bootstrap'
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';

type Props = {
  show: boolean
  handleClose: () => void
}
const HeaderUserMenu = ({show, handleClose}: Props) => {
  const dispatch=useDispatch();
  const currentUser :any= useSelector<any>(state=> state.authReducer?.response);
 
  const logout=()=>{
    localStorage.clear();
    dispatch({ type: redTypes.SIGN_OUT_SUCCESS  });
   dispatch({
        type: redTypes.CLEAR_MERCHANT_LOGIN_STATE,
      });
      dispatch({
        type: redTypes.CLEAR_ADMIN_LOGIN_STATE,
      });
  }
    return (
      <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-400px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
     <div className='modal-title fs-1 fw-bold d-flex justify-content-center py-5'>
     <span style={{paddingTop:8}}> Are you sure want to log out ?</span>
     </div>
     <div  className="modal-body d-flex justify-content-center fw-semibold py-2 mb-4 gap-5">
     <a href="#"  className='btn fw-bold'style={{color:'white',backgroundColor:'#8BE8E5'}} onClick={logout}>Logout</a>
     <a href="#"  className='btn fw-bold'style={{color:'white',backgroundColor:'#FF8989'}}onClick={handleClose}>Cancel</a>
     </div>
    </Modal>
    )

}

export {HeaderUserMenu}
