import {put, takeEvery, call,select} from 'redux-saga/effects';
import {redTypes} from '../../constants/reduxTypes';
import { IAdminUser, IAdminUserCreateResponse } from '../../types/commonTypes';
import {  adminUserCreationRequestApi } from '../../services/commonApiCall';


function* admiUserCretion(param:object) {
  console.log('admiUserCretionSaga',param);

try {
   
 const response:IAdminUserCreateResponse =yield adminUserCreationRequestApi(param)
console.log('responseVerify',response);


if (response && !response.message) {
  // console.log('billnumber',response);
yield put({type: redTypes.ADMIN_USER_CREATION_SUCCESS, payload: response});

}else if (response.message) {
  console.log('billError',response.message);
 yield put({
   type: redTypes.ADMIN_USER_CREATION_FAILURE,
   errorMessage: response.message,
   errorCode: response.errorCode,
 });
}

else {
  console.log('Something Error');
 yield put({
   type: redTypes.ADMIN_USER_CREATION_FAILURE,
   errorMessage: "Something went wrong",
 });
}
}  catch (e) {
yield put({
type: redTypes.ADMIN_USER_CREATION_FAILURE,
errorMessage: 'Something went wrong',
});
}

}
export default function* admiUserCretionSaga():any {
     return yield takeEvery (redTypes.ADMIN_USER_CREATION_REQUEST, admiUserCretion);
  }