import { configData } from "../../config/config.helper";

export const api = {
  baseUrl: configData.API_URL,
  login: "merchants/login",
  register: "merchants/send-email-verification",
  VerifyCode: "merchants/verify-email",
  createProfile: "merchants/",
  billList: "bills",
  customerDetail: "customers",
  adminUser: "admin-users/",
  customerOverallDetail: "users/user-merchant",
  adminLogin: "admin-users/login",
  refreshToken: "merchants/auth/refresh",
  roles: "roles",
  merchants: "merchants/",
  customerBills: "customers/customer-bills",
  reportBills: "report/bill",
  stores: "stores",
  settings: "settings",
  chats: "chats",
  apiKeys:"merchants/generate-Key",
  offers:'offers/',
  imageUploader:'image-uploader/',
  imageUploaderMultiple:'image-uploader/multiple-uploads',
  getReview:'reviews',
  getUserStore:'users/user-stores'
};
