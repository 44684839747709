/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useRef} from 'react'
import {Link} from 'react-router-dom'
import {defaultAlerts, defaultLogs, KTIcon, toAbsoluteUrl} from '../../../helpers'
import { Modal } from 'react-bootstrap'
import { StepperComponent } from '../../../assets/ts/components'
import './index.css'
import { NotificationHeader } from './notificationHeader'
import { NotificationChart } from '../../../../modules/components/pages/notification/notificationChart'
type Props = {
  state: boolean
   handleClose: () => void
}
const HeaderNotificationsMenu = ({state,handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  return (
    <>
    <Modal
    id='kt_modal_create_admin'
    tabIndex={-1}
    aria-hidden='true'
    dialogClassName='modal-dialog modal-dialog-ended w-350px w-lg-375px'
    show={state}
    onHide={handleClose}
    onEntered={loadStepper}
   // backdrop={true}
  >
     <div className='modal-header' style={{backgroundColor:'#1450A3',height:'105px'}}>
      <NotificationHeader/>
     </div>
  {/* <div
    className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
    // data-kt-menu={state}
  > */}
     <div className='modal-body '>

 
        <NotificationChart/>
      </div>
     
  </Modal>
  </>
)
  }
export {HeaderNotificationsMenu}
