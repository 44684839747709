import { redTypes } from "../constants/reduxTypes";
import { AuthStateModel, AuthActions } from "../types";
import { createSlice, current } from "@reduxjs/toolkit";

const initialState: AuthStateModel = {
  loading: false,
  verificationSend: false,
  verificationCode: {},
  spinner: false,
  isAuthenticated: localStorage.getItem("currentUser") ? true : false,
  response: {},
  loginSuccess: {},
  roles: "",
  error: "",
  errorSignUp:'',
  errorMerchant:'',
  errorAdmin:'',
  errorCodeVerify:'',
  getMerchant:{},
  merchantLoginState:false,
  adminLoginState:false
};

export const authReducer = (state = initialState, action: any) => {
  // console.log("state", action);
  switch (action.type) {
    case redTypes.SIGNUP_REQUEST:
      return { ...state, loading: true };
    case redTypes.SIGNUP_SUCCESS:
      return { ...state, loading: false, verificationSend: action.payload };
    case redTypes.SIGNUP_FAILURE:
      return { ...state, loading: false, errorSignUp: action.errorMessage };
    case redTypes.CODE_VERIFY_REQUEST:
      return { ...state, loading: true };
    case redTypes.CODE_VERIFY_SUCCESS:
      return { ...state, loading: false, verificationCode: action.payload };
    case redTypes.CODE_VERIFY_FAILURE:
      return { ...state, loading: false, errorCodeVerify: action.errorMessage };
    case redTypes.MERCHANTS_CREATE_REQUEST:
      return { ...state, spinner: true, loading: true };
    case redTypes.MERCHANTS_CREATE_PROFILE_SUCCESS:
      return {
        ...state,
        spinner: false,
        isAuthenticated: true,
        response: action.payload,
      };
    case redTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        response: null,
        loginSuccess: null,
        roles: null,
        error:null,
        errorMerchant:null,
         errorAdmin:'',
         errorSignUp:'',
         errorCodeVerify:'',
      };
    case redTypes.LOGIN_REQUEST:
      return { ...state, loading: true, spinner: true };
    case redTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        spinner: false,
        isAuthenticated: true,
        loginSuccess: action.payload,
      //  roles: action.payload.roles,
      merchantLoginState:true
      };
    case redTypes.LOGIN_FAILURE:
      return { ...state, loading: false, errorMerchant: action.errorMessage };
      case redTypes.CLEAR_MERCHANT_LOGIN_STATE:
        return { ...state, loading: false,merchantLoginState:false,errorMerchant:'' };
      case redTypes.GET_ROlES_SUCCESSFULLY:
        return { ...state, loading: false, getMerchant: action.payload };

      case redTypes.ADMIN_LOGIN_REQUEST:
      return { ...state, loading: true, };
    case redTypes.ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        loginSuccess: action.payload,
        roles: action.payload.roles,
        adminLoginState:true
      };
    case redTypes.ADMIN_LOGIN_FAILURE:
      return { ...state, loading: false, errorAdmin: action.errorMessage };
      case redTypes.CLEAR_ADMIN_LOGIN_STATE:
      return { ...state, loading: false, adminLoginState:false,errorAdmin:''};
      case redTypes.CLEAR_ERROR:
        return { ...state, loading: false,error:null,errorMerchant:'' ,errorAdmin:''};
        
    default:
      return state;
  }
};
