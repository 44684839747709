/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { CustomerHeader } from "./customerHeader";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { redTypes } from "../../../constants/reduxTypes";
import { ErrorBoundary } from "./ErrorBoundary";
import moment from "moment";
import { Bill } from "../../../types/commonTypes";
import { RotatingLines, ThreeDots } from "react-loader-spinner";

type Props = {
  className: string;
};

const CustomerBills: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customerBillsData: any = useSelector<any>(
    (state) => state.billListReducer?.customerBills
  );
  //  console.log("custmorBills", customerBillsData);
  const loading: any = useSelector<any>(
    (state) => state.billListReducer?.loading
  );
  const errorCustomerBills: any = useSelector<any>(
    (state) => state.billListReducer?.errorCustomerBills
  );
  const { state }: any = useLocation();
  // console.log("stateLocation", state);

  React.useEffect(() => {
    if (state) {
      dispatch({
        type: redTypes.CUSTOMER_SET_STATE,
        payload: { customerState: state },
      });
    }
  }, [state]);
  const handleClick = () => {
    navigate("/customers");
    dispatch({ type: redTypes.RESET_SET_STATE });
  };
  return (
    <>
      <CustomerHeader />
      {errorCustomerBills ? (
        <ErrorBoundary />
      ) : (
        <div className="card mb-5 mb-xl-10">
          {/* begin::Header */}
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">Bills</span>
              {!customerBillsData ? (
                <div className="fw-semi-bold fs-6">No data found</div>
              ) : (
                <span
                  className="text-muted mt-1 fw-semibold fs-7"
                  style={{ color: "#A1A5B7" }}
                >
                  Over {customerBillsData.bills.length} bills
                </span>
              )}
            </h3>
            <div className="card-toolbar">
              <button
                type="button"
                className="btn btn-bg-primary"
                style={{ color: "white" }}
                onClick={handleClick}
              >
                back
              </button>
            </div>
          </div>

          <div className="card-body py-3">
            {/* begin::Table container */}
            <div className="table-responsive">
              {/* begin::Table */}
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-3">
                {/* begin::Table head */}
                {loading ? (
                    <div
                      className=" fw-bold fs-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >   
                    <ThreeDots 
                        height="80" 
                        width="80" 
                        radius="9"
                        color="#ADC4CE" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        visible={true}
                        />
                      Loading...
                    </div>
                  ) : (
                    <>
                <thead>
                  <tr className="fw-bold fs-4" style={{ color: "#454548" }}>
                    <th className="w-25px">
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="1"
                          data-kt-check="true"
                          data-kt-check-target=".widget-13-check"
                        />
                      </div>
                    </th>
                    <th className="text-center">#</th>
                    <th className="text-center">Bill Number</th>
                    <th className="text-center">Pretax Value</th>
                    <th className="text-center">Discount</th>
                    <th className="text-center">Other Charges</th>
                    <th className="text-center">Total Invoice</th>
                    <th className="text-center">CreatedAt</th>
                    {/* <th className='text-center'>Actions</th> */}
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
              
                <tbody>
                 
                      {!customerBillsData ? (
                        <div
                          className=" fw-bold fs-3"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 105,
                          }}
                        >
                          Data not found!!
                        </div>
                      ) : (
                        <>
                          {customerBillsData.bills?.map(
                            (val: Bill, index: any) => {
                              return (
                                <tr key={index} className="text-center fs-6">
                                  <td>
                                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input widget-13-check"
                                        type="checkbox"
                                        value="1"
                                      />
                                    </div>
                                  </td>
                                  <td className="">
                                    {/* <a href='#' className='text-dark fw-bold text-hover-primary fs-6'> */}
                                    {val.amounts.id}
                                    {/* </a> */}
                                  </td>
                                  <td className="">
                                    {val.bill_number}
                                    {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>Code: PH</span> */}
                                  </td>

                                  <td className="">
                                    {val.amounts.pretax_value}
                                  </td>
                                  <td className="">{val.amounts.discount}</td>
                                  <td className="">
                                    {val.amounts.other_charges}
                                  </td>
                                  <td className="">
                                    {val.amounts.total_invoice_value}
                                  </td>
                                  <td className="">
                                    <span className="">
                                      {" "}
                                      {moment(
                                        customerBillsData.createdAt
                                      ).format("MMM DD YYYY h:mm A")}
                                    </span>
                                  </td>
                                  {/* <td className='text-center fw-bold fs-7'>
                  <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTIcon iconName='switch' className='fs-3' />
                  </a>
                  <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTIcon iconName='pencil' className='fs-3' />
                  </a>
                  <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'style={{color:'red',backgroundColor:'#f3e7e6'}}>
                    <KTIcon iconName='trash' className='fs-3' />
                  </a>
                </td> */}
                                </tr>
                              );
                            }
                          )}
                        </>
                      )}
                   
                </tbody>
                </>
                  )}
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      )}
    </>
  );
};

export { CustomerBills };
