import React from "react";
import './style.css'
import { BsStarFill,BsSortDownAlt ,BsSortUpAlt} from "react-icons/bs";
import {Outlet, useLocation} from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { redTypes } from "../../../constants/reduxTypes";
import moment from "moment";
import LoadingIndicator from './loadingIndicator';
import { Rating } from 'react-simple-star-rating'
import Dropdown from 'react-bootstrap/Dropdown';
import { KTIcon, toAbsoluteUrl } from "../../../../../src/_metronic/helpers";
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { faker } from '@faker-js/faker';
import ReactPaginate from 'react-paginate';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import {
  Card,
  CardSubtitle,
  CardText,
  CardTitle,
  CardBody,
  CardImg,
} from "reactstrap";
import { StoreHeader } from "./storeHeader";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
);
function Body({
  firstName,
  lastName,
  profilePic,
  stars,
  comment,
  timestamp,
}:any) {
  const dispatch = useDispatch();
  const [sort,setSort]=React.useState(true)
  const [pageCount, setPageCount] = React.useState<number>(0)
  const [currentPages, setCurrentPages] = React.useState<number>(0)
  console.log('sort',sort)
  const loading: any = useSelector<any>(
    (state) => state.billListReducer?.reviewLoader
  );
  console.log('reviewLoader',loading)
  const review: any = useSelector<any>(
    (state) => state.billListReducer?.reviews
  );
 console.log('review',review)
 
  const arr =[1,2]
  const {state}:any = useLocation()

  console.log('state in review',state)
  React.useEffect(() => {
    console.log('useEffect call')
    dispatch({
      type: redTypes.GET_REVIEW_REQUEST,
      payload: { store_id: state.id ,sort:sort,currentPages:currentPages },
    });  
  }, [sort,state,currentPages]);

  const handleSort=()=>{
    setSort(!sort)

  }
  const sortData =['asc','desc']


  const options:any= {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        display: false,
        grid: {
          display: false
        }
      },
      y: {
        stacked: true,
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };
  
  const labels = ["⭐⭐⭐⭐⭐", "⭐⭐⭐⭐", "⭐⭐⭐", "⭐⭐", "⭐"];
  
   const data = {
    labels,
    datasets: [
      {
        label: 'Total',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        borderColor: 'rgba(255, 175, 55)',
        backgroundColor: 'rgba(255, 175, 55)',
      },
      // {
      //   label: 'Last Year',
      //   data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      //   borderColor: 'rgb(53, 162, 235)',
      //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
      // },
    ],
  };

  const handlePageClick = (e:any) => {
    const selectedPage = e.selected * 1;
    setCurrentPages(selectedPage)
  };

  return (
    <>
    <StoreHeader state={state}/>




   
    <div style={{padding:'10px'}}>

    
      <Row style={{backgroundColor:'white'}} >
        <Col sm={4}  style={{padding:'10px'}}  >
          <div style={{display:'flex',flexDirection:'row'}}>
          <div>
        <div>
        <h2>reviews</h2>
       </div>
       <div style={{display:'flex',flexDirection:"row"}}>
       <Rating
         allowFraction = {true}
         readonly={true}
       // onClick={handleRating}
        initialValue={4}
        size={18}
        /* Available Props */
       />
        <div style={{paddingLeft:'5px',paddingTop:'3px'}}>
        4 out of 5
        </div>
       </div>
       <div className="star-ratings-chart" style={{width:'350px',height:'250px'}}>
       <Bar options={options} data={data} width={250} height={150} />
       </div>
       </div>
       <div className="vertical-line "></div>
       </div>
        </Col>
      
        
        <Col sm={8}>

        {!loading?
    <Card>
       <div style={{display:'flex',justifyContent:'flex-end',padding:'10px'}}>
       <Dropdown className=" hide-on-print">
        <Dropdown.Toggle variant="secondary" id="dropdownMenuButton">
      {!sort?'older':"earlier"}
         </Dropdown.Toggle>
        <Dropdown.Menu>
         <Dropdown.Item href="#" onClick={()=>{setSort(false)}} >older</Dropdown.Item> 
         <Dropdown.Item href="#" onClick={()=>{setSort(true)}}>earlier</Dropdown.Item>
        
        </Dropdown.Menu>
      </Dropdown>
    </div>
{review.review&&review.review.map((data:any)=>{
  const imgurl = data.customers.image?data.customers.image:"https://bill2u-image-bucket.s3.ap-southeast-1.amazonaws.com/1692959199656person.png"
  return(
    <div>
        
    <div className="reviews-top">
      <div  style={{display:'flex',flexDirection:'row',alignItems:"center",gap:'20px'}}>
        <div style={{display:'flex',flexDirection:'column'}}>
        <CardImg
  style={{ height: '50px', width: '50px', borderRadius: '50%', objectFit: 'cover' }}
  className="avatar"
  src={imgurl}
  alt="user avatar"
/>
       </div>
       
        
        
     
        <div style={{display:'flex',flexDirection:'column'}}>
        <div style={{fontWeight:'bold'}}>
         {data.customers?data.customers.firstname:"cholai"}
        
        </div>

        <div >
        <Rating
         allowFraction = {true}
         readonly={true}
       // onClick={handleRating}
        initialValue={data.ratings?data.ratings:""}
        size={13}
        /* Available Props */
      />
         <BsStarFill color="white"/>
        </div>

        <div className="reviews-body">
        <CardText>
        {data.reviews?data.reviews:""}
        </CardText>
      </div>
        </div>
    
      <CardText >
        <small style={{color:'#A1A5B7'}} >
     {data.createdAt?moment(data.createdAt).format(
                                "YYYY MMM DD hh:mm"
                              ):''}  
        </small>
      </CardText>
      </div>
    </div>
  </div>
  )
})}
      <div style={{display:'flex',justifyContent:'flex-end',padding:'10px'}}>
      <ReactPaginate
            previousLabel={ <span
            
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Previous"
            >
               <KTIcon iconName='arrow-left' className='fs-1 fw-bold' /> </span>}
            nextLabel={<span
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Next"
            >
            <KTIcon iconName='arrow-right' className='fs-1 fw-bold' /></span>}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel={"..."}
            breakClassName="break-me"
            breakLinkClassName="page-link"
            pageCount={review.count?review.count/4:0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
            /> 
      </div> 
    </Card>:<LoadingIndicator/>}

        </Col>
      </Row>
      </div>
    
   
    </>
   
  );
}

export default Body;
