import axios, { AxiosResponse } from "axios";
import { api } from "../constants/api";
import {
  ICreateProfileSuccessResponse,
  ILoginSuccessResponse,
  SignUpResponse,
  SignupRequest,
  VerifyCodeResponse,
} from "../types";
import {
  getRolesResponse,
  createRoleForApi,
  deleteRoleApiResponse,
  deleteRoleResponse,
  getMerchantResponse,
  getGalleryImages,
  getReview,
  ISettings,
} from "../types/commonTypes";
import { authHeader } from "../helper/authHeader";

export const signUpRequestApi = (
  param: SignupRequest
): SignUpResponse | any => {
  var uploadUrl = api.baseUrl + api.register;
  return axios
    .post<SignUpResponse[]>(uploadUrl, param.payload)
    .then((response) => {
      var result = response;
      console.log("res", response);
      return result;
    })

    .catch((error) => {
      // console.log("e", error.response);
      // throw Error(error.response);
      return error.response.data;
    });
};

export const verifyCodeRequestApi = (param: any): VerifyCodeResponse | any => {
  // console.log('verifyCodeRequestApi',param)
  var uploadUrl =
    api.baseUrl +
    api.VerifyCode +
    "?" +
    "&email=" +
    param.payload.email +
    "&verificationCode=" +
    param.payload.verificationCode;
  console.log("uploadUrl", uploadUrl);
  return axios
    .post<VerifyCodeResponse[]>(uploadUrl)
    .then((response) => {
      var result = response.data;
      console.log("resDta", response.data);
      return result;
    })

    .catch((error) => {
      return error.response.data;
      // console.log("e", error);
      // throw Error(error.response);
    });
};

export const createProfileRequestApi = (
  param: any
): ICreateProfileSuccessResponse | any => {
  var uploadUrl = api.baseUrl + api.createProfile;

  return axios
    .post<ICreateProfileSuccessResponse[]>(uploadUrl, param.payload)
    .then((response) => {
      //   var result = response
      console.log("res", response.data);
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const loginRequestApi = (param: any): ILoginSuccessResponse | any => {
  var subDomains = param.payload.subdomains;
  if (!subDomains) {
    var uploadUrl = api.baseUrl + api.login;

    return axios
      .post<ILoginSuccessResponse[]>(uploadUrl, param.payload)
      .then((response) => {
        // console.log('res',response)
        console.log("resloginApi", response.data);
        return response.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  } else {
    var uploadUrl = api.baseUrl + api.adminLogin;

    return axios
      .post<ILoginSuccessResponse[]>(uploadUrl, param.payload)
      .then((response) => {
        // console.log('res',response)
        console.log("resadminLogin", response.data);
        return response.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  }
};


export const getRolesApi = (param: any): getRolesResponse | any => {
  // console.log('adminLoginParams',param)
  async function fetch(param:any) {
    var authToken = await authHeader();
  var CurrentToken = authToken.currentToken
  var subDomains = param.payload.domain;

  var getUploadUrl = `${api.baseUrl}${api.roles}`+'?' + "&subdomains="+param.payload.domain+"&currentPages="+param.payload.currentPages
  //  console.log('uploadUrl',getUploadUrl)

  return axios
    .get<getRolesResponse[]>(getUploadUrl,{ headers: { Authorization: `Bearer ${CurrentToken}` } })
    .then((response) => {
      // console.log('res',response)
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
  }
  return fetch(param)
};

export const getGalleryApi = (param: any): getGalleryImages | any => {
    console.log('get gallery api log',param)
    async function fetch(param:any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken
    var subDomains = param.payload.domain;

    var getUploadUrl = `${api.baseUrl}${api.imageUploaderMultiple}`+'?'+"&store_id="+param.payload.store_id + "&subdomains="+param.payload.subdomains
   console.log('uploadUrl',getUploadUrl)

  return axios
    .get<getRolesResponse[]>(getUploadUrl,{ headers: { Authorization: `Bearer ${CurrentToken}` } })
    .then((response) => {
      // console.log('res',response)
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
  }
  return fetch(param)
};
export const getReviewApi = (param: any): getReview | any => {
  console.log('get gallery api log',param)
  async function fetch(param:any) {
  var authToken = await authHeader();
  var CurrentToken = authToken.currentToken
  var subDomains = param.payload.domain;

  var getUploadUrl = `${api.baseUrl}${api.getReview}`+'?'+"&store_id="+param.payload.store_id +"&sort="+param.payload.sort+"&currentPage="+param.payload.currentPages
 console.log('get review url',getUploadUrl)

return axios
  .get<getReview[]>(getUploadUrl,{ headers: { Authorization: `Bearer ${CurrentToken}` } })
  .then((response) => {
    // console.log('res',response)
    return response.data;
  })
  .catch((err) => {
    return err.response.data;
  });
}
return fetch(param)
};

export const updateAdminUsersApi = (param: any): getMerchantResponse | any => {
  console.log("updateParamServices", param);
  var subDomains = param.payload;
  async function fetch(param:any) {
    var authToken = await authHeader();
  var CurrentToken = authToken.currentToken
  var updateUrl = `${api.baseUrl}${api.adminUser}`;
  console.log("uploadUrl", updateUrl);

  return axios
    .put<getMerchantResponse[]>(updateUrl, param.payload,{ headers: { Authorization: `Bearer ${CurrentToken}` } })
    .then((response) => {
      // console.log('res',response)
      console.log("getMerchantApi", response.data);
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
  }
  return fetch(param)
};

// export const updateSettingsApi = (param: any): ISettings | any => {
//   console.log("updateParamServices", param);
//   async function fetch(param:any) {
//     var authToken = await authHeader();
//   var CurrentToken = authToken.currentToken
//   var updateUrl = `${api.baseUrl}${api.settings}`;
//   console.log("uploadUrl", updateUrl);

//   return axios
//     .put<ISettings[]>(updateUrl, param.payload,{ headers: { Authorization: `Bearer ${CurrentToken}` } })
//     .then((response) => {
//       // console.log('res',response)
//       console.log("ISettings Api", response.data);
//       return response.data;
//     })
//     .catch((err) => {
//       return err.response.data;
//     });
//   }
//   return fetch(param)
// };

export const createRoleApi = (param: any): createRoleForApi | any => {
  var subDomains = param.payload.subdomain;
  async function fetch(param:any) {
    var authToken = await authHeader();
  var CurrentToken = authToken.currentToken
  var UploadUrl = `${api.baseUrl}${api.roles}`;
  if (param.payload.id == 0) {
    delete param.payload.id;
    console.log("add roles");
    console.log("add roles param", param.payload);
    return axios
      .post<createRoleForApi[]>(UploadUrl, param.payload,{ headers: { Authorization: `Bearer ${CurrentToken}` } })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {

        return err.response.data;
      });
  } else {
    console.log("update roles");
    return axios
      .put<createRoleForApi[]>(UploadUrl, param.payload,{ headers: { Authorization: `Bearer ${CurrentToken}` } })
      .then((response) => {
        // console.log('res',response)
        console.log("createRolesApi", response.data);
        return response.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  }
}
return fetch(param)
};
export const deleteRoleApi = (param: any): deleteRoleApiResponse | any => {
  async function fetch(param:any) {
    var authToken = await authHeader();
  var CurrentToken = authToken.currentToken
  var deleteUrl =
    `${api.baseUrl}${api.roles}` +
    "?id=" +
    param.payload.id +
    "&subdomains=" +
    param.payload.subdomains;

  return axios
    .delete<deleteRoleResponse[]>(deleteUrl,{ headers: { Authorization: `Bearer ${CurrentToken}` } })
    .then((response) => {
      // console.log('res',response)
      console.log("deleteRolesApi", response.data);
      return response.data;
    })
    .catch((err) => {
      return err.response.data;
    });
  }
  return fetch(param)
};

export const changePasswordWithEmailApi = (param: any): any | any => {
  async function fetch(param:any) {
    var authToken = await authHeader();
  var CurrentToken = authToken.currentToken
  if (param?.payload?.otp) {
    if (param?.payload?.merchant) {
      delete param.payload.merchant;
      var uploadUrl = api.baseUrl + api.merchants + "verify-otp";
      return axios
        .put<any[]>(uploadUrl, param.payload,{ headers: { Authorization: `Bearer ${CurrentToken}` } })
        .then((response) => {
          console.log("changePasswordeitheOtpmerchants", response.data);
          return response.data;
        })
        .catch((err) => {
          return err.response.data;
        });
    } else {
      var uploadUrl = api.baseUrl + api.adminUser + "verify-otp";
      return axios
        .put<any[]>(uploadUrl, param.payload,{ headers: { Authorization: `Bearer ${CurrentToken}` } })
        .then((response) => {
          console.log("changePasswordeitheOtp", response.data);
          return response.data;
        })
        .catch((err) => {
          return err.response.data;
        });
    }
  } else {
    if (param?.payload?.merchant) {
      // console.log("changePasswordWithEmailmerchant", param.payload);
      delete param.payload.merchant;
      var uploadUrl = api.baseUrl + api.merchants + "password-verification";
      console.log("updateEmail", uploadUrl);
      return axios
        .post<any[]>(uploadUrl, param.payload,{ headers: { Authorization: `Bearer ${CurrentToken}` } })
        .then((response) => {
          console.log("changePasswordeithemailmerchant", response.data);
          return response.data;
        })
        .catch((err) => {
          return err.response.data;
        });
    } else {
      // console.log("changePasswordWithEmail", param.payload);
      var uploadUrl = api.baseUrl + api.adminUser + "password-verification";
      console.log("updateEmail", uploadUrl);
      return axios
        .post<any[]>(uploadUrl, param.payload,{ headers: { Authorization: `Bearer ${CurrentToken}` } })
        .then((response) => {
          console.log("changePasswordeithemail", response.data);
          return response.data;
        })
        .catch((err) => {
          return err.response.data;
        });
    }
  }
}
return fetch(param)
};
