import { put, takeEvery, call, select } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";
import { IStores } from "../../types/commonTypes";
import { storeRequestApi } from "../../services/commonApiCall";

function* storeCreation(param: object) {
   // console.log('storeCreation',param);

  try {
    const response: IStores = yield storeRequestApi(param);
    //  console.log('bill',response.message);

    if (response && !response.message) {
   //   console.log("storeRes", response);
      yield put({ type: redTypes.STORE_CREATION_SUCCESS, payload: response });
    } else if (response.message) {
      yield put({
        type: redTypes.STORE_CREATION_FAILURE,
        errorMessage: response.message,
        errorCode: response.errorCode,
      });
    } else {
      console.log("Something Error");
      yield put({
        type: redTypes.STORE_CREATION_FAILURE,
        errorMessage: "Something went wrong",
      });
    }
  } catch (e) {
    yield put({
      type: redTypes.STORE_CREATION_FAILURE,
      errorMessage: "Something went wrong",
    });
  }
}
export default function* storeCreationSaga(): any {
  return yield takeEvery(redTypes.STORE_CREATION_REQUEST, storeCreation);
}
