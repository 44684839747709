/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useReducer, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
// import {getUserByToken, login} from '../core/_requests'
import { toAbsoluteUrl } from "../../../_metronic/helpers";
// import {useAuth} from '../core/Auth'
import { login } from "../../actions/apiRequests";
// import { LoginModel } from './models'
import { AuthUserModel } from "../../types";
import { Spinner } from "../spinner";
import { AdminLogin } from "./adminLogin";
import { Tab, Tabs } from "react-bootstrap";
import { redTypes } from "../../constants/reduxTypes";
import { KTIcon } from "../../../_metronic/helpers";
import { LoginHeader } from "./loginHeader";
import { getFcmToken } from "../firebaseConfig/getFcmToken";
import { requestPermission } from "../firebaseConfig/notificationPermision";


const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues: AuthUserModel = {
  email: "",
  password: "",
};

export function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const merchantLoginState: any = useSelector<any>(
    (state) => state.authReducer?.merchantLoginState
  );
  const errorMerchant: any = useSelector<any>((state) => state.authReducer?.errorMerchant);
  const loading: any = useSelector<any>((state) => state.authReducer?.loading);


  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    status,
    setStatus,
  } = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      console.log("on submit");
      var fcmToken = await requestPermission()
      console.log("fcmToken", fcmToken)
      const data = {
        email: values.email,
        password: values.password,
        fcmToken:fcmToken
      };
      console.log("loginData",data)
      dispatch(login(data));
      // if (loginSuccess) {
      //  navigate('/dashboard')
      // }
      setStatus('')
    },
  });
  React.useEffect(() => {
    if (errorMerchant) {
      setTimeout(() => {
        dispatch({
          type: redTypes.CLEAR_MERCHANT_LOGIN_STATE,
        });
      }, 5000);
    }
  }, [errorMerchant]);
  React.useEffect(() => {
    if (merchantLoginState) {
      navigate("/dashboard");
      // dispatch({
      //   type: redTypes.CLEAR_MERCHANT_LOGIN_STATE,
      // });
    }
  }, [merchantLoginState]);

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
            <LoginHeader />

            <form
              className="form w-100 mt-10"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form1"
            >
              <div className="fv-row mb-8">
                <label className="form-label fs-6 fw-bolder text-dark">
                  Email
                </label>
                <input
                  placeholder="Enter your email"
                  name="email"
                  type="email"
                  className={clsx(
                    "form-control bg-transparent",
                    { "is-invalid": touched.email && errors.email },
                    {
                      "is-valid": touched.email && !errors.email,
                    }
                  )}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                {touched.email && errors.email && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert ">{errors.email}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className="fv-row mb-3">
                <label className="form-label fw-bolder text-dark fs-6 mb-0">
                  Password
                </label>
                <input
                  autoComplete="off"
                  placeholder="Password"
                  name="password"
                  type="password"
                  className={clsx(
                    "form-control bg-transparent",
                    { "is-invalid": touched.password && errors.password },
                    {
                      "is-valid": touched.password && !errors.password,
                    }
                  )}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {touched.password && errors.password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert ">{errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              {errorMerchant && !loading && (
                // <div className="alert alert-dismissible bg-secondary justify-content-center d-flex flex-column flex-sm-row p-3 mb-5 px-5"style={{}}>
                //       <div className="">
                //      <span className="text-center fw-bolder fs-6"style={{color:'#813636'}}>
                //      {/* <KTIcon iconName="check" className="fs-3" /> */}
                //       {errorMerchant}
                //       </span> 
                //     </div>
                //   </div>
                <div className='notice d-flex rounded border-danger border border-dashed p-3' style={{ backgroundColor: '#FCAEAE' }}>
                  <span style={{ color: "red" }}>
                    <KTIcon iconName='information-5' className='fs-1 me-4' />
                  </span>
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <div className='fs-6' style={{ color: 'white' }}>

                        {errorMerchant}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* end::Form group */}

              {/* begin::Wrapper */}
              <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                <div />

                {/* begin::Link */}
                <Link to="/auth/forgot-password" className="link-primary">
                  Forgot Password ?
                </Link>
                {/* end::Link */}
              </div>
              {/* end::Wrapper */}

              {/* begin::Action */}
              <div className="d-grid mb-10">
                <button
                  type="submit"
                  id="kt_merchant_sign_in_submit"
                  className="btn btn-bg-primary "
                  style={{ color: "white" }}
                //disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && (
                    <span className="indicator-label fw-bold">Log In</span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
              {/* end::Action */}

              <div className="text-gray-500 text-center fw-semibold fs-6">
                Not a Member yet?{" "}
                <Link to="/registration" className="link-primary">
                  Sign up
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
