import { getRolesResponse } from "../../types/commonTypes";
import { getRolesApi } from "../../services/apiCalls";
import { put, takeEvery, call, select } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";

function* getRolesSaga(param: object) {
  try {
    const response: getRolesResponse = yield getRolesApi(param);

    if (response && !response.message) {
      yield put({ type: redTypes.GET_ROlES_SUCCESSFULLY, payload: response });
    } else if(response.message) {
      yield put({
        type: redTypes.GET_ROLES_FAILURE,
        errorMessage: response.message,
        errorCode: response.errorCode,
      });
    }
    else {
      yield put({
        type: redTypes.GET_ROLES_FAILURE,
        errorMessage: "Something went wrong",
      });
    }
  } catch (e) {
    yield put({
      type: redTypes.GET_ROLES_FAILURE,
      errorMessage: "Something went wrong",
    });
  }
}
export default function* getRoleSaga(): any {
  return yield takeEvery(redTypes.GET_ROLES, getRolesSaga);
}
