import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { RotatingLines, ThreeDots } from "react-loader-spinner";
import { redTypes } from "../../constants/reduxTypes";
import { ICustomerOverallDetail } from "../../types/commonTypes";
import { KTIcon } from "../../../_metronic/helpers";
import { ErrorBoundary } from "./ErrorBoundary";



export const Customers: FC = () => {
  const dispatch = useDispatch();
    const loginSuccess: any = useSelector<any>(
        (state) => state.authReducer?.loginSuccess
      );
      const loading: any = useSelector<any>(
        (state) => state.billListReducer?.loading
      );
      const customerDetailData: any = useSelector<any>(
        (state) => state.billListReducer?.customerOverall
      );
const errorOverAllCustomer: any = useSelector<any>(
  (state) => state.billListReducer?.errorOverAllCustomer
);
    return (
        <>
         {errorOverAllCustomer ? (
        <ErrorBoundary/>
      ) : (
              <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5"> 
              <div className=" align-items-start d-flex flex-direction-row px-2 mb-2"style={{gap:15,color:'#009ef7',fontSize:'24px'}}>
        <KTIcon iconName="profile-user" className="fs-1 mt-2" />
              <span className=" fw-bold  ">customers</span>
            </div>
            </div>
      <div className="card-body py-0">
         <div className="table-responsive ">
            <table className="table table-row-dashed table-row-gray-300 table-hover  gs-0 gy-3">
            {loading ? (
                    <div
                      className=" fw-bold fs-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap:40
                      }}
                    >   
                    <ThreeDots 
                        height="80" 
                        width="80" 
                        radius="9"
                        color="#ADC4CE" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        visible={true}
                        />
                      Loading...
                    </div>
                  ) : (
                    <>
            <thead>
                  <tr className="fw-bold fs-4" style={{ color: "#454548" ,}}>
                    <th className="text-center">#</th>
                    <th className="text-center">Email</th>
                    <th className="text-center">First Name</th>
                    <th className="text-center">Last Name</th>
                    <th className="text-center">Locality</th>
                    <th className="text-center">State</th>
                    <th className="text-center">City</th>
                    <th className="text-center">Country</th>
                    <th className="text-center">Created At</th>
                  </tr>
                </thead>
                <tbody>
                {!customerDetailData.getUserMerchant ? (
                    <div
                      className=" fw-bold fs-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 105,
                      }}
                    >
                      Data not found!!
                    </div>
                  ) : (
                    <>
                      {customerDetailData.getUserMerchant.length > 0 &&
                        customerDetailData.getUserMerchant?.map(
                          (val: ICustomerOverallDetail | any) => {
                            return (
                              <tr className="text-center fs-6">
                                <td className="">{val.id}</td>
                                <td className="">
                                  {val.user.email}
                                </td>
                                <td className="">
                                  {val.user.firstname}
                                </td>
                                <td className=" ">
                                  {val.user.lastname}
                                </td>
                                <td className=" ">
                                  {val.user.locality}
                                </td>
                                <td className=" ">
                                  {val.user.state}
                                </td>
                                <td className=" ">{val.user.city}</td>
                                <td className="">
                                  {val.user.country}
                                </td>
                                <td className="">
                                  {moment(val.user.createdAt).format(
                                    "MMM DD YYYY h:mm A"
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </>
                  )} 
                </tbody>
                </>
                  )}
             </table>
         </div>
         </div>    
        </div>
      )}
        </>
    )
}