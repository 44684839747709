import { Modal } from "react-bootstrap"; /* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { useFormik } from "formik";
import React, { FC } from "react";
import { createRole } from "../../../types/commonTypes";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { redTypes } from "../../../constants/reduxTypes";
import { createRoles } from "../../../actions/apiRequests";
type Props = {
  show: boolean;
  handleClicks: () => void;
  data: any;
};

const registrationSchema = Yup.object().shape({
  name: Yup.string().required("Role's name is required"),
  //  permissions: Yup.string()
  //   .required('Permissions must be required'),
});

export function Dropdown1({ show, handleClicks, data }: Props) {
  //console.log('data for update',data)
  const acl_obj: any = {};
  if (data.acl_str) {
    const acl_str = data.acl_str;
    const acl_arr = acl_str.split(",").map((item: any) => item.trim());
    //const acl_obj = {};

    acl_arr.forEach((item: any) => {
      // console.log('item for for each',item.replace(/'/g, ''))
      // arr.push(item)
      acl_obj[item] = true;
    });
  }
  //console.log('acl_obj',acl_obj.dashboard);
  const dispatch = useDispatch();
  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const addRoles: any = useSelector<any>(
    (state) => state.billListReducer?.Roles
  );
  //  console.log('addroles',typeof(addRoles))

  React.useEffect(() => {
    dispatch({ type: redTypes.CLEAR_ROLES });
  }, [addRoles]);

  React.useEffect(() => {
    if (typeof addRoles === "object") {
      //console.log("useEffect");
      handleClicks();
    }
  }, [addRoles]);
  const [inputs, setInputs] = React.useState<createRole>({
    id: data.id ? data.id : 0,
    name: data ? data.name : "",
    permissions: {
      dashboard: acl_obj.dashboard ? "dashboard" : "",
      bills: acl_obj.bills ? "bills" : "",
      customers: acl_obj.customers ? "customers" : "",
      stores: acl_obj.stores ? "stores" : "",
      apiKeys: acl_obj.apiKeys ? "apiKeys" : "",
      offers: acl_obj.apikeys ? "offers" : "",
    },
  });

  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    status,
    setStatus,
  } = useFormik({
    initialValues: {
      id: inputs.id,
      name: inputs.name,
      dashboard: inputs.permissions.dashboard,
      bills: inputs.permissions.bills,
      customers: inputs.permissions.customers,
      stores: inputs.permissions.stores,
      apiKeys: inputs.permissions.apiKeys,
      offers: inputs.permissions.offers,
      //permissio_listns:inputs.permissions
    },
    validationSchema: registrationSchema,
    onSubmit: async (values) => {
      let arr = [];
      // console.log('values',values)
      if (values?.dashboard.length == 1 || values.dashboard == "dashboard") {
        arr.push("dashboard");
      }
      if (values?.bills.length == 1 || values.bills == "bills") {
        arr.push("bills");
      }
      if (values?.customers.length == 1 || values.customers == "customers") {
        arr.push("customers");
      }
      if (values?.stores.length == 1 || values.stores == "stores") {
        arr.push("stores");
      }
      if (values?.apiKeys.length == 1 || values.apiKeys == "apiKeys") {
        arr.push("apiKeys");
      }
      if (values?.offers.length == 1 || values.offers == "offers") {
        arr.push("offers");
      }

      console.log("arr", arr);
      const stringValue = arr.join(",");

      console.log(stringValue);
      if (values?.id !== 0) {
        console.log("update", values);
        const payload = {
          id: values.id,
          name: values.name,
          acl_str: stringValue,
          subdomains: loginSuccess.domain,
        };
        dispatch(createRoles(payload));
      } else {
        console.log("add", values);
        const payload = {
          id: values.id,
          name: values.name,
          acl_str: stringValue,
          subdomains: loginSuccess.domain,
        };
        dispatch(createRoles(payload));
      }

      //console.log('res',res)

      //  dispatch({
      //       type:redTypes.ADD_ROLES,
      //       payload: {
      //           name:values.name,
      //           acl_str:stringValue,
      //           subdomains:loginSuccess.domain
      //       },
      //     });
      // handleClicks()
    },
  });

  // console.log('dropdown')
  return (
    <>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-500px"
        show={show}
        onHide={handleClicks}
        //onEntered={loadStepper}
        backdrop={true}
      >
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">Create Role</div>
        </div>

        <div className="separator border-gray-200"></div>

        <div className="px-7 py-5">
          <div className="mb-10">
            <label className="form-label fw-bold">Role</label>
            <input
              type="text"
              placeholder="Enter the Role's name"
              name="name"
              className={clsx(
                "form-control form-control-solid",
                { "is-invalid": touched.name && errors.name },
                {
                  "is-valid": touched.name && !errors.name,
                }
              )}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className="mb-10">
            <label className="form-label fw-bold">Permissions</label>

            {/* <div
              className="d-flex"
              style={{ display: "flex", justifyContent: "space-between" }}
            > */}
              <div className='row g-9 mb-7'>
          <div className='col-md-3'>
              <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="dashboard"
                  name="dashboard"
                  onChange={handleChange}
                  defaultChecked={acl_obj.dashboard ? true : false}
                />
                <span className="form-check-label px-6">Dashboard</span>
              </label>
              </div>
              <div className='col-md-3'>
              <label className="form-check form-check-sm form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="bills"
                  value="bills"
                  onChange={handleChange}
                  defaultChecked={acl_obj.bills ? true : false}
                />
                <span className="form-check-label px-6">Bills</span>
              </label>
                </div>
                <div className='col-md-3'>
              <label className="form-check form-check-sm form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="customers"
                  value="customers"
                  onChange={handleChange}
                  defaultChecked={acl_obj.customer ? true : false}
                />
                <span className="form-check-label px-6">Customers</span>
              </label>
              </div>

              <div className='col-md-3'>
              <label className="form-check form-check-sm form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="stores"
                  value="stores"
                  onChange={handleChange}
                  defaultChecked={acl_obj.stores ? true : false}
                />
                <span className="form-check-label px-6">stores</span>
              </label>
              </div>
              </div>
              <div className='row g-9 mb-7'>
              <div className='col-md-3'>
              <label className="form-check form-check-sm form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="apiKeys"
                  value="apiKeys"
                  onChange={handleChange}
                  defaultChecked={acl_obj.apiKeys ? true : false}
                />
                <span className="form-check-label px-6">apiKeys</span>
              </label>
              </div>
              <div className='col-md-3'>
              <label className="form-check form-check-sm form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="offers"
                  value="offers"
                  onChange={handleChange}
                  defaultChecked={acl_obj.offers ? true : false}
                />
                <span className="form-check-label px-6">offers</span>
              </label>
              </div>
              </div>
            </div>
          {/* </div> */}

          <div className="d-flex justify-content-center" style={{ gap: 6 }}>
            <button
              onClick={() => handleSubmit()}
              className="btn fw-bold btn-bg-primary"
              style={{ color: "white" }}
              data-kt-menu-dismiss="true"
            >
              Submit
            </button>

            <button
              onClick={handleClicks}
              type="reset"
              className="btn fw-bold btn-bg-secondary"
              style={{ color: "#7E8299" }}
              data-kt-menu-dismiss="true"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
