import {put, takeEvery, call,select} from 'redux-saga/effects';
import {redTypes} from '../../constants/reduxTypes';
import { IBillListSuccessResponse } from '../../types/commonTypes';
import { roleRequestApi } from '../../services/commonApiCall';


function* role(param:object) {
  // console.log('domain',param);

try {
   
 const response:IBillListSuccessResponse =yield roleRequestApi(param)
//  console.log('bill',response.data);

if (response && !response.message) {
yield put({type: redTypes.ROLE_SUCCESS, payload: response});

}
else{
  yield put({
    type: redTypes.ROLE_FAILURE,
    errorMessage: response.message,
    errorCode: response.errorCode,
    });
}
}  catch (e) {
yield put({
type: redTypes.ROLE_FAILURE,
errorMessage: 'Something went wrong',
});
}

}
export default function* roleSaga():any {
     return yield takeEvery (redTypes.ROLE_REQUEST,role);
  }