import React, { FC, useState } from "react";
import { redTypes } from "../../../constants/reduxTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  checkIsActive,
  KTIcon,
  WithChildren,
} from "../../../../_metronic/helpers";
import { Dropdown1 } from "./dropdown";
import { getRolesResponse, role } from "../../../types/commonTypes";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ReactPaginate from 'react-paginate';


export const Roles: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [popUp, setPopUp] = React.useState<boolean>(false);
  const [data, setData] = React.useState<role>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = React.useState<number>();
  const [rolesData, setRolesData] = React.useState();
  //console.log('rolesData',rolesData);
  const [currentPages, setCurrentPages] = useState<number>(0)
  const [pageCount, setPageCount] = useState<number>(0)
  const [totalData, setTotalData] = useState<number>(0)


  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const addRoles: any = useSelector<any>(
    (state) => state.billListReducer?.Roles
  );
  // console.log("addRoles", addRoles);

  const getRoles: any = useSelector<any>(
    (state) => state.billListReducer?.getRoles
  );
  console.log('getRoles',getRoles);

  const errorGetRoles: any = useSelector<any>(
    (state) => state.billListReducer?.errorGetRoles
  );
  // console.log("errorGetRoles", errorGetRoles);
  const errorCodeRoles: any = useSelector<any>(
    (state) => state.billListReducer?.errorCodeRoles
  );
  const deleteRoles: any = useSelector<any>(
    (state) => state.billListReducer?.deleteRoles
  );
  const adminUserData: any = useSelector<any>(
    (state) => state.billListReducer?.adminSuccess
  );
//   let item: any = [];
//   React.useEffect(() => {
// for(var i = 0; i < adminUserData.length; i++) {
//   var dataItem = adminUserData[i];
//   // console.log('dataItem',dataItem);
//   var datas = dataItem.role
//   console.log('datas',datas);
//   setRolesData(datas)
//   item.push(datas);
// }
// }, []);
// console.log('item',item);

  // React.useEffect(() => {
  //   dispatch({
  //     type: redTypes.GET_ROLES,
  //     payload: {domain:loginSuccess.domain,currentPages:currentPages},
  //   });
  // }, []);

  React.useEffect(() => {
    dispatch({
      type: redTypes.GET_ROLES,
      payload: {domain:loginSuccess.domain,currentPages:currentPages},
    });
  }, [addRoles,deleteRoles,currentPages]);

 

  // React.useEffect(() => {
  //   dispatch({
  //     type: redTypes.GET_ROLES,
  //     payload: loginSuccess.domain,
  //   });

  // }, []);
  const handleClick = () => {
    setData({});
    setPopUp(!popUp);
  };

  const handleClickData = (val: role) => {
    setData(val);
    setPopUp(!popUp);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const handleDeleteData = (val: number) => {
    setShowModal(true);
    console.log("delete id", val);
    setDeleteId(val);
    // dispatch({
    //   type: redTypes.DELETE_ROLES,
    //   payload: {
    //     id: val,
    //     subdomains: loginSuccess.domain,
    //   },
    // });
  };
  const deletePopUp = () => {
    dispatch({
      type: redTypes.DELETE_ROLES,
      payload: {
        id: deleteId,
        subdomains: loginSuccess.domain,
      },
    });
  };
  React.useEffect(() => {
    if(deleteRoles === true){
    handleClose()
    dispatch({ type: redTypes.CLEAR_ROLES });
    }
  }, [deleteRoles]);
  const handleErrorClick = () => {
    dispatch({ type: redTypes.RESET_SET_STATE });
    navigate("/dashboard");
  };
  React.useEffect(() => {
    if(getRoles){
    const perPage = getRoles.count
          setTotalData(perPage)
          const total = Math.ceil(perPage / 10)
          setPageCount(total)
    }
  }, [getRoles]);
  const handlePageClick = (e:any) => {
    const selectedPage = e.selected * 1;
    setCurrentPages(selectedPage)
  };
  return (
    <>
      {popUp && (
        <Dropdown1 handleClicks={handleClick} show={popUp} data={data} />
      )}
      {errorGetRoles ? (
        <div className="d-flex  justify-content-center align-item-center text-center p-10">
          <div className="card card-flush  w-lg-650px py-5">
            <div className="card-body py-15 py-lg-20">
              {/* begin::Title */}
              <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Error!</h1>
              {/* end::Title */}

              {/* begin::Text */}
              <div className="fw-semibold fs-4 text-gray-500 mb-5">
                ErrorCode - {errorCodeRoles} : {errorGetRoles}
              </div>
              <div className="mb-3">
                <img
                  src={require("../../../images/error.png")}
                  className="mw-50 mh-150px theme-light-show"
                  alt=""
                />
              </div>
              <div className="mb-0">
                <button
                  type="button"
                  className="btn btn-bg-success"
                  style={{ color: "white" }}
                  onClick={handleErrorClick}
                >
                  Return Home
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
         <div className="d-flex flex-direction-row justify-content-between px-3">
        <div className=" align-items-start d-flex flex-direction-row px-2 mb-2"style={{gap:15,color:'#009ef7',fontSize:'24px'}}>
                {/* <h3 className="card-title align-items-start flex-row"style={{gap:5}}> */}
                <KTIcon iconName="shield-cross" className="fs-1 mt-2" />
                  <span className="card-label fw-bold">
                  Roles
                  </span>
                {/* </h3> */}
               
              </div>
              <div className=" d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn mb-4"
                    style={{ backgroundColor: "#c2e5f9", color: "#009ef7" }}
                    onClick={handleClick}
                  >
                    <KTIcon iconName="plus" className="fs-3" />
                    Add Role
                  </button>
                </div>
                </div>
        <div className="card mb-5 mb-xl-8">
          {/* <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start  flex-row"style={{gap:5}}>
            <KTIcon iconName="shield-cross" className="fs-1" />
              <span className="card-label fw-bold fs-3 mb-1">Roles</span>
            </h3>
            <div className="card-toolbar">

              <button
                type="button"
                className="btn btn-sm btn-light"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="top-end"
                onClick={handleClick}
              >
             <KTIcon iconName="plus" className="fs-3" /> Add Role
              </button>

            </div>
          </div> */}

          <div className="card-body py-3">
            <div className="table-responsive py-5">
              <table className="table table-row-dashed table-row-gray-300 table-hover gy-5">
                <thead>
                  <tr className="fw-bold fs-4" style={{ color: "#454548" }}>
                    <th className="text-center">#</th>
                    <th className="text-center">Roles</th>
                    <th className="text-center">PERMISSIONS</th>
                    <th className="text-center">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {!getRoles.getRoles ? (
                    <div
                      className=" fw-bold fs-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 105,
                      }}
                    >
                      Data not found!!
                    </div>
                  ) : (
                    <>
                      {getRoles.getRoles.length > 0 &&
                        getRoles.getRoles?.map((val: getRolesResponse) => {
                         //  console.log('valuescustomerDetailData',val)
                          return (
                            <tr className="text-center fs-6">
                              <td className="">{val.id}</td>
                              <td className="">{val.name}</td>
                              <td className="">{val.acl_str}</td>
                              <td className="">
                                <div className="d-flex justify-content-center flex-shrink-0">
                                  <a
                                    href="#"
                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"style={{color:'green',backgroundColor:'#d4ebde'}}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Edit"
                                    onClick={() => {
                                      handleClickData(val);
                                    }}
                                  >
                                    <KTIcon
                                      iconName="pencil"
                                      className="fs-3"
                                    />
                                  </a>
                                  <a
                                    href="#"
                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"style={{color:'red',backgroundColor:'#f3e7e6'}}
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Delete"
                                    onClick={() => {
                                      handleDeleteData(val.id);
                                    }}
                                  >
                                    <KTIcon iconName="trash" className="fs-3" />
                                  </a>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer border-0 pt-5  d-flex justify-content-between">
           <div className=" ">
            <span className="fs-3"> Total - {totalData}</span>
            <span className='text-dark px-1 fs-6 lh-0'>Roles</span>
           </div>
           <div className="d-flex px-15 "style={{color:'black'}}>
           <ReactPaginate
            previousLabel={ <span
            
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Previous"
            >
               <KTIcon iconName='arrow-left' className='fs-1 fw-bold' /> </span>}
            nextLabel={<span
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Next"
            >
            <KTIcon iconName='arrow-right' className='fs-1 fw-bold' /></span>}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel={"..."}
            breakClassName="break-me"
            breakLinkClassName="page-link"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
            />
           </div>
          </div>
        </div>
        </>
      )}
       {showModal ? (
        <Modal
          id="kt_modal_create_app"
          tabIndex={-1}
          aria-hidden="true"
          dialogClassName="modal-dialog modal-dialog-centered mw-350px"
          show={showModal}
          onHide={handleClose}
          backdrop={true}
        >
          <div className="modal-title fs-6 fw-bold d-flex justify-content-center py-5">
            <span style={{ paddingTop: 8 }}> Are you sure want to Delete?</span>
          </div>
          <div className="modal-body d-flex justify-content-center fw-semibold py-2 mb-4"style={{gap:5}}>
            <a
              href="#"
              className="btn btn-bg-primary"
              style={{ color: 'white' }}
              onClick={deletePopUp}
            >
              Yes
            </a>
            <a
              href="#"
              className="btn btn-bg-secondary"
              style={{ color:"#7E8299"  }}
              onClick={handleClose}
            >
              No
            </a>
          </div>
        </Modal>
      ) : null}
    </>
  );
};
