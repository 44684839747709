import { put, takeEvery, call, select } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";
import { signUpRequestApi } from "../../services/apiCalls";
import {
  SignUpResponse,
  SignupRequest,
} from "../../types";

function* register(param: SignupRequest) {
  try {
    const response: SignUpResponse = yield signUpRequestApi(param);

    if (response?.status === 201) {
      yield put({
        type: redTypes.SIGNUP_SUCCESS,
        payload: response.data.verificationSend,
      });
    } else if (response.message) {
      yield put({
        type: redTypes.SIGNUP_FAILURE,
        errorMessage: response.message,
      });
    } else {
      yield put({
        type: redTypes.SIGNUP_FAILURE,
        errorMessage: "Something went wrong",
      });
    }
  } catch (e) {
    yield put({
      type: redTypes.SIGNUP_FAILURE,
      errorMessage: "Something went wrong",
    });
  }
}
export default function* registerSaga(): any {
  return yield takeEvery(redTypes.SIGNUP_REQUEST, register);
}
