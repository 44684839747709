/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {  useSelector } from 'react-redux';
import { ColorRing } from  'react-loader-spinner'




export function Spinner() {
 // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const spinner = useSelector<any>(state => state.authReducer?.spinner);


  return (
    <div className='d-flex flex-column flex-root'>
    <div className='d-flex flex-column flex-center flex-column-fluid'>
      <div className='d-flex flex-column flex-center text-center p-10'>
        <div className='card card-flush  w-lg-650px py-5'>
          <div className='card-body py-15 py-lg-20'>
          <ColorRing
  visible={true}
  height="80"
  width="80"
  ariaLabel="blocks-loading"
  wrapperStyle={{}}
  wrapperClass="blocks-wrapper"
  colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
/>
<div className='text-gray-500 fw-semibold fs-6'>Please wait...</div> 
          </div>
        </div>
      </div>
    </div>
  </div>
      


  )
}

