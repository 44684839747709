import {put, takeEvery, call,select} from 'redux-saga/effects';
import {redTypes} from '../../constants/reduxTypes';
import { changePasswordWithEmailResponse } from '../../types/commonTypes';
import { changePasswordWithEmailApi } from '../../services/apiCalls';


function* changePasswordWithOtp(param:object) {
  console.log('domain otp',param);

try {
   
 const response:changePasswordWithEmailResponse =yield changePasswordWithEmailApi(param)
 console.log('responseForChangePassword',response);

if (response) {
  // console.log('billnumber',response);
yield put({type: redTypes.CHANGE_PASSWORD_WITH_OTP_SUCCESS, payload: response});

}
}  catch (e) {
// yield put({
// type: redTypes.SIGNUP_FAILURE,
// errorMessage: 'Something went wrong',
// });
}

}
export default function* changePasswordWithOtpSaga():any {
     return yield takeEvery (redTypes.CHANGE_PASSWORD_WITH_OTP, changePasswordWithOtp);
  }