import React, { FC, useState } from "react";
import { TablesBillList } from "../../../../_metronic/partials/widgets/tables/TableBillList";
import { KTIcon, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { BillListPopUp } from "./billListPopUp";
import { useDispatch, useSelector } from "react-redux";
import { redTypes } from "../../../constants/reduxTypes";
import { useNavigate } from "react-router-dom";
import { IBillListSuccessResponse, IStores } from "../../../types/commonTypes";
import moment from "moment";
import { ErrorBoundaryBills } from "./ErrorBoundary";
import { Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import "./index.css";
import { ThreeDots } from "react-loader-spinner";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

interface ReactPaginateProps {
  previousLabel: string;
  nextLabel: string;
  breakLabel?: string;
  breakClassName?: string;
  pageCount: number;
  marginPagesDisplayed?: number;
  pageRangeDisplayed?: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  containerClassName?: string;
  activeClassName?: string;
  subContainerClassName?: any;
}
export const BillListTable: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterStore, setFilterStore] = useState<string | undefined>();
  const [downloadData, setDownloadData] = useState<string | undefined>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentPages, setCurrentPages] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [totalData, setTotalData] = useState<number>(0);
  const [datas, setDatas] = useState<any>([]);
  //console.log('datas',datas);
  const [param, setParam] = React.useState("AllData");
  //console.log('datasParam',param);
  const [val, setVal] = useState<boolean>(false);
  //console.log('val',val);
  const [stateStore, setStateStore] = useState<boolean>(false);
    //console.log("stateStore", stateStore);
  const [state, setState] = useState<boolean>(false);
  //console.log("state", state);
  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  // console.log("successDataBill", loginSuccess);
  const billListData: any = useSelector<any>(
    (state) => state.billListReducer?.response
  );
   //console.log('billListData',billListData)
  const loading: any = useSelector<any>(
    (state) => state.billListReducer?.loading
  );
  const errorBills: any = useSelector<any>(
    (state) => state.billListReducer?.errorBills
  );
  // console.log("errorBills", errorBills);
  const storeData: any = useSelector<any>(
    (state) => state.billListReducer?.storeSuccess
  );
  const data: any = useSelector<any>(
    (state) => state.billListReducer?.billsDashboard
  );
  const storeCreationSuccess: any = useSelector<any>(
    (state) => state.billListReducer?.storeCreationSuccess
  );
  const handleViewClick = (val: any) => {
    const value = `/bills/invoice/${val.bill_number}`;
    dispatch({
      type: redTypes.BILLS_INVIOCE_REQUEST,
      payload: { domain: loginSuccess.domain, billsNumber: val.bill_number },
    });
    dispatch({
      type: redTypes.CUSTOMER_DETAIL_REQUEST,
      payload: { customer_id: val.customer_id, domain: loginSuccess.domain },
    });
    navigate(value);
  };

  React.useEffect(() => {
    var apiParam;

    if (param == "AllData") {
      console.log("AllData");

      dispatch({
        type: redTypes.BILL_LIST_TABLE_REQUEST,
        payload: {
          domain: loginSuccess.domain,
          currentPages: currentPages,
          overAll: "AllData",
        },
      });
    } else if (param == "Store") {
      console.log("filterStore");
      dispatch({
        type: redTypes.BILL_LIST_TABLE_REQUEST,
        payload: {
          domain: loginSuccess.domain,
          currentPages: currentPages,
          storeId: filterStore,
          startDate: "",
          endDate: "",
        },
      });
    } else if (param == "Date") {
      console.log("CreatedAt");
      dispatch({
        type: redTypes.BILL_LIST_TABLE_REQUEST,
        payload: {
          domain: loginSuccess.domain,
          currentPages: currentPages,
          storeId: "",
          startDate: datas[0],
          endDate: datas[1],
        },
      });
    } else if (param == "StoresAndDate") {
      console.log("StoresAndDate");
      dispatch({
        type: redTypes.BILL_LIST_TABLE_REQUEST,
        payload: {
          domain: loginSuccess.domain,
          currentPages: currentPages,
          startDate: datas[0],
          endDate: datas[1],
          storeId: filterStore,
        },
      });
    } else {
      console.log("empty");
      apiParam = "";
    }
  }, [currentPages]);

  React.useEffect(() => {
    dispatch({
      type: redTypes.STORE_REQUEST,
      payload:{domain:loginSuccess.domain,currentPages:currentPages},
    });
  }, [storeCreationSuccess]);
  // React.useEffect(() => {
  //   if(downloadData){
  //     console.log('downloadData',downloadData);
  //   dispatch({
  //     type: redTypes.BILLS_DASHBOARD_REQUEST,
  //     payload: {domain:loginSuccess.domain,data:downloadData,downloadCsv:true},
  //   });
  // }
  // }, [downloadData]);

  // const handleDownloadClick = () => {
  //   console.log('onClick Success');
  //   if(createDate){
  //     console.log('createDate',createDate);
  //     dispatch({
  //       type: redTypes.BILLS_DASHBOARD_REQUEST,
  //       payload: {domain:loginSuccess.domain,data:createDate,downloadCsv:false},
  //     });
  //   }
  //   else{
  //     console.log('downloadData',downloadData);
  //   dispatch({
  //     type: redTypes.BILLS_DASHBOARD_REQUEST,
  //     payload: {domain:loginSuccess.domain,data:"current-year",downloadCsv:true},
  //   });
  // }
  // };
  //const handlestoreClick = () => {
     React.useEffect(() => {
    console.log("handlestoreClick");
    //setStateStore(true);
    if (stateStore&&filterStore && !val) {
      setParam("Store");
      dispatch({
        type: redTypes.BILL_LIST_TABLE_REQUEST,
        payload: {
          domain: loginSuccess.domain,
          currentPages: currentPages,
          storeId: filterStore,
          startDate: "",
          endDate: "",
        },
      });
    } else if (stateStore&&!filterStore && !val) {
      setParam("AllData");
      setStateStore(false);
      dispatch({
        type: redTypes.BILL_LIST_TABLE_REQUEST,
        payload: {
          domain: loginSuccess.domain,
          currentPages: currentPages,
          overAll: "AllData",
        },
      });
    } else if (stateStore&&filterStore && val) {
      setState(true);
    }
    // else{
    //   setParam("AllData")
    //   dispatch({
    //     type: redTypes.BILL_LIST_TABLE_REQUEST,
    //     payload: {domain:loginSuccess.domain,currentPages:currentPages,overAll:"AllData"},
    //   });
    // }
     }, [filterStore,stateStore]);
 // };

  React.useEffect(() => {
    if (val && datas && !stateStore) {
      setParam("Date");
      dispatch({
        type: redTypes.BILL_LIST_TABLE_REQUEST,
        payload: {
          domain: loginSuccess.domain,
          currentPages: currentPages,
          storeId: "",
          startDate: datas[0],
          endDate: datas[1],
        },
      });
    } else if (val && !datas && !stateStore) {
      setParam("AllData");
      setVal(false)
      dispatch({
        type: redTypes.BILL_LIST_TABLE_REQUEST,
        payload: {
          domain: loginSuccess.domain,
          currentPages: currentPages,
          overAll: "AllData",
        },
      });
    } else if (val && datas && stateStore) {
      setState(true);
    }
    // else{
    //   setParam("AllData")
    //   dispatch({
    //     type: redTypes.BILL_LIST_TABLE_REQUEST,
    //     payload: {domain:loginSuccess.domain,currentPages:currentPages,overAll:"AllData"},
    //   });
    // }
    // }
  }, [val, datas, stateStore]);

  React.useEffect(() => {
    if (state && datas && filterStore) {
      // console.log('StoresAndDate');
      setParam("StoresAndDate");
      dispatch({
        type: redTypes.BILL_LIST_TABLE_REQUEST,
        payload: {
          domain: loginSuccess.domain,
          currentPages: currentPages,
          startDate: datas[0],
          endDate: datas[1],
          storeId: filterStore,
        },
      });
    } else if (state && !datas && filterStore) {
      // setState(false)
      setParam("Store");
      dispatch({
        type: redTypes.BILL_LIST_TABLE_REQUEST,
        payload: {
          domain: loginSuccess.domain,
          currentPages: currentPages,
          storeId: filterStore,
          startDate: "",
          endDate: "",
        },
      });
    } else if (state && datas && !filterStore) {
      // setState(false)
      setParam("Date");
      dispatch({
        type: redTypes.BILL_LIST_TABLE_REQUEST,
        payload: {
          domain: loginSuccess.domain,
          currentPages: currentPages,
          storeId: "",
          startDate: datas[0],
          endDate: datas[1],
        },
      });
    } else if (state && !datas && !filterStore) {
      setState(false);
      setStateStore(false);
      setVal(false)
      setParam("AllData");
      dispatch({
        type: redTypes.BILL_LIST_TABLE_REQUEST,
        payload: {
          domain: loginSuccess.domain,
          currentPages: currentPages,
          overAll: "AllData",
        },
      });
    }
  }, [state, datas, filterStore]);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };
  React.useEffect(() => {
    if (billListData) {
      const perPage = billListData.count;
      setTotalData(perPage);
      const total = Math.ceil(perPage / 10);
      setPageCount(total);
    }
  }, [billListData]);
  const handlePageClick = (e: any) => {
    const selectedPage = e.selected * 1;
    setCurrentPages(selectedPage);
  };
  return (
    <>
      {errorBills ? (
        <ErrorBoundaryBills />
      ) : (
        <>
          <div
            className=" align-items-start d-flex flex-direction-row px-2 mb-2"
            style={{ gap: 15, color: "#009ef7", fontSize: "24px" }}
          >
            <KTIcon iconName="document" className="fs-1 mt-2" />
            {/* <h3 > */}
            <span className=" fw-bold  ">Bills</span>
            {/* </h3>   */}
          </div>
          <div className="card mb-5 mb-xl-8">
            <div
              className="card-toolbar d-flex mt-6 px-5 justify-content-end"
              style={{ gap: 8 }}
            >
              <select
                className="form-select form-select-solid fw-bolder"
                style={{
                  width: 130,
                   backgroundColor: "#f1faff",
                  // color: "#009ef7",
                  cursor: "pointer",
                }}
                data-kt-select2="true"
                data-placeholder="Select option"
                // data-allow-clear='true'
                onChange={(e) => {setFilterStore(e.target.value)    
                   setStateStore(true)
                }}
                //onClick={(e) => handlestoreClick()}
                value={filterStore}
              >
                <option value="" disabled selected hidden style={{color: "#009ef7"}}>
                  Store
                </option>
                <option value="">All Stores</option>
                {storeData.getStores
                  ? storeData.getStores?.map((survey: IStores) => (
                      <option key={survey.name} value={survey.id}>
                        {survey.name}
                      </option>
                    ))
                  : null}
              </select>
              <div
                className=""
                style={{ backgroundColor: "#f1faff", color: "#009ef7" }}
              >
                <RangePicker
                  showTime={{  format: 'hh:mm A', // 'hh:mm A' for 12-hour format with AM/PM
                  use12Hours: true,   // Set use12Hours to true for 12-hour format
                }}
                  style={{
                    width: 220,
                    height: 42,
                    backgroundColor: "#f1faff",
                    color: "#009ef7",
                    border: "none",
                  }}
                  onChange={(values) => {
                    setDatas(
                      values?.map((item: any) => {
                        //let dateString=item.format().split('+')[0];
                        const dateFormat = item.toISOString();
                        return dateFormat;
                      })
                    );
                    setVal(true);
                  }}
                />
                {/* <button type='button' className='btn  me-3'
            style={{backgroundColor:'#f1faff',color:'#009ef7'}}
            onClick={(e) => handleDownloadClick()}
            >
                  {data && (
                      <a style={{ textDecoration: 'none' }} 
                        href={`data:text/csv;charset=utf-8,${escape(data)}`}
                        download="transaction.csv"  >
                          <KTIcon iconName='exit-up' className='fs-2' />
                      Export
                      </a>

                    )}
      </button> */}
                {/* {data && (
                      <a style={{ textDecoration: 'none' }} 
                        href={`data:text/csv;charset=utf-8,${escape(data)}`}
                        download="transaction.csv"  >
                          <KTIcon iconName='exit-up' className='fs-2' />
                      Export
                      </a>

                    )} */}
                {/* </button> */}
              </div>
            </div>
            {/* </div> */}
            <div className="card-body py-3">
              <div className="table-responsive ">
                <table className="table table-row-dashed table-row-gray-300 table-hover  gs-0 gy-3">
                  {loading ? (
                    <div
                      className=" fw-bold fs-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 40,
                      }}
                    >
                      <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#ADC4CE"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        visible={true}
                      />
                      Loading...
                    </div>
                  ) : (
                    <>
                      <thead>
                        <tr
                          className="fw-bold fs-4"
                          style={{ color: "#454548" }}
                        >
                          <th className="text-center">#</th>
                          <th className="text-center">Store</th>
                          <th className="text-center">Bill Number</th>
                          <th className="text-center">currency</th>
                          <th className="text-center">Irn</th>
                          <th className="text-center">Created At</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!billListData.getBill ? (
                          <div
                            className=" fw-bold fs-3"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: 105,
                            }}
                          >
                            Data not found!!
                          </div>
                        ) : (
                          <>
                            {billListData &&
                              billListData.getBill?.map(
                                (val: IBillListSuccessResponse, index: any) => {
                                  // console.log('values1', val)
                                  return (
                                    <tr key={index}>
                                      <td className="text-center fs-6">
                                        {val.id}
                                      </td>
                                      <td className="text-center fs-6">
                                        {val.stores?.name}
                                      </td>
                                      <td className="text-center  fs-6">
                                        {val.bill_number}
                                      </td>
                                      <td className="text-center  fs-6">
                                        {val.currency?val.currency:'No Data'}
                                      </td>
                                      <td className="text-center  fs-6">
                                        {val.irn}
                                      </td>
                                      <td className="text-center fs-6">
                                        {moment(val.createdAt).format(
                                          "MMM DD YYYY h:mm A"
                                        )}
                                      </td>
                                      <td className="text-center">
                                        <div className="d-flex justify-content-center flex-shrink-0">
                                          <a
                                            //href="#"
                                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                            style={{
                                              backgroundColor: "#e8e8eb",
                                            }}
                                            data-bs-toggle="tooltip "
                                            data-toggle="modal"
                                            title="View"
                                            onClick={(e) =>
                                              handleViewClick(val)
                                            }
                                          >
                                            <KTIcon
                                              iconName="eye"
                                              className="fs-3"
                                            />
                                          </a>

                                          {/* <a
                                      href="#"
                                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      data-bs-trigger="hover"
                                      title="Delete"
                                    >
                                      <KTIcon
                                        iconName="trash"
                                        className="fs-3"
                                      />
                                    </a> */}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                          </>
                        )}
                      </tbody>
                    </>
                  )}
                </table>
              </div>
            </div>
            <div className="card-footer border-0 pt-5  d-flex justify-content-between">
              <div className=" ">
                <span className="fs-3 "> Total - {totalData} </span>
                <span className="text-dark px-1 fs-6 lh-0">Bills</span>
              </div>
              <div className="d-flex px-15 " style={{ color: "black" }}>
                <ReactPaginate
                  previousLabel={
                    <span
                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
                      data-bs-toggle="tooltip "
                      title="Previous"
                    >
                      <KTIcon iconName="arrow-left" className="fs-1 fw-bold" />{" "}
                    </span>
                  }
                  nextLabel={
                    <span
                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
                      data-bs-toggle="tooltip "
                      title="Next"
                    >
                      <KTIcon iconName="arrow-right" className="fs-1 fw-bold" />
                    </span>
                  }
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel={"..."}
                  breakClassName="break-me"
                  breakLinkClassName="page-link"
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
            </div>
          </div>
        </>
      )}
      {/* </div> */}
      {/* </div> */}
    </>
  );
};
