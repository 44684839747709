import { configData } from '../../config/config.helper';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { redTypes } from '../constants/reduxTypes';
import React from 'react';
import { useDispatch ,useSelector} from 'react-redux';
import { api } from '../constants/api';


export const authHeader = async () => {
  try {
    const currentToken :any= JSON.parse(localStorage.getItem('currentToken')||'')
    if (checkTokenExpiry(currentToken)) {
        console.log('ExpiryCurrentToken');
        await RefreshToken();
        const currentTokens:any = JSON.parse(localStorage.getItem('currentToken')||'')
        return returnAuthHeader(currentTokens);
    } else {
        console.log('CurrentToken');
        return returnAuthHeader(currentToken);
    }
}
  catch(error:any)
  {
    throw Error(error)

  }
}
const returnAuthHeader = (currentToken:string) => {

  return {
    currentToken
  };
}

const RefreshToken = async () => {
  console.log('refresh');
  var refreshToken = JSON.parse(localStorage.getItem('refreshToken')||'');
  var uploadUrl = api.baseUrl + api.refreshToken;

  return await axios
    .post(uploadUrl,{refreshToken})   
    .then(response => {
      if (response.data) {
        localStorage.setItem("currentToken", JSON.stringify(response.data.token));
      }
      return response.data.token;
    }).catch (error => {
      console.log('error',error.response.data);
      
      throw Error( error.response.data)

    });

}





const checkTokenExpiry = (token:string) => {
  var decoded:any = jwt_decode(token);
  var toExpire = decoded.exp * 1000
  if (Date.now() > toExpire) {
    return true; //expired
  }
  return false; //not expired
}