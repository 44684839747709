import { getReview } from "../../types/commonTypes";
import { getReviewApi } from "../../services/apiCalls";
import { put, takeEvery, call, select } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";

function* getReviewSaga(param: object) {
    console.log('getReviewsSaga',param)
  try {
    const response: getReview = yield getReviewApi(param);

    if (response && !response.message) {
      yield put({ type: redTypes.GET_REVIEW_SUCCESSFULLY, payload: response });
    } else if(response.message) {
      yield put({
        type: redTypes.GET_ROLES_FAILURE,
        errorMessage: response.message,
        errorCode: response.errorCode,
      });
    }
    else {
      yield put({
        type: redTypes.GET_ROLES_FAILURE,
        errorMessage: "Something went wrong",
      });
    }
  } catch (e) {
    yield put({
      type: redTypes.GET_ROLES_FAILURE,
      errorMessage: "Something went wrong",
    });
  }
}
export default function* getReviewSagas(): any {
  return yield takeEvery(redTypes.GET_REVIEW_REQUEST, getReviewSaga);
}
