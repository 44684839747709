// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import { useDispatch, useSelector } from "react-redux"
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import Chart from "react-apexcharts";
import { redTypes } from '../../constants/reduxTypes'
import { IBillListSuccessResponse } from '../../../../modules/types/commonTypes'


type Props = {
  className: string
  //chartHeight: string
  backGroundColor: string
}


const Customerchart: React.FC<Props> =  ({className, backGroundColor, chartHeight}) => {

   const labelColor = getCSSVariableValue('--bs-gray-800')

   const dispatch = useDispatch();
   const chartRef = useRef<HTMLDivElement | null>(null)
   const loginSuccess: any = useSelector<any>(
     (state) => state.authReducer?.loginSuccess
   );
   const [dateCreate, setDateCreate] = useState<string | undefined>(
     "previous-month"
   );
   const[graphDataAmount,setGraphDataAmount] = useState<any>([])
  //  console.log('group',graphDataAmount);
   
   const[items,setItems] = useState<any>([])
  //  console.log('itemgroup',items);
   const billsDashboardData: any = useSelector<any>(
     (state) => state.billListReducer?.billsDashboard
   );

 
  
//    React.useEffect(() => {
//     dispatch({
//       type: redTypes.BILLS_DASHBOARD_REQUEST,
//       payload: {
//         domain: loginSuccess.domain,
//         data: dateCreate,
//         downloadCsv: false,
//       },
//     });
//   }, [dateCreate]);
   
//    const date = new Date();
//    const month = date.getMonth() + 1;
//    const currentYear = date.getFullYear();
//    const days = new Date(currentYear, month, 0).getDate();
//    const previousMonth =month -1;
//    // console.log('previousMonth',previousMonth);
//    const previousDays = new Date(currentYear, previousMonth, 0).getDate();
//  //  console.log('previousDays',previousDays);
 
//    var year = date.getFullYear();
 
//    React.useEffect(() => { 
//      //console.log('called useeffect')
//    var datesArray = [];
 
//    date.setDate(1);
//    while (date.getMonth() === previousMonth) {
//      var dates = date.getDate();
//      //  console.log('date',date);
//      var formattedDate =
//         year + "-" + ("0" + previousMonth).slice(-2) + "-" + ("0" + dates).slice(-2);
//       // year + '-' + previousMonth.toString().padStart(2, '0') + '-' + date.toString().padStart(2, '0');
//      datesArray.push(formattedDate);
//      date.setDate(dates + 1);
//    }
 
//   //console.log('datesArrays',datesArray);
 
//    var graphAmountData: any = [];
//    for (var k = 0; k < datesArray.length; k++) {
//      graphAmountData[k] = 0;
//    }
//    // console.log("graphAmountData", graphAmountData);
//    for (var j = 0; j < billsDashboardData.length; j++) {
//      var dataItem = billsDashboardData[j];
//      var groupDay = dataItem.group_day;
//      //console.log('groupDay',groupDay);
//      var totalValue = parseInt(dataItem.total_value);
//      var dateIndex = datesArray.findIndex(function (date: any) {
//        // console.log('dateIndex1',date);
//        return date === groupDay;
//      });
//      if (dateIndex !== -1) {
//        graphAmountData[dateIndex] = totalValue;
//      }
//    }
//   //  console.log("graphAmountData", graphAmountData);
//    if(graphAmountData.length>0){
//    //  console.log('change states')
//      setGraphDataAmount(graphAmountData)
//    }
//    else{
//     // console.log('null accept')
//    }
    
//    let itemData: any = [];
//    for (let i = 1; i <= previousDays; i++) {
//      itemData.push(i);
//  }
//  //console.log('item',itemData);
//   setItems(itemData)
//  }, [billsDashboardData]);
 

 



 const [state, setState] = useState<any>([]);
 const[option,setOption]=useState<any>({})
 useEffect(() => {
  setState([
    {
        name: 'Inflation',
        data: [0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,],
      },
  ]);
}, []);

  // console.log('state',state);
  // console.log('state2',graphDataAmount);

  useEffect(() => {
  setOption({
    chart: {
        fontFamily: 'inherit',
        height: chartHeight,
        type: 'bar',
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: false,
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      colors: ['#ffffff'],
      plotOptions: {
        bar: {
          borderRadius: 2.5,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
          columnWidth: '20',
        },
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return val + ''
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
        //  [
        //   'Jan',
        //   'Feb',
        //   'Mar',
        //   'Apr',
        //   'May',
        //   'Jun',
        //   'Jul',
        //   'Aug',
        //   'Sep',
        //   'Oct',
        //   'Nov',
        //   'Dec',
        //   'Jan',
        //   'Feb',
        //   'Mar',
        // ],
        position: 'top',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        // min: 0,
        // max: 10,
        show: false,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
          background: labelColor,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + ''
          },
        },
      },
})
}, []);


return (
//   <div
//   className={`card ${className} theme-dark-bg-body`}
//   style={{backgroundColor: backGroundColor}}
// >
<div className=''>
      <div className="mixed-chart">
        <Chart
          options={option}
          series={state}
          type="bar"
          width={430}
          height={100}
        />
      </div>
    </div>
  // </div>
);
}

export {Customerchart}
