import { put, takeEvery, call, select } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";
import { IReport } from "../../types/commonTypes";
import { billsDashboardApi } from "../../services/commonApiCall";

function* billsDashboard(param: object) {
  //  console.log('domain',param);

  try {
    const response: IReport = yield billsDashboardApi(param);
//    console.log('billsDashboard',response.message);

    if (response) {
      console.log("dashboard", response);
      yield put({ type: redTypes.BILLS_DASHBOARD_SUCCESS, payload: response });
    }
    //  else if (response.message) {
    //   console.log("billError", response.message);
    //   yield put({
    //     type: redTypes.BILL_LIST_TABLE_FAILURE,
    //     errorMessage: response.message,
    //     errorCode: response.errorCode,
    //   });
    // } else {
    //   console.log("Something Error");
    //   yield put({
    //     type: redTypes.BILL_LIST_TABLE_FAILURE,
    //     errorMessage: "Something went wrong",
    //   });
    // }
  } catch (e) {
    // yield put({
    //   type: redTypes.BILL_LIST_TABLE_FAILURE,
    //   errorMessage: "Something went wrong",
    // });
  }
}
export default function* billsDashboardSaga(): any {
  return yield takeEvery(redTypes.BILLS_DASHBOARD_REQUEST, billsDashboard);
}
