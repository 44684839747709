import React, { FC } from "react";
import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { Modal } from "react-bootstrap";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { KTIcon } from "../../../../_metronic/helpers";
import { adminCreation, offersCreation } from "../../../actions/apiRequests";
import {
  IAdminUser,
  IAdminUserCreate,
  IStores,
  Ioffers,
} from "../../../types/commonTypes";
import { redTypes } from "../../../constants/reduxTypes";
import { ICustomerOverallDetail } from "../../../types/commonTypes";
import { sharing } from "webpack";

type Props = {
  show: boolean;
  data?: any;
  handleClose: () => void;
};


const registrationSchema = Yup.object().shape({
  offer_title: Yup.string()
    .min(3, "Minimum 3 symbols")
    .required("Offer Title  is required"),
  offer_description: Yup.string()
    .min(3, "Minimum 3 symbols")
    .required("Offer Description is required"),
  store_id: Yup.string().required("Store  is required"),
  customer_id: Yup.string().required("Customer  is required"),
  offer_code: Yup.string().required("Offer Code is required"),
  // created_by: Yup.string()
  //   .min(3, 'Minimum 3 symbols')
  //   .required('Created by is required'),
});

export const CreateOffers = ({ show, handleClose, data }: Props) => {
  // console.log('offersData',data);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const storeData: any = useSelector<any>(
    (state) => state.billListReducer?.storeSuccess
  );
  const customerDetailData: any = useSelector<any>(
    (state) => state.billListReducer?.customerOverall
  );
  const offersCreationSuccess: any = useSelector<any>(
    (state) => state.billListReducer?.offersCreationSuccess
  );
  // console.log('offersCreationSuccess',offersCreationSuccess)

  React.useEffect(() => {
    dispatch({ type: redTypes.CLEAR_OFFERS_CREATION });
  }, [offersCreationSuccess]);

  React.useEffect(() => {
    if (typeof offersCreationSuccess === "object") {
      console.log("useEffect");
      handleClose();
    }
  }, [offersCreationSuccess]);
  const errorAdminCreate: any = useSelector<any>(
    (state) => state.billListReducer?.errorAdminCreate
  );
  const errorCodeAdminCreate: any = useSelector<any>(
    (state) => state.billListReducer?.errorCodeAdminCreate
  );

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };
  const [input, setInput] = useState<Ioffers>(
    data ? data : {
          id: 0,
          offer_title: "",
          offer_description: "",
          store_id: "",
          customer_id: "",
          offer_link: "",
          valid_till: "",
          offer_code: "",
          offer_image_url: "",
          created_by: "",
          updated_by: "",
        }
  );

  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    status,
    setStatus,
  } = useFormik({
    initialValues: {
      id: input.id,
      offer_title: input.offer_title,
      offer_description: input.offer_description,
      store_id: input.store_id,
      customer_id: input.customer_id,
      offer_link: input.offer_link,
      valid_till: input.valid_till,
      offer_code: input.offer_code,
      offer_image_url: input.offer_image_url,
      // created_by:loginSuccess.domain ||input.created_by,
      // updated_by: input.updated_by,
    },
    validationSchema: registrationSchema,
    onSubmit: async (values: any) => {
      console.log("onSubmit ");
      if (values?.id == 0) {
        console.log("offerCreate");
        const data = {
          id: values.id,
          offer_title: values.offer_title,
          offer_description: values.offer_description,
          store_id: parseInt(values.store_id),
          customer_id: parseInt(values.customer_id),
          offer_link: values.offer_link,
          valid_till: values.valid_till,
          offer_code: values.offer_code,
          offer_image_url: values.offer_image_url,
          created_by: loginSuccess.id,
          // updated_by:values.updated_by,
          subdomains: loginSuccess.domain,
        };
        //  console.log('offerCreate',data);

        dispatch(offersCreation(data));
      } else {
        console.log("offerUpdate");
        const UpdateData = {
          id: values.id,
          offer_title: values.offer_title,
          offer_description: values.offer_description,
          store_id: parseInt(values.store_id),
          customer_id: parseInt(values.customer_id),
          offer_link: values.offer_link,
          valid_till: values.valid_till,
          offer_code: values.offer_code,
          offer_image_url: values.offer_image_url,
          // created_by:values.created_by,
          updated_by: loginSuccess.id,
          subdomains: loginSuccess.domain,
        };
        dispatch(offersCreation(UpdateData));
      }
    },
  });

  return (
    <>
      <Modal
        id="kt_modal_create_admin"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-700px"
        show={show}
        onHide={handleClose}
        onEntered={loadStepper}
        backdrop={true}
      >
        <div className="modal-header">
          {!data ? <h2>New Offers</h2> : <h2>Offers Update</h2>}
          {/* begin::Close */}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={handleClose}
          >
            <KTIcon className="fs-1" iconName="cross" />
          </div>
          {/* end::Close */}
        </div>

        <div className="modal-body py-lg-10 px-lg-20 ">
          <div className="row g-9 mb-7">
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className=" fs-6 fw-semibold mb-2">Offer Title</label>
              <input
                type="text"
                placeholder="Offer Title"
                name="offer_title"
                className={clsx(
                  "form-control form-control-solid",
                  { "is-invalid": touched.offer_title && errors.offer_title },
                  {
                    "is-valid": touched.offer_title && !errors.offer_title,
                  }
                )}
                value={values.offer_title}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.offer_title && errors.offer_title && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert ">{errors.offer_title}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className=" fs-6 fw-semibold mb-2">
                Offer Description
              </label>
              <input
                type="text"
                placeholder="offer_description"
                name="offer_description"
                className={clsx(
                  "form-control form-control-solid",
                  {
                    "is-invalid":
                      touched.offer_description && errors.offer_description,
                  },
                  {
                    "is-valid":
                      touched.offer_description && !errors.offer_description,
                  }
                )}
                value={values.offer_description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.offer_description && errors.offer_description && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert ">{errors.offer_description}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="row g-9 mb-7">
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className=" fs-6 fw-semibold mb-2">Store</label>
              <select
                aria-label="Select example"
                name="store_id"
                className={clsx(
                  "form-select form-select-solid",
                  { "is-invalid": touched.store_id && errors.store_id },
                  {
                    "is-valid": touched.store_id && !errors.store_id,
                  }
                )}
                value={values.store_id}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value='' disabled selected hidden>Store</option>
                {storeData.getStores
                  ? storeData.getStores?.map((survey: IStores) => (
                      <option key={survey.name} value={survey.id}>
                        {survey.name}
                      </option>
                    ))
                  : null}
              </select>
              {touched.store_id && errors.store_id && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert ">{errors.store_id}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className=" fs-6 fw-semibold mb-2">Customer</label>
              <select
                aria-label="Select example"
                placeholder="Customer"
                name="customer_id"
                className={clsx(
                  "form-select form-select-solid",
                  { "is-invalid": touched.customer_id && errors.customer_id },
                  {
                    "is-valid": touched.customer_id && !errors.customer_id,
                  }
                )}
                value={values.customer_id}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value='' disabled selected hidden>Customer</option>
                {customerDetailData.getUserMerchant &&
                  customerDetailData.getUserMerchant?.map(
                    (survey: ICustomerOverallDetail) => (
                      <option
                        key={survey.user?.firstname}
                        value={survey.user?.id}
                      >
                        {survey.user?.firstname}
                      </option>
                    )
                  )}
              </select>
              {touched.customer_id && errors.customer_id && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert ">{errors.customer_id}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="row g-9 mb-7">
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className=" fs-6 fw-semibold mb-2">Offer Link</label>
              <input
                type="text"
                placeholder="Offer Link"
                name="offer_link"
                className="form-control form-control-solid"
                //    {'is-invalid': touched.offer_link && errors.offer_link},
                //    {
                //      'is-valid': touched.offer_link && !errors.offer_link,
                //    }
                //  )}
                value={values.offer_link}
                onChange={handleChange}
                //  onBlur={handleBlur}
              />
              {/* {touched.offer_link && errors.offer_link && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.offer_link}</span>
            </div>
          </div>
        )} */}
            </div>
            {data ? (
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <label className=" fs-6 fw-semibold mb-2">Valid Till</label>
                <input
                  type="text"
                  placeholder="Valid"
                  name="valid_till"
                  className="form-control form-control-solid"
                  value={values.valid_till}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <label className=" fs-6 fw-semibold mb-2">Valid Till</label>
                <input
                  type="datetime-local"
                  placeholder="Valid"
                  name="valid_till"
                  className="form-control form-control-solid"
                  value={values.valid_till}
                  onChange={handleChange}
                />
              </div>
            )}
          </div>

          <div className="row g-9 mb-7">
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className=" fs-6 fw-semibold mb-2">Offer Code</label>
              <input
                type="text"
                placeholder="Offer Code"
                name="offer_code"
                className={clsx(
                  "form-control form-control-solid",
                  { "is-invalid": touched.offer_code && errors.offer_code },
                  {
                    "is-valid": touched.offer_code && !errors.offer_code,
                  }
                )}
                value={values.offer_code}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.offer_code && errors.offer_code && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert ">{errors.offer_code}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-6 fv-row fv-plugins-icon-container">
              <label className=" fs-6 fw-semibold mb-2">Offer Image Url</label>
              <input
                type="text"
                placeholder="Offer Image Url"
                name="offer_image_url"
                className="form-control form-control-solid"
                value={values.offer_image_url}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row g-9 mb-7">
            {/* {!data ? (
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <label className=" fs-6 fw-semibold mb-2">Created By</label>
                <input
                  type="text"
                  placeholder="Created By"
                  name="created_by"
                  className=
                    "form-control form-control-solid"
                  //   { "is-invalid": touched.created_by && errors.created_by },
                  //   {
                  //     "is-valid": touched.created_by && !errors.created_by,
                  //   }
                  // )}
                  value={values.created_by}
                  onChange={handleChange}
                 // onBlur={handleBlur}
                />
              
              </div>
            ) : (
              <div className="col-md-6 fv-row fv-plugins-icon-container">
                <label className=" fs-6 fw-semibold mb-2">UpdatedBy</label>
                <input
                  type="text"
                  placeholder="UpdatedBy"
                  name="updated_by"
                  className="form-control form-control-solid"
                  value={values.updated_by}
                  onChange={handleChange}
                />
              </div>
            )} */}
          </div>

          {/* {errorAdminCreate  &&  (
                  <div className="alert alert-dismissible bg-secondary justify-content-center d-flex flex-column flex-sm-row p-3 mb-5 px-5"style={{}}>
                       <span className="text-center fw-bolder fs-6"style={{color:'#813636'}}>
                       ErrorCode - {errorCodeAdminCreate} : {errorAdminCreate}
                        </span> 
                      </div>
                  )} */}
        </div>

        <div className="modal-footer flex-center">
          <button
            onClick={() => handleSubmit()}
            className="btn btn-bg-primary fw-bold"
            style={{ color: "white" }}
          >
           {!data ? 'Create':'Update'}
          </button>
          <button
            className="btn fw-bold"
            onClick={handleClose}
            style={{ color: "#7E8299", backgroundColor: "#F9F9F9" }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
