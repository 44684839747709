import { all,fork } from 'redux-saga/effects';
import loginSaga from  "./handleApiCalls/loginSaga"
import  registerSaga  from './handleApiCalls/register';
import verifyCodeSaga from './handleApiCalls/verifyCodeSaga';
import createProfileSaga from './handleApiCalls/createProfileSaga';
import billListTableSaga from './handleApiCalls/billListTableSaga';
import customerListSaga from './handleApiCalls/customerListSaga';
import admiUserSaga from './handleApiCalls/adminUserSaga';
import customerOverAllDetailSaga from './handleApiCalls/customerDetailsSaga';
import admiUserCretionSaga from './handleApiCalls/createAdminSaga';
import roleSaga from './handleApiCalls/roleSaga';
import getRoleSaga from './handleApiCalls/getRoles'
import createRoleSaga from './handleApiCalls/create.role'
import deleteRoleSaga from './handleApiCalls/deleteRoles'
import changePasswordWithEmailSaga from './handleApiCalls/changePasswordWithEmail'
import changePasswordWithOtpSaga from './handleApiCalls/changePasswordWithOtp'
import updateAdminUsersSaga from './handleApiCalls/updateAdminUserSaga'
import adminLoginSaga from './handleApiCalls/adminLoginSaga';
import customerBillsSaga from './handleApiCalls/customerBills';
import billsDashboardSaga from './handleApiCalls/billsDashboardSaga';
import billsDownloadSaga from './handleApiCalls/billsDowloadSaga';
import storeSaga from './handleApiCalls/storeSaga';
import storeCreationSaga from './handleApiCalls/storeCreation';
import settingSaga from './handleApiCalls/settingSaga';
import updateSettingsSaga from './handleApiCalls/updateSettingSaga';
import apiKeysSaga from './handleApiCalls/apiKeysSaga';
import apiKeysGenerateSaga from './handleApiCalls/apiKeysGenerateSaga';
import apiKeysDeleteSaga from './handleApiCalls/apiKeysDeleteSaga';
import offersCreationSaga from './handleApiCalls/offersCreationSaga';
import offersSaga from './handleApiCalls/offersSaga';
import offersDeleteSaga from './handleApiCalls/offersDeleteSaga';
import billsInvoiceSaga from './handleApiCalls/billsInvoice';
import chatSaga from './handleApiCalls/chatSaga';
import chatCreateSaga from './handleApiCalls/chatCreateSaga';
import imageUploaderSaga from './handleApiCalls/imageUploaderSaga';
import getGallerySagas from './handleApiCalls/getGalleryImages';
import getReviewSagas from './handleApiCalls/getReview';

export default function* root():any {
    yield yield all([
        loginSaga(),
        adminLoginSaga(),
        registerSaga(),
        verifyCodeSaga(),
        createProfileSaga(),
        billListTableSaga(),
        billsInvoiceSaga(),
        customerListSaga(),
        admiUserSaga(),
        customerOverAllDetailSaga(),
        admiUserCretionSaga(),
        roleSaga(),
        getRoleSaga(),
        createRoleSaga(),
        deleteRoleSaga(),
        changePasswordWithEmailSaga(),
        changePasswordWithOtpSaga(),
        updateAdminUsersSaga(),
        customerBillsSaga(),
        billsDashboardSaga(),
        billsDownloadSaga(),
        storeSaga(),
        storeCreationSaga(),
        settingSaga(),
        updateSettingsSaga(),
        chatSaga(),
        chatCreateSaga(),
        apiKeysSaga(),
        apiKeysDeleteSaga(),
        apiKeysGenerateSaga(),
        offersSaga(),
        offersCreationSaga(),
        offersDeleteSaga(),
        imageUploaderSaga(),
        getGallerySagas(),
        getReviewSagas()
    ] )
}