import React,{ FC } from 'react'
import {useState,useRef} from 'react'
import {  useSelector,useDispatch } from 'react-redux';
import { useNavigate,useLocation } from 'react-router-dom';
import moment from "moment";
import {Modal} from 'react-bootstrap'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {KTIcon} from '../../../../_metronic/helpers'



type Props = {
  show: boolean
  data:any
  handleClose: () => void
}

export const AdminUserPopUp = ({show, handleClose,data}: Props) => {

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
return(
    <>
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-500px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      backdrop={true}
    >
 <div className='modal-header'>
        <h2>Admin User Detail</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-20 '>
        <div className='fv-row mb-7'>
         
          <div className=' py-1'>
          <label className=' fw-bold fs-6'>User Name </label>
          <label className='span fs-6'style={{color: "#777777",paddingLeft: "80px"}}>{data.username}</label>
          </div>
          <div className='g-20 py-1'>
          <label className=' fw-bold fs-6'>First Name </label>
          <label className='span fs-6'style={{color: "#777777",paddingLeft: "80px"}}>{data.firstname}</label>
          </div>

          <div className='g-20 py-1'>
          <label className=' fw-bold fs-6'>Last Name </label>
          <label className='span fs-6'style={{color: "#777777",paddingLeft: "80px"}}>{data.lastname}</label>
          </div>

          <div className=' py-1'>
          <label className=' fw-bold fs-6'>Email </label>
          <label className='span fs-6'style={{color: "#777777",paddingLeft: "112px"}}>{data.email}</label>
          </div>
          <div className=' py-1'>
          <label className=' fw-bold fs-6'>Phone </label>
          <label className='span fs-6'style={{color: "#777777",paddingLeft: "105px"}}>{data.phone}</label>
          </div>
          <div className=' py-1'>
          <label className=' fw-bold fs-6'>Address1 </label>
          <label className='span fs-6'style={{color: "#777777",paddingLeft: "87px"}}>{data.address1}</label>
          </div>
          <div className=' py-1'>
          <label className=' fw-bold fs-6'>Address2 </label>
          <label className='span fs-6'style={{color: "#777777",paddingLeft: "88px"}}>{data.address2}</label>
          </div>
          <div className=' py-1'>
          <label className=' fw-bold fs-6'>City </label>
          <label className='span fs-6'style={{color: "#777777",paddingLeft: "125px"}}>{data.city}</label>
          </div>
          <div className=' py-1'>
          <label className=' fw-bold fs-6'>State </label>
          <label className='span fs-6'style={{color: "#777777",paddingLeft: "115px"}}>{data.state}</label>
          </div>
          <div className=' py-1'>
          <label className=' fw-bold fs-6'>Country </label>
          <label className='span fs-6'style={{color: "#777777",paddingLeft: "97px"}}>{data.country}</label>
          </div>
          <div className=' py-1'>
          <label className=' fw-bold fs-6'>Locality </label>
          <label className='span fs-6'style={{color: "#777777",paddingLeft: "100px"}}>{data.locality}</label>
          </div>
          <div className=' py-1'>
          <label className=' fw-bold fs-6'>CreatedAt </label>
          <label className='span fs-6'style={{color: "#777777",paddingLeft: "85px"}}>{moment(data.createdAt).format('MMM DD YYYY h:mm A')}</label>
          </div>
          {/* <div className=' py-1'>
          <label className=' fw-bold fs-6'>status </label>
          <label className='span fs-6'style={{color: "#777777",paddingLeft: "115px"}}>{data.status}</label>
          </div> */}
          <div className=' py-1'>
          <label className=' fw-bold fs-6'>Roles Name </label>
          {data&&data.roles?.map((val:any) => {

                  return ( 
                    <label className='span fs-6'style={{color: "#777777",paddingLeft: "72px"}}>{val.name}</label>
                  )
                  })}
       
          </div>
          <div className=' py-1'>
          <label className=' fw-bold fs-6'>Store Name </label>
          <label className='span fs-6'style={{color: "#777777",paddingLeft: "75px"}}>{data.stores.name}</label>
          </div>

          {/* <div className=' py-lg-1'>
          <label className=' fw-bold fs-6'>Store :</label>
          </div>
          <pre className='span fs-6' style={{color: "#777777",paddingLeft: "70px"}}>
                    {JSON.stringify(data.stores, null, 2) }
                    </pre> */}
        </div>
      </div>
    </Modal>
    </>
)
}
