import {put, takeEvery, call,select} from 'redux-saga/effects';
import {redTypes} from '../../constants/reduxTypes';
import { IAdminUser } from '../../types/commonTypes';
import {  adminUserRequestApi } from '../../services/commonApiCall';


function* admiUser(param:object) {
  // console.log('adminUserrSaga',param);

try {
   
 const response:IAdminUser =yield adminUserRequestApi(param)
// console.log('responseVerify',typeof(response));



if (typeof(response && !response.message)=== 'object') {
  // console.log('billnumber',response);
  yield put({type: redTypes.ADMIN_USER_GET_SUCCESS, payload: response});

}
if (response && !response.message) {

  // console.log('billnumber',response);
  yield put({type: redTypes.ADMIN_USER_SUCCESS, payload: response});

}
else if(response.message){
  // console.log('adminError',response);
  yield put({
    type: redTypes.ADMIN_USER_FALIURE,
    errorMessage:response.message,
    errorCode:response.errorCode
    });
}
else{
  // console.log('adminError',response);
  yield put({
    type: redTypes.ADMIN_USER_FALIURE,
    errorMessage:'Something went wrong',

    });
}
}  catch (e) {
yield put({
type: redTypes.ADMIN_USER_FALIURE,
errorMessage: 'Something went wrong',
});
}

}
export default function* admiUserSaga():any {
     return yield takeEvery (redTypes.ADMIN_USER_REQUEST, admiUser);
  }