import React,{FC,useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { useDispatch, useSelector } from 'react-redux';
import { redTypes } from '../../../constants/reduxTypes';

const Success = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminCreationSuccess: any = useSelector<any>(state => state.billListReducer?.adminCreationSuccess);

  const handleClick = () => {
      // if(adminCreationSuccess){
        navigate('/adminUsers')  
       // dispatch({type:redTypes.RESET_ADMIN_USER,})
      // }
  };
  return (
    <>
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center mt-18 py-20'>
        <div className='d-flex flex-column  text-center p-10 '>
          <div className='card card-flush  w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>
      <h1 className='fw-bolder'style={{color:'green'}}>Successfully Added New Admin!</h1>

      <div className='mb-3'>
      <img
                    src={require("../../../images/success.png")}
                    className="mw-50 mh-150px theme-light-show"
                    alt=""
                  />
      </div>

      <div className='mb-0'>
        <button  onClick={()=>handleClick()} className='btn btn-bg-primary'style={{color:'white'}}>View Admin List</button>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
    </>
  )
}

export {Success}
