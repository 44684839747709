import React, { FC, useState } from "react";
import { Container, Row, Col,Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers"; 
import { BsSearch } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { redTypes } from '../../../modules/constants/reduxTypes';
import axios, { AxiosResponse } from "axios";
import { api } from "../../../modules/constants/api";
import { ChatView } from "../../../modules/components/pages/store/chatView";

export const ChatViewForAll:FC=()=> {
  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess)
    const storeData: any = useSelector<any>(
      (state) => state.billListReducer?.storeSuccess
    );
  const[customerData,setCustomerData]= useState<any>([])
  const [storeId,setStoreId]=useState(1)
  const [custmerId,setCustomerId]=useState()
  const[indexvalue,setIndex]=useState(0)
  console.log('storeId',storeId)
  console.log('custmerId',custmerId)
  const dispatch = useDispatch();
  React.useEffect(() => {
    console.log('useeffect called in chat')
    dispatch({
      type: redTypes.STORE_REQUEST,
      payload:{domain:loginSuccess.domain,chat:true},
    });
  }, []);
  React.useEffect(() => {
    console.log('useeffect call for chat')
    const fetchData = async () => {
      const store_id =storeId?storeId:1
      if (!storeData.getStores) {
        console.log('useEffect called in chat');
       // setStoreId(store_id)
        const getUploadUrl = `${api.baseUrl}${api.getUserStore}` + '?' + '&store_id=' + store_id;
        console.log('getUploadUrl', getUploadUrl);
        
        try {
          const response = await axios.get(getUploadUrl);
          setCustomerData(response.data);
        } catch (err) {
          console.error('Error fetching data:', err);
        }
      }
    };

    fetchData();
  }, [storeData,storeId]);
  
  console.log('storeData',storeData)
  console.log('customerData in new view',customerData)

  const storeChangeView =(data:any,index:any)=>{
     setStoreId(data.id)
     setIndex(index)

  }
  const customerChangeView = (data:any)=>{
     setCustomerId(data.id)

  }

  return (
    <>
    
    <Container fluid className="py-5" >
      <Row>
        <Col md="12">
          <Card id="chat3" style={{ borderRadius: "15px" }}>
            <Card.Body>
              <Row >
              <Col xs ='1' style={{paddingTop:'9px'}} >
                <div style={{display:'flex',flexDirection:'column',gap:'10px',width:'fit-content', height: "450px",overflow :'auto'}}>
                {storeData.getStores?storeData.getStores.map((data:any,index:any)=>{
                  console.log('url',data.url)
                  
                  return(

                    <div onClick={()=>{storeChangeView(data,index)}} style={{ border: indexvalue == index?'2px solid blue':"white", width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            
                    <img
                              src={data.url?data.url:'https://bill2u-image-bucket.s3.ap-southeast-1.amazonaws.com/1692959199656person.png'}
                              alt="avatar 1"
                              style={{ width: "55px" ,height:'55px',borderRadius:'10px'}}
                            />
                        
                 
                 </div>
                  )
                 
                }):storeData.map((data:any,index:any)=>{
                  console.log('url',data.url)
                  
                  return(

                    <div onClick={()=>{storeChangeView(data,index)}} style={{ border: indexvalue == index?'2px solid blue':"white", width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            
                    <img
                              src={data.url?data.url:'https://bill2u-image-bucket.s3.ap-southeast-1.amazonaws.com/1692959199656person.png'}
                              alt="avatar 1"
                              style={{ width: "55px" ,height:'55px',borderRadius:'10px'}}
                            />
                        
                 
                 </div>
                  )
                 
                })}
                
          
         
         </div>
              </Col>
                <Col xs ='4' className="mb-4 mb-md-0">
                  <div className="p-3">
                  <div className="input-group-append mb-3">
  <input
    className="form-control rounded"
    placeholder="Search"
    type="search"
  />
  <div className="input-group-append">
    <span className="input-group-text">
      {/* Your icon goes here */}
      {/* For example, if you want to use a search icon */}
      <i className="fa fa-search"></i>
    </span>
  </div>
</div>

                    <div
                    
                      style={{ position: "relative", height: "400px",overflow :'auto'}}
                    >
                      {customerData.length>0?customerData.map((data:any)=>{
                        return(
                          <div  onClick={()=>{customerChangeView(data)}} className="mb-0">
                        
                          <a
                            href="#!"
                            className="d-flex justify-content-between"
                          >
                            <div className="d-flex flex-row">
                              <div>
                                <img style={{ height: '50px', width: '50px', borderRadius: '50%', objectFit: 'cover' }}
                                  src={data.users.image?data.users.image:'https://bill2u-image-bucket.s3.ap-southeast-1.amazonaws.com/1692959199656person.png'}
                                  alt="avatar"
                                  className="d-flex align-self-center me-3"
                                  //width="60"
                                />
                                <span className="badge bg-success badge-dot"></span>
                              </div>
                              <div className="pt-1">
                                <p className="fw-bold mb-0">{data.users?data.users.firstname:''}</p>
                                <p className="small text-muted">
                                  Hello, Are you there?
                                </p>
                              </div>
                            </div>
                            <div className="pt-1">
                              <p className="small text-muted mb-1">Just now</p>
                              <span className="badge bg-danger rounded-pill float-end">
                                3
                              </span>
                            </div>
                          </a>
                        
                        
                      </div>
                        )
                      }):<div> There is no customer for this store.</div>}
                    
                    </div>
                  </div>
                </Col>
                <Col xs="6" >
                <div
                   
                   style={{ position: "relative", height: "400px",overflow:'auto' }}
                   className="pt-3 pe-3"
                 >
                  {custmerId?<ChatView storeId={storeId} userId={custmerId} />:<div>please select any customer</div>}
                  
                
                    {/* <a className="ms-1 text-muted" href="#!">
                      <MDBIcon fas icon="paperclip" />
                    </a>
                    <a className="ms-3 text-muted" href="#!">
                      <MDBIcon fas icon="smile" />
                    </a>
                    <a className="ms-3" href="#!">
                      <MDBIcon fas icon="paper-plane" />
                    </a> */}
                  </div>

               {custmerId&&  
                  <div className="text-muted d-flex justify-content-start align-items-center pe-3 pt-3 mt-2">
                    <img
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
                      alt="avatar 3"
                      style={{ width: "40px", height: "100%" }}
                    />
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="exampleFormControlInput2"
                      placeholder="Type message"
                    />
                    {/* <a className="ms-1 text-muted" href="#!">
                      <MDBIcon fas icon="paperclip" />
                    </a>
                    <a className="ms-3 text-muted" href="#!">
                      <MDBIcon fas icon="smile" />
                    </a>
                    <a className="ms-3" href="#!">
                      <MDBIcon fas icon="paper-plane" />
                    </a> */}
                  </div>}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  );
}