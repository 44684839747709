import {createStore, applyMiddleware} from 'redux';
import rootReducer from '../reducers/index';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';


// const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
    key: 'root',
    storage,
    // whitelist: [], // add any reducers to persist
    // blacklist: [], // add any reducers to not persist
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const sagaMiddleware = createSagaMiddleware();
const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));

// const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export {store, persistor};