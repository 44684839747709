import { IApiKeys } from "../../types/commonTypes";
import { put, takeEvery, call, select } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";
import { apiKeysDeleteApi } from "../../services/commonApiCall";

function* apiKeysDelete(param: object) {
  console.log("apiKeysDelete", param);
  try {
    const response: IApiKeys = yield apiKeysDeleteApi(param);
    if (response) {
      console.log(" delete response", response);
      yield put({ type: redTypes.DELETE_API_KEYS_SUCCESS, payload: response });
    }
  } catch (e) {
    console.log("error", e);
  }
}
export default function* apiKeysDeleteSaga(): any {
  return yield takeEvery(redTypes.DELETE_API_KEYS, apiKeysDelete);
}
