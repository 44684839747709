import React, { FC } from "react";
import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { KTIcon } from "../../../../_metronic/helpers";
import { Tab, Tabs } from "react-bootstrap";
import { CustomerHeader } from "./customerHeader";
import { redTypes } from "../../../constants/reduxTypes";
import { ErrorBoundary } from "./ErrorBoundary";

type Props = {
  // show: boolean
  // data:any
  // handleClose: () => void
};

export const CustomerPopUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  

  const customerData: any = useSelector<any>(
    (state) => state.billListReducer?.customerState
  );
  console.log("customerData", customerData);

  const handleClick = () => {
    navigate("/customers");
    dispatch({ type: redTypes.RESET_SET_STATE });
  };
  return (
    <>
      <CustomerHeader />
      {/* <div className="app-content  flex-column-fluid">
        <div className="container-xxl app-content  p-3 mb-5 bg-white rounded p-5 "> */}
      {!customerData ? (
        <ErrorBoundary />
      ) : (
        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
          <div className="card-header cursor-pointer">
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Customer Details</h3>
            </div>
            <div className="card-toolbar">
              <button
                type="button"
                className="btn btn-bg-primary"
                style={{ color: "white" }}
                onClick={handleClick}
              >
                back
              </button>
            </div>
          </div>
          <div className="card-body p-20">
            <div className="row mb-7 px-3">
              <label className="col-lg-3 fw-bold" style={{ color: "#A1A5B7" }}>
                First Name
              </label>

              <div className="col-lg-6">
                <span className="fw-bolder fs-6">
                  {customerData.user.firstname}
                </span>
              </div>
            </div>
            <div className="row mb-7 px-3">
              <label className="col-lg-3 fw-bold" style={{ color: "#A1A5B7" }}>
                Last Name
              </label>

              <div className="col-lg-6">
                <span className="fw-bolder fs-6 ">
                  {customerData.user.lastname}
                </span>
              </div>
            </div>

            <div className="row mb-7 px-3">
              <label className="col-lg-3 fw-bold " style={{ color: "#A1A5B7" }}>
                Email
              </label>

              <div className="col-lg-6 fv-row">
                <span className="fw-bold fs-6">{customerData.user.email}</span>
              </div>
            </div>
            <div className="row mb-7 px-3">
              <label className="col-lg-3 fw-bold" style={{ color: "#A1A5B7" }}>
                Phone
              </label>

              <div className="col-lg-6 fv-row">
                <span className="fw-bold fs-6">{customerData.user.phone}</span>
              </div>
            </div>
            <div className="row mb-7 px-3">
              <label className="col-lg-3 fw-bold" style={{ color: "#A1A5B7" }}>
                Address1
              </label>

              <div className="col-lg-6 fv-row">
                <span className="fw-bold fs-6">
                  {customerData.user.address1}
                </span>
              </div>
            </div>
            <div className="row mb-7 px-3">
              <label className="col-lg-3 fw-bold " style={{ color: "#A1A5B7" }}>
                Address2
              </label>

              <div className="col-lg-6 fv-row">
                <span className="fw-bold fs-6">
                  {customerData.user.address2}
                </span>
              </div>
            </div>
            <div className="row mb-7 px-3">
              <label className="col-lg-3 fw-bold" style={{ color: "#A1A5B7" }}>
                City
              </label>

              <div className="col-lg-6 fv-row">
                <span className="fw-bold fs-6">{customerData.user.city}</span>
              </div>
            </div>
            <div className="row mb-7 px-3">
              <label className="col-lg-3 fw-bold" style={{ color: "#A1A5B7" }}>
                State
              </label>

              <div className="col-lg-6 fv-row">
                <span className="fw-bold fs-6">{customerData.user.state}</span>
              </div>
            </div>
            <div className="row mb-7 px-3">
              <label className="col-lg-3 fw-bold" style={{ color: "#A1A5B7" }}>
                Contry
              </label>

              <div className="col-lg-6 fv-row">
                <span className="fw-bold fs-6">
                  {customerData.user.country}
                </span>
              </div>
            </div>
            <div className="row mb-7 px-3">
              <label className="col-lg-3 fw-bold" style={{ color: "#A1A5B7" }}>
                Locality
              </label>

              <div className="col-lg-6 fv-row">
                <span className="fw-bold fs-6">
                  {customerData.user.locality}
                </span>
              </div>
            </div>
            <div className="row mb-7 px-3">
              <label className="col-lg-3 fw-bold" style={{ color: "#A1A5B7" }}>
                CreatedAt
              </label>

              <div className="col-lg-6 fv-row">
                <span className="fw-bold fs-6">
                  {customerData.user.createdAt}
                </span>
              </div>
            </div>
          </div>
          {/* </Tab>
            <Tab eventKey="Bills" title="Bills View">
              <div className="fv-row mb-8">
                <label className="form-label fs-6 fw-bolder text-dark">
                  Email
                </label>
              </div>
            </Tab>
          </Tabs> */}
          {/* </div>
        </div> */}
        </div>
      )}
    </>
  );
};
