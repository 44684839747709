import { FC } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { redTypes } from "../../../constants/reduxTypes";

const ErrorBoundary: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const errorOverAllCustomer: any = useSelector<any>(
    (state) => state.billListReducer?.errorOverAllCustomer
  );
  const errorCodeOverAllCustomer: any = useSelector<any>(
    (state) => state.billListReducer?.errorCodeOverAllCustomer
  );
  const errorCustomerBills: any = useSelector<any>(
    (state) => state.billListReducer?.errorCustomerBills
  );
  console.log("errorcustomerBills", errorCustomerBills);

  const errorCodeCustomerBills: any = useSelector<any>(
    (state) => state.billListReducer?.errorCodeCustomerBills
  );
  const handleClick = () => {
    dispatch({ type: redTypes.RESET_SET_STATE });
    navigate("/customers");
  };
  const handleReturnClick = () => {
    dispatch({ type: redTypes.RESET_SET_STATE });
    navigate("/dashboard");
  };
  return (
    <>
      {/* <div
        className="d-flex flex-column flex-root"
      >
        <div className="d-flex flex-column flex-center flex-column-fluid">
          <div className="d-flex flex-column flex-center text-center p-10"> */}
      <div className="d-flex  justify-content-center align-item-center text-center p-10">
        <div className="card card-flush  w-lg-650px py-5">
          <div className="card-body py-15 py-lg-20">
            {/* begin::Title */}
            <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Error!</h1>
            {/* end::Title */}

            {errorOverAllCustomer ? (
              <div className="fw-semibold fs-4 text-gray-500 mb-5">
                ErrorCode - {errorCodeOverAllCustomer} : {errorOverAllCustomer}
              </div>
            ) : errorCustomerBills ? (
              <div className="fw-semibold fs-4 text-gray-500 mb-5">
                ErrorCode - {errorCodeCustomerBills} : {errorCustomerBills}
              </div>
            ) : (
              <div className="fw-semibold fs-4 text-gray-500 mb-5">
                Data not found
              </div>
            )}
            <div className="mb-3">
              <img
                src={require("../../../images/error.png")}
                className="mw-50 mh-150px theme-light-show"
                alt=""
              />
            </div>
            <div className="mb-0">
              {errorOverAllCustomer ? (
                <button
                  type="button"
                  className="btn btn-bg-success"
                  style={{ color: "white" }}
                  onClick={handleReturnClick}
                >
                  Return Home
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-bg-success"
                  style={{ color: "white" }}
                  onClick={handleClick}
                >
                  Return Home
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ErrorBoundary };
