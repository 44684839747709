import {put, takeEvery, call,select} from 'redux-saga/effects';
import {redTypes} from '../../constants/reduxTypes';
import { createProfileRequestApi } from '../../services/apiCalls';
import { ICreateProfileSuccessResponse } from '../../types';


function* createProfile(param:any) {

try {
   
 const response:ICreateProfileSuccessResponse =yield createProfileRequestApi(param)

if (response?.status === true) {
   localStorage.setItem("currentUserId",JSON.stringify(response.id));
   localStorage.setItem("currentUser", JSON.stringify(response.email));
   localStorage.setItem("currentToken", JSON.stringify(response.token));
   localStorage.setItem("refreshToken", JSON.stringify(response.refreshToken));
  
yield put({type: redTypes.MERCHANTS_CREATE_PROFILE_SUCCESS, payload: response});

}else if (response.message) {
   yield put({
     type: redTypes.MERCHANTS_CREATE_PROFILE_FAILURE,
     errorMessage: response.message,
   });
 }
 else {
   yield put({
     type: redTypes.MERCHANTS_CREATE_PROFILE_FAILURE,
     errorMessage: "Something went wrong",
   });
 }
 } catch (e) {
 yield put({
   type: redTypes.MERCHANTS_CREATE_PROFILE_FAILURE,
   errorMessage: "Something went wrong",
 });
 }
}
export default function* createProfileSaga():any {
     return yield takeEvery (redTypes.MERCHANTS_CREATE_REQUEST, createProfile);
  }