/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { redTypes } from '../../../constants/reduxTypes';
import moment from 'moment';
import { Setting, ISettings } from '../../../types/commonTypes';
import { ErrorBoundarySetting } from './ErrorBoundary';
import { settingUpdation } from '../../../actions/apiRequests';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'

const registrationSchema = Yup.object().shape({
  setting_value: Yup.string().required('Setting Value is required'),

})


const Settings: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = React.useState<Setting>();
  const [input, setInput] = React.useState<Array<any>>([{
    id: 0,
    store_id: 0,
    setting_name: '',
    setting_value: '',
    setting_helper_text: '',
  }
  ]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);


  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const settingData: any = useSelector<any>(
    (state) => state.billListReducer?.settingSuccess
  );
  const settingUpdationSuccess: any = useSelector<any>(
    (state) => state.billListReducer?.settingUpdationSuccess
  );

  const settingStatus: any = useSelector<any>(
    (state) => state.billListReducer?.settingStatus
  );
  console.log("settingStatus", settingStatus);

  console.log('settingData', typeof (settingData))
  const errorSetting: any = useSelector<any>(
    (state) => state.billListReducer?.errorSetting);
  console.log("errorSetting", errorSetting);

  React.useEffect(() => {
    setInput(settingData);
  }, [settingData]);

  React.useEffect(() => {
    dispatch({
      type: redTypes.GET_SETTING_REQUEST,
      payload: { domain: loginSuccess.domain },
    });

  }, [settingUpdationSuccess]);

  React.useEffect(() => {
    if (settingStatus) {
      setShowSuccessMessage(true);
    }
  }, [settingStatus]);

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
    dispatch({ type: redTypes.CLEAR_SETTING_UPDATION });
  };


  const handleClose = () => {
    navigate('/dashboard');
    dispatch({ type: redTypes.RESET_SET_STATE });
    dispatch({ type: redTypes.CLEAR_SETTING_UPDATION });
  };

  const labelName = (str: string) => {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  const handleChanges = (index: number, evnt: any) => {
    console.log("handle change index and evnt :", index, evnt.target.value);
    let rowValue = evnt.target.value;
    let rowData = [...input]
    console.log("handle change rowData :", rowData);
    rowData[index].setting_value = rowValue;

    setInput(rowData);
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form submission behavior
    handleSubmit();
  };

  const { handleSubmit, values, errors, touched, handleBlur } = useFormik({
    initialValues: {
      settings: input
    },
    // validationSchema: registrationSchema,
    onSubmit: () => {
      input.forEach(
        ({ id, store_id, setting_name, setting_value, setting_helper_text }, idx, arr) => arr[idx] = ({ id, store_id, setting_name, setting_value, setting_helper_text })
      )

      console.log('onSubmit values:', input);
      const updateData = {
        settings: input,
        subdomains: loginSuccess.domain
      }
      dispatch(settingUpdation(updateData))
    },
  })


  return (
    <>
      {errorSetting ? (
        <ErrorBoundarySetting />
      ) : (
        <>
          <div className="d-flex px-1">
            <div className=" align-items-start d-flex flex-direction-row px-2 mb-2" style={{ gap: 15, color: '#009ef7', fontSize: '24px' }}>
              <KTIcon iconName="setting" className="fs-1 mt-2" />
              <span className='card-label fw-bold'>Settings</span>
            </div>
          </div>

          <div className='card mb-5 mb-xl-10'>

            <div className='card-body py-2'>
              {showSuccessMessage && (
                <div className="alert alert-success text-white fs4 alert-dismissible" role="alert" style={{ background: "#89e1b163", color: "white" }}>
                <i className="ki-duotone ki-shield-tick text-white fs-1hx me-3"><span className="path1"  style={{ fill: "white" }}></span><span className="path2"  style={{ fill: "white" }}></span></i>
                <span style={{ color: "#146c43" }}>Settings updated successfully!</span>
                <button type="button" className="btn-close mx-3" onClick={handleCloseSuccessMessage}></button>

              </div>
              )}

              <form onSubmit={handleFormSubmit}>
                {settingData.length === 0 ? (
                  <div
                    className=" fw-bold fs-3"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 105,
                    }}
                  >
                    Data not found for Settings!!
                  </div>
                ) : (
                  <>
                    <div className='row mt-3'>
                      {input.length > 0 && input?.map(
                        (val: any, index) => {
                          const { id, setting_name, setting_value, setting_helper_text } = val;
                          return (
                            <div key={index} className='col-lg-6 mb-3 fv-row'>
                              <label className=' fs-6 fw-semibold mb-2'>{labelName(setting_name)}<span data-bs-toggle="tooltip" title={setting_helper_text}> <i className="fa-solid fa-circle-info fa-2xs" style={{ color: "#a1a5b7" }}></i></span></label>
                              <input type="text" name='setting_value'
                                className={clsx('form-control form-control-solid',
                                  //   { 'is-invalid': touched.val.setting_value && errors.setting_value },
                                  //   { 'is-valid': touched.setting_value && !errors.setting_value,   }
                                )}
                                value={setting_value}
                                onChange={(evnt) => (handleChanges(index, evnt))}
                                onBlur={handleBlur}
                              />
                            </div>
                          )
                        }
                      )}
                    </div>
                  </>
                )}
                <div className="card-footer border-0 d-flex justify-content-center flex-shrink-0">
                  <button type='submit' disabled={settingData.length === 0} onClick={() => handleSubmit()} className='mt-2 btn btn-bg-primary' style={{ color: 'white' }}>Update</button>
                  <button className='mt-2 btn ' onClick={handleClose} style={{ border: '1px solid #000', marginLeft: '5px' }}>Back</button>
                </div>
              </form>
            </div>

          </div>
        </>
      )
      }
    </>
  )
}

export { Settings }
