import { put, takeEvery, call, select } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";
import { IBillListSuccessResponse } from "../../types/commonTypes";
import { billListRequestApi } from "../../services/commonApiCall";

function* billsInvoice(param: object) {
  //  console.log('domain',param);

  try {
    const response: IBillListSuccessResponse = yield billListRequestApi(param);
    //  console.log('bill',response.message);

    if (response && !response.message) {
      // console.log("billnumber", response);
      yield put({ type: redTypes.BILLS_INVIOCE_SUCCESS, payload: response });
    } else if (response.message) {

      yield put({
        type: redTypes.BILLS_INVIOCE_FAILURE,
        errorMessage: response.message,
        errorCode: response.errorCode,
      });
    } else {
      console.log("Something Error");
      yield put({
        type: redTypes.BILLS_INVIOCE_FAILURE,
        errorMessage: "Something went wrong",
      });
    }
  } catch (e) {
    yield put({
      type: redTypes.BILLS_INVIOCE_FAILURE,
      errorMessage: "Something went wrong",
    });
  }
}
export default function* billsInvoiceSaga(): any {
  return yield takeEvery(redTypes.BILLS_INVIOCE_REQUEST, billsInvoice);
}
