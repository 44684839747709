import { FC } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { redTypes } from "../../../constants/reduxTypes";

const ErrorBoundary: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorSetting: any = useSelector<any>((state) => state.billListReducer?.errorChat);
  // console.log("errorStore", errorStore);
  const errorCodeSetting: any = useSelector<any>(
    (state) => state.billListReducer?.errorCodeChat
  );

  return (
    <>
      <div className="d-flex  justify-content-center align-item-center text-center p-10">
        <div className="card card-flush  w-lg-650px py-5">
          <div className="card-body py-15 py-lg-20">
            
            {/* <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Error!</h1> */}
            
            {errorSetting ? (
              <div className="fw-semibold fs-4 text-gray-500 mb-5">
              ErrorCode - {errorCodeSetting} : {errorSetting}
            </div>):(
            <div className="fw-semibold fs-4 text-gray-500 mb-5">
              Choose the Store to initiate the Chat !!
            </div>)}
            <div className="mb-3">
              <img
                src={require("../../../../_metronic/images/error.png")}
                className="mw-50 mh-150px theme-light-show"
                alt=""
              />
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export { ErrorBoundary };
