import { put, takeEvery, call, select } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";
import { IReport } from "../../types/commonTypes";
import { billsDownloadApi } from "../../services/commonApiCall";

function* billsDownload(param: object) {
   console.log('domain',param);

  try {
    const response: IReport = yield billsDownloadApi(param);

    if (response) {
      // console.log('response',response);
      
      yield put({ type: redTypes.BILLS_DOWNLOAD_SUCCESS, payload: response });
    }

  } catch (e) {
    // yield put({
    //   type: redTypes.BILL_LIST_TABLE_FAILURE,
    //   errorMessage: "Something went wrong",
    // });
  }
}
export default function* billsDownloadSaga(): any {
  return yield takeEvery(redTypes.BILLS_DOWNLOAD_REQUEST, billsDownload);
}
