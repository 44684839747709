import { put, takeEvery } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";
import { IChat } from "../../types/commonTypes";
import { chatRequestApi } from "../../services/commonApiCall";

function* chat(param: object) {
console.log("chatSaga param:", param);

try{
    const response:IChat =yield chatRequestApi(param)
      console.log('chat',response.message);

     if (response && !response.message) {
        console.log("chatRes :", response);
        yield put({ type: redTypes.CHAT_SUCCESS, payload: response });
      }
      else if (response.message){
        
        yield put({
          type: redTypes.CHAT_FAILURE,
          errorMessage:response.message,
          errorCode: response.errorCode,
          });
      }
      else{
        console.log('Something Error');
        yield put({
          type: redTypes.CHAT_FAILURE,
          errorMessage: 'Something went wrong',
          });
      }
}catch(e){
  yield put({
    type: redTypes.CHAT_FAILURE,
    errorMessage: 'Something went wrong',
    });
}
}


export default function* chatSaga(): any {
    return yield takeEvery(redTypes.CHAT_REQUEST, chat);
  }