import { put, takeEvery, call, select } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";
import { IStores } from "../../types/commonTypes";
import { imageUploaderApi } from "../../services/commonApiCall";

function* imageUploader(param: object) {
   // console.log('imageUploader',param);

  try {
    const response: IStores = yield imageUploaderApi(param);

    if (!response?.multipleUploads && !response.message) {
     // console.log("imageMsg", response);
      yield put({ type: redTypes.IMAGE_UPLOADER_SUCCESS, payload: response });
    }
    else if(response?.multipleUploads){
      console.log('multipleUploadSuccessResponse',response)
      yield put({ type: redTypes.MULTI_IMAGE_UPLOADER_SUCCESS, payload: response.response });

    }
    
    else if (response.message) {
      yield put({
        type: redTypes.IMAGE_UPLOADER_FAILURE,
        errorMessage: response.message,
        errorCode: response.errorCode,
      });
    } else {
      console.log("Something Error");
      yield put({
        type: redTypes.IMAGE_UPLOADER_FAILURE,
        errorMessage: "Something went wrong",
      });
    }
  } catch (e) {
    yield put({
      type: redTypes.IMAGE_UPLOADER_FAILURE,
      errorMessage: "Something went wrong",
    });
  }
}
export default function* imageUploaderSaga(): any {
  return yield takeEvery(redTypes.IMAGE_UPLOADER_REQUEST, imageUploader);
}
