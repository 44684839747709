/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { KTIcon } from "../../../../helpers";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useSelector } from "react-redux";

const SidebarMenuMain = () => {
  const intl = useIntl();
  const rolesSuccess: any = useSelector<any>(
    (state) => state.authReducer?.roles
  );
  // console.log('rolesDash',rolesSuccess);
  let rolesArray = [];
  if (rolesSuccess) {
    rolesArray = rolesSuccess.split(",");
  }
  // console.log("roleArray", rolesArray);

  // const myFunction = (item:any,index:any) =>{
  //   console.log("item",item)
  //   console.log('index',index);

  // }
  // const rolesArray = ['dashboard','customers','bills']

  return (
    <>
      {rolesSuccess ? (
        <div>
          {rolesArray.includes("dashboard") ? (
            <SidebarMenuItem
              to="/dashboard"
              icon="element-11"
              title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
              fontIcon="bi-app-indicator"
            />
          ) : null}

          {rolesArray.includes("bills") ? (
            <SidebarMenuItem
              to="/bills"
              icon="document"
              title="Bills "
              fontIcon="bi-layers"
            />
          ) : null}
          {rolesArray.includes("customers") ? (
            <SidebarMenuItem
              to="/customers"
              icon="profile-user"
              fontIcon="bi-person"
              title="Customers"
            />
          ) : null}
        </div>
      ) : (
        <div>
          <SidebarMenuItem
            to="/dashboard"
            icon="element-11"
            title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
            fontIcon="bi-app-indicator"
          />

          {/* <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                Crafted
              </span>
            </div>
          </div> */}
          <SidebarMenuItem
            to="/bills"
            icon="document"
            title="Bills "
            fontIcon="bi-layers"
          />
          <SidebarMenuItem
            to="/customers"
            icon="profile-user"
            fontIcon="bi-person"
            title="Customers"
          />
          <SidebarMenuItem
            to="/adminUsers"
            icon="user-tick"
            title="Admin Users"
          />
          <SidebarMenuItem to="/roles" icon="shield-cross" title="Roles" />
          <SidebarMenuItem to="/stores" icon="shop" title="Stores" />
          <SidebarMenuItem to="/apiKeys" icon="key" title="Api Keys" />
          <SidebarMenuItem to="/offers" icon="abstract-26" title="Offers" />
          <SidebarMenuItem to="/chats" icon="notepad" title="Chats" />

        </div>
      )}
    </>
  );
};

export { SidebarMenuMain };
