/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
//import {getUserByToken, register} from '../core/_requests'
import {Link,useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { AuthUserModel } from '../../types'
import { signupRequest } from '../../actions/apiRequests'
import { redTypes } from '../../constants/reduxTypes'

import {
  SignupPayload,
} from "../../types";

const initialValues :AuthUserModel = {
  firstname: '',
 lastname: '',
   phone:'',
  email: '',
  password: '',
  domain:'',
  plan:'',
  country:{code:'IN',name:'india'}
 // changepassword: '',
  // acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
   phone: Yup.string()
    .min(10, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  // changepassword: Yup.string()
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Password confirmation is required')
  //   .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  //acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const verificationSend = useSelector<any>(state => state.authReducer?.verificationSend);
  const errorSignUp = useSelector<any>(state => state.authReducer?.errorSignUp);

  const navigate=useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
 // const {saveAuth, setCurrentUser} = useAuth()
  if(verificationSend===true){
    //  console.log('factor useEffect')
navigate('/verifyCode')
    //  dispatch({type:redTypes.ENROLL_FALSE})
      
  }
   
  const { handleSubmit, values, handleChange, errors, touched, handleBlur, status, setStatus } = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values) => {
      // console.log('on submit for register')
   
      var data :SignupPayload={
          email:values.email,
             phone:values.phone,
        lastname: values.lastname,
        firstname: values.firstname,
          password:  values.password,
          country:{code:'IN',name:'india'},
          domain:'prakash1825@adoltech.com',
          plan:'trial',
      }
       dispatch(signupRequest(data))
       //setStatus(error)
    },
  })


  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
    <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
    <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>

    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
        {/* end::Title */}

        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>

  <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>First name</label>
        <input
           placeholder='Enter your first name'
           name='firstname'
           type='text'
           className={clsx(
            'form-control bg-transparent',
            {'is-invalid': touched.firstname && errors.firstname},
            {
              'is-valid': touched.firstname && !errors.firstname,
            }
          )}
           value={values.firstname}
           onChange={handleChange}
           onBlur={handleBlur}
          // className= {errors.email && touched.email ? errors.email : null}
          autoComplete='off'
        />
        {touched.firstname && errors.firstname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.firstname}</span>
            </div>
          </div>
        )}
      </div>
      {/* begin::Form group Firstname */}
      
     
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        {/* begin::Form group Lastname */}
        <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
        <input
             placeholder='Enter your first name'
             name='lastname'
             type='text'
             className={clsx(
              'form-control bg-transparent',
              {'is-invalid': touched.lastname && errors.lastname},
              {
                'is-valid': touched.lastname && !errors.lastname,
              }
            )}
             value={values.lastname}
             onChange={handleChange}
             onBlur={handleBlur}
            // className= {errors.email && touched.email ? errors.email : null}
            autoComplete='off'
          />
          {touched.lastname && errors.lastname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
              <span role='alert '>{errors.lastname}</span>
              </div>
            </div>
          )}
       
        {/* end::Form group */}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Phone Number</label>
        <input
           placeholder='Enter your phone number'
           name='phone'
           type='text'
           className={clsx(
            'form-control bg-transparent',
            {'is-invalid': touched.phone && errors.phone},
            {
              'is-valid': touched.phone && !errors.phone,
            }
          )}
           value={values.phone}
           onChange={handleChange}
           onBlur={handleBlur}
          // className= {errors.email && touched.email ? errors.email : null}
          autoComplete='off'
        />
        {touched.phone && errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.phone}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
           placeholder='Enter your email'
           name='email'
           type='email'
           className={clsx(
            'form-control bg-transparent',
            {'is-invalid': touched.email && errors.email},
            {
              'is-valid': touched.email && !errors.email,
            }
          )}
           value={values.email}
           onChange={handleChange}
           onBlur={handleBlur}
          // className= {errors.email && touched.email ? errors.email : null}
          autoComplete='off'
        />
        {touched.email && errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
            <span role='alert '>{errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              autoComplete='off'
              placeholder='Password'
              name='password'
              type='password'
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': touched.password && errors.password},
                {
                  'is-valid': touched.password && !errors.password,
                }
              )}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.password && errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert '>{errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {status &&  (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{status}</div>
            </div>
          )}
          
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-bg-primary w-100 mb-5'
          style={{color:'white'}}
        //  disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Sign Up</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-bg-secondary w-100 mb-5'
            onClick={()=>navigate('/login')}
            style={{color:'white'}}
          >
            Cancel
          </button>
      </div>
      {/* end::Form group */}
    </form>

    </div>
    </div>
    </div>
  )
}
