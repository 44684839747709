import React, { FC } from "react";
import { Form, Button } from 'react-bootstrap';
import clsx from "clsx";
import { useFormik } from "formik";
import {  useSelector,useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { redTypes } from "../../../constants/reduxTypes";

export const Profile: FC = () => {
    const navigate = useNavigate();
  const dispatch = useDispatch();
   // const adminCreationSuccess: any = useSelector<any>(state => state.authReducer?.loginSuccess);
    const response: any = useSelector<any>(
        (state) => state.authReducer?.loginSuccess
      );
      const updateAdminUser: any = useSelector<any>(
        (state) => state.billListReducer?.updateAdminUser
      );
      const getAdminUser: any = useSelector<any>(
        (state) => state.billListReducer?.adminUserGetSuccess
      );
      console.log('update_admin_user',updateAdminUser)
      console.log('get_admin_user',getAdminUser)
      React.useEffect(() => {
        console.log('fetchApi')
        
       
          dispatch({ type: redTypes.ADMIN_USER_REQUEST, payload: { id: response.id,subdomains:response.domain,get_admin_user:true} });
     
      }, []);  

    const[inputs,setInputs]=React.useState(getAdminUser?getAdminUser:{id:'',email:'',firstname:'',lastname:'',phone:'',
    domain:'',address1:'',address2:'',country:'',state:''})
      React.useEffect(() => {
        console.log('render')
       // window.location.reload();
   
    }, [getAdminUser]);   

      React.useEffect(() => {
        if(updateAdminUser){
            navigate('/dashboard')
            dispatch({ type: redTypes.CLEAR_UPDATE_ADMIN_USERS, payload: values });
            dispatch({ type: redTypes.ADMIN_USER_REQUEST, payload: { id: response.id,subdomains:response.domain,get_admin_user:true} });
            //window.location.reload()
          //  setInputs(undefined)

        }
       
          //dispatch({ type: redTypes.ADMIN_USER_REQUEST, payload: { id: response.id} });
     
      }, [updateAdminUser]);   
      
//console.log('adminUserProfileResponse',adminCreationSuccess)
//console.log('responseForMerchant',response)
    

    const {
        handleSubmit,
        values,
        handleChange,
        errors,
        touched,
        handleBlur,
        status,
        setStatus,
      } = useFormik({
        initialValues:{
            id:inputs.id,
            email:inputs.email,
            firstname:inputs.firstname,
            lastname:inputs.lastname,
            phone:inputs.phone,
            subdomains:response.domain,
            address1:inputs.address1,
            address2:inputs.address2,
            country:inputs.country,
            state:inputs.state
        },
        enableReinitialize:true,
       // validationSchema: loginSchema,
        onSubmit: async (values) => {
          console.log("on submit",values);
          //setLoading(true);
          //values.id = response.id
          dispatch({ type: redTypes.UPDATE_ADMIN_USERS, payload: values });
          //  setStatus(error)
        },
      });
      console.log('values',values)


    return(

        <div className='card mb-50 mb-xl-10' style={{padding:'5px'}}>
           
        <div className='card-body pt-9 pb-0'>
             <div >
                <h1>Admin User Profile</h1>
             </div>
            
            <hr></hr>
 <div style={{display:'flex',flexDirection:'row',gap:"30px"
 
}}>
<div>
    <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Email
                    </label>
                    <input
                      placeholder="Enter your email"
                      name="email"
                      type="email"
                      className={clsx(
                        "form-control bg-transparent",
                        // { "is-invalid": touched.email && errors.email },
                        // {
                        //   "is-valid": touched.email && !errors.email,
                        // }
                      )}
                      value={values.email}
                      onChange={handleChange}
                    //   onBlur={handleBlur}
                      autoComplete="off"
                    />
                    
                   
                  </div>
                
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Phone Number
                    </label>
                    <input
                      placeholder="Enter your email"
                      name="phone"
                      type="phone"
                      className={clsx(
                        "form-control bg-transparent",
                        // { "is-invalid": touched.email && errors.email },
                        // {
                        //   "is-valid": touched.email && !errors.email,
                        // }
                      )}
                      value={values.phone}
                      onChange={handleChange}
                    //   onBlur={handleBlur}
                      autoComplete="off"
                    />
                    
                   
                  </div>
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      First Name
                    </label>
                    <input
                      placeholder="Enter your first name"
                      name="firstname"
                      type="firstname"
                      className={clsx(
                        "form-control bg-transparent",
                        // { "is-invalid": touched.email && errors.email },
                        // {
                        //   "is-valid": touched.email && !errors.email,
                        // }
                      )}
                      value={values.firstname}
                      onChange={handleChange}
                    //   onBlur={handleBlur}
                      autoComplete="off"
                    />
                    
                   
                  </div>
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Last Name
                    </label>
                    <input
                      placeholder="Enter your last name"
                      name="lastname"
                      type="lastname"
                      className={clsx(
                        "form-control bg-transparent",
                        // { "is-invalid": touched.email && errors.email },
                        // {
                        //   "is-valid": touched.email && !errors.email,
                        // }
                      )}
                      value={values.lastname}
                      onChange={handleChange}
                    //   onBlur={handleBlur}
                      autoComplete="off"
                    />
                    
                   
                  </div>
                
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Domain
                    </label>
                    <input
                      placeholder="Enter your last name"
                      name="subdomains"
                      type="subdomains"
                      className={clsx(
                        "form-control bg-transparent",
                        // { "is-invalid": touched.email && errors.email },
                        // {
                        //   "is-valid": touched.email && !errors.email,
                        // }
                      )}
                      value={values.subdomains}
                      onChange={handleChange}
                    //   onBlur={handleBlur}
                      autoComplete="off"
                    />
                    
                   
                  </div>
                  </div>
                  <div>
                  <div>
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Address1
                    </label>
                    <input
                      placeholder="Enter your last name"
                      name="address1"
                      type="address1"
                      className={clsx(
                        "form-control bg-transparent",
                        // { "is-invalid": touched.email && errors.email },
                        // {
                        //   "is-valid": touched.email && !errors.email,
                        // }
                      )}
                      value={values.address1}
                      onChange={handleChange}
                    //   onBlur={handleBlur}
                      autoComplete="off"
                    />
                    </div>
                       <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Address2
                    </label>
                    <input
                      placeholder="Enter your last name"
                      name="address2"
                      type="address2"
                      className={clsx(
                        "form-control bg-transparent",
                        // { "is-invalid": touched.email && errors.email },
                        // {
                        //   "is-valid": touched.email && !errors.email,
                        // }
                      )}
                      value={values.address2}
                      onChange={handleChange}
                    //   onBlur={handleBlur}
                      autoComplete="off"
                    />
                    
                   
                  </div>
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Country
                    </label>
                    <input
                      placeholder="Enter your last name"
                      name="country"
                      type="country"
                      className={clsx(
                        "form-control bg-transparent",
                        // { "is-invalid": touched.email && errors.email },
                        // {
                        //   "is-valid": touched.email && !errors.email,
                        // }
                      )}
                      value={values.country}
                      onChange={handleChange}
                    //   onBlur={handleBlur}
                      autoComplete="off"
                    />
                    
                   
                  </div>
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      State
                    </label>
                    <input
                      placeholder="Enter your last name"
                      name="state"
                      type="state"
                      className={clsx(
                        "form-control bg-transparent",
                        // { "is-invalid": touched.email && errors.email },
                        // {
                        //   "is-valid": touched.email && !errors.email,
                        // }
                      )}
                      value={values.state}
                      onChange={handleChange}
                    //   onBlur={handleBlur}
                      autoComplete="off"
                    />
                    
                   
                  </div>
                 

          </div>
                  </div>
                 
                  </div>
            </div>
            <div style={{display:'flex',flexDirection:'row' ,justifyContent:'flex-start',gap:'10px',marginLeft:'30px'}}>
                  <div className="d-grid mb-10 ">
                    <button
                      type="submit"
                      id="kt_merchant_sign_in_submit"
                      className="btn btn-bg-secondary"
                      style={{ color: "black" }}
                      onClick={()=>{navigate('/dashboard')}}
                      //disabled={formik.isSubmitting || !formik.isValid}
                    >
                     Cancel
                    </button>
                  </div>

                  <div className="d-grid mb-10">
                    <button
                      type="submit"
                      id="kt_merchant_sign_in_submit"
                      className="btn btn-bg-primary "
                      style={{ color: "black" }}
                      onClick={()=>{handleSubmit()}}
                      //disabled={formik.isSubmitting || !formik.isValid}
                    >
                     Update
                    </button>
                  </div>
                  </div>
                
          
  
          
        </div>
    
          );
          
        
    


};