import {ISettings} from '../../types/commonTypes'
import { settingUpdationRequestApi } from '../../services/commonApiCall'
import {put, takeEvery} from 'redux-saga/effects';
import {redTypes} from '../../constants/reduxTypes';

function* settingUpdateSaga(param:object){
    console.log('updateParam',param)
    try{

        const response:ISettings =yield settingUpdationRequestApi(param)
        console.log('response for settingUpdt:',response)
        if(response){
            console.log('S U response: ',response)
            yield put({type: redTypes.SETTING_UPDATION_SUCCESS, payload: response});
            // alert("Data Updated Successfully")

        }


    }
    catch(e){
        console.log('errorFor s u:',e)

    }


}
export default function* updateSettingsSaga():any {
    return yield takeEvery (redTypes.SETTING_UPDATION_REQUEST, settingUpdateSaga);
 }