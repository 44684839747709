import { put, takeEvery, call, select } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";
import { Ioffers } from "../../types/commonTypes";
import { offersRequestApi } from "../../services/commonApiCall";

function* offers(param: object) {
   console.log('domain',param);

  try {
    const response: Ioffers = yield offersRequestApi(param);
    //  console.log('bill',response.message);

    if (response && !response.message) {
      yield put({ type: redTypes.OFFERS_SUCCESS, payload: response });
    } else if (response.message) {
      console.log("Error", response.message);
      yield put({
        type: redTypes.OFFERS_FAILURE,
        errorMessage: response.message,
        errorCode: response.errorCode,
      });
    } else {
      console.log("Something Error");
      yield put({
        type: redTypes.OFFERS_FAILURE,
        errorMessage: "Something went wrong",
      });
    }
  } catch (e) {
    yield put({
      type: redTypes.OFFERS_FAILURE,
      errorMessage: "Something went wrong",
    });
  }
}
export default function* offersSaga(): any {
  return yield takeEvery(redTypes.OFFERS_REQUEST, offers);
}
