import React, { useState } from "react";
import { Dropdown ,Alert} from "react-bootstrap";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
// import { DrawerRight } from "../../drawer/DrawerRight";
import clsx from 'clsx'
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  Search,
  ThemeModeSwitcher,
  ChangePasswordPopup,
  SwitchSettingPage
} from "../../../partials";

const Navbar = () => {
  const naviage =useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMessage,seShowMessage]= useState (false)
  const [showModal, setShowModal] = useState<boolean>(false);
  const [password,setPassword] = useState <boolean>(false);
  const [setting,setSetting] = useState <boolean>(false)
  const [state,setState] = useState <boolean>(false)

  const rolesSuccess: any = useSelector<any>(
    (state) => state.authReducer?.roles
  );

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };
  const handleNotificationToggle = () => {
    setState(true);
  };
  const handleClose = () => {
    setShowModal(false);
    setPassword(false);
    setSetting(false);
    setState(false);
  };

  const logout = () => {
    setShowModal(true);
  };

  const changePassword =()=> {
    setPassword(true)

  };

  const changeSetting =()=> {
    setSetting(true)

  };

  const navigate =()=> {
   
    naviage('/Profile')
  }
  const handleChangeMessage= ()=>{
    seShowMessage(!showMessage)
  }

  const itemClass = 'ms-1 ms-lg-3'
  const btnClass =
    'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
    const btnIconClass = 'fs-1'
  return (
    <>
    {/* {showMessage?<DrawerRight show={showMessage} onchange={handleChangeMessage}/>:null} */}
    {password ? (
      <ChangePasswordPopup show={password} handleClose={handleClose} />
    ) : null}
    {showModal ? (
      <HeaderUserMenu show={showModal} handleClose={handleClose} />
    ) : null}
    {setting ? (
      <SwitchSettingPage show={setting} handleClose={handleClose} />
    ) : null}
    <div className="app-navbar flex-shrink-0 mt-3">
    <div className={clsx('app-navbar-item', itemClass)}>
        <div
          // data-kt-menu-trigger={setState(true)}
          //data-kt-menu-trigger="{default: 'click'}"
          onClick={handleNotificationToggle}
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          {/* <KTIcon iconName='bi bi-bell' className={btnIconClass} /> */}
          {/* <i className="bi bi-bell fs-1 fw-bolder"style={{color:'gray'}}></i> */}
          <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <i className="bi bi-bell fs-1 fw-bolder"style={{color:'gray'}}></i>
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
          </div>
        </div>
        <HeaderNotificationsMenu state={state} handleClose={handleClose} />
      </div>
  

    {/* <div  onClick={handleChangeMessage} className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}
      <Dropdown show={showDropdown} onToggle={handleDropdownToggle} className=" hide-on-print">
        <Dropdown.Toggle variant="secondary" id="dropdownMenuButton" style={{}}>
        <KTIcon iconName="profile-circle" className="fs-3" />
       </Dropdown.Toggle>
        <Dropdown.Menu>
       {rolesSuccess&&  <Dropdown.Item href="#"  onClick={navigate}>Profile</Dropdown.Item>}  
          <Dropdown.Item href="#" onClick={changePassword}>Change Password</Dropdown.Item>
          <Dropdown.Item href="#" onClick={changeSetting}>Settings</Dropdown.Item>
          <Dropdown.Item href="#" onClick={logout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
    </>
  );
};

export { Navbar };







