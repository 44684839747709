import {put, takeEvery, call,select} from 'redux-saga/effects';
import {redTypes} from '../../constants/reduxTypes';
import { ICustomerBills, ICustomerOverallDetail } from '../../types/commonTypes';
import {  customerBillsApi } from '../../services/commonApiCall';


function* customerBills(param:object) {
 // console.log('customerBills',param);

try {
   
 const response:ICustomerBills =yield customerBillsApi(param)
 //console.log('responsecustomerBills',response);

if (response && !response.message) {
yield put({type: redTypes.CUSTOMER_BILLS_DETAILS_SUCCESS, payload: response});

}
else if (response.message){
  // console.log('msg',response.message);
  yield put({
    type: redTypes.CUSTOMER_BILLS_DETAILS_FAILURE,
    errorMessage:response.message,
    errorCode: response.errorCode,
    });
}
else{
  console.log('Something Error');
  yield put({
    type: redTypes.CUSTOMER_BILLS_DETAILS_FAILURE,
    errorMessage: 'Something went wrong',
    });
}
}  catch (e) {
yield put({
type: redTypes.CUSTOMER_BILLS_DETAILS_FAILURE,
errorMessage: 'Something went wrong',
});
}

}
export default function* customerBillsSaga():any {
     return yield takeEvery (redTypes.CUSTOMER_BILLS_DETAILS_REQUEST, customerBills);
  }