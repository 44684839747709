// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTIcon} from '../../../helpers'
import { useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'


type Props = {
  className: string
  backGroundColor: string
}

const MixedWidget15: FC<Props> = ({className, backGroundColor}) => {
  const navigate = useNavigate();
    const billListData: any = useSelector<any>(state => state.billListReducer?.response);
    const storeData: any = useSelector<any>(
      (state) => state.billListReducer?.storeSuccess
    );
    const customerDetailData: any = useSelector<any>(
      (state) => state.billListReducer?.customerOverall
    );
    let totalAmount = 0;
    const datas = billListData.getBillAmount?.forEach(element => {
      // console.log('element',element);
      var amount = element.bill_amounts?.total_invoice_value
      if (amount) {
        totalAmount += parseFloat(amount);
      }
    });
    totalAmount = totalAmount.toFixed(2);
    return (
  <div
    className={`card ${className} theme-dark-bg-body`}
    style={{backgroundColor: backGroundColor}}
  >
    {/* begin::Body */}
    <div className='card-body d-flex flex-column'>
      {/* begin::Wrapper */}
      <div className='d-flex flex-column mb-7'>
        {/* begin::Title  */}
        <a href='#' className='text-dark text-hover-primary fw-bolder fs-3'>
          Summary
        </a>
        {/* end::Title */}
      </div>
      {/* end::Wrapper */}

      <div className='row g-0'>
        {/*begin::Col*/}
        <div className='col-6'>
          <div className='d-flex align-items-center mb-9 me-2'style={{cursor:'pointer'}} onClick={()=>{navigate('/bills')}}>
            {/*begin::Symbol*/}
            <div className='symbol symbol-40px me-3'>
              <div className='symbol-label bg-body bg-opacity-50'>
                <KTIcon iconName='abstract-42' className='fs-1 text-dark' />
              </div>
            </div>
            {/*end::Symbol*/}

            {/*begin::Title*/}
            <div>
            {!billListData ?<div className='fw-semi-bold fs-6'>No data found</div>:
              <div className='fs-5 text-dark fw-bolder lh-1'>{billListData?.getBillAmount?.length}</div>}
              <div className='fs-7 text-gray-600 fw-bold'>New Bills</div>
            </div>
            {/*end::Title*/}
          </div>
        </div>
        {/*end::Col*/}

        {/*begin::Col*/}
        <div className='col-6'>
          <div className='d-flex align-items-center mb-9 ms-2'>
            {/*begin::Symbol*/}
            <div className='symbol symbol-40px me-3'>
              <div className='symbol-label bg-body bg-opacity-50'>
                <KTIcon iconName='abstract-45' className='fs-1 text-dark' />
              </div>
            </div>
            {/*end::Symbol*/}

            {/*begin::Title*/}
            <div>
              <div className='fs-5 text-dark fw-bolder lh-1'>${totalAmount}</div>
              <div className='fs-7 text-gray-600 fw-bold'>Total Sales</div>
            </div>
            {/*end::Title*/}
          </div>
        </div>
        {/*end::Col*/}

        {/*begin::Col*/}
        <div className='col-6'>
          <div className='d-flex align-items-center me-2'style={{cursor:'pointer'}}onClick={()=>{navigate('/customers')}}>
            {/*begin::Symbol*/}
            <div className='symbol symbol-40px me-3'>
              <div className='symbol-label bg-body bg-opacity-50'>
                <KTIcon iconName='abstract-21' className='fs-1 text-dark' />
              </div>
            </div>
            {/*end::Symbol*/}

            {/*begin::Title*/}
            <div>
            {!customerDetailData ?<div className='fw-semi-bold fs-6'>No data found</div>:
              <div className='fs-5 text-dark fw-bolder lh-1'>{customerDetailData.count}</div>}
              <div className='fs-7 text-gray-600 fw-bold'>Customers</div>
            </div>
            {/*end::Title*/}
          </div>
        </div>
        {/*end::Col*/}

        {/*begin::Col*/}
        <div className='col-6'>
          <div className='d-flex align-items-center ms-2'style={{cursor:'pointer'}}onClick={()=>{navigate('/stores')}}>
            {/*begin::Symbol*/}
            <div className='symbol symbol-40px me-3'>
              <div className='symbol-label bg-body bg-opacity-50'>
                <KTIcon iconName='abstract-44' className='fs-1 text-dark' />
              </div>
            </div>
            {/*end::Symbol*/}

            {/*begin::Title*/}
            <div>
            {!storeData ?<div className='fw-semi-bold fs-6'>No data found</div>:
              <div className='fs-5 text-dark fw-bolder lh-1'>{storeData.count}</div>}
              <div className='fs-7 text-gray-600 fw-bold'>Stores</div>
            </div>
            {/*end::Title*/}
          </div>
        </div>
        {/*end::Col*/}
      </div>
    </div>
  </div>
    )
}
export {MixedWidget15}
