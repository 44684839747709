import { put, takeEvery } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";
import { IChat } from "../../types/commonTypes";
import { chatCreateApi } from "../../services/commonApiCall";

function* chatCreate(param: object) {
  console.log("chatCreateSaga param:", param);
try{
    const response:IChat =yield chatCreateApi(param)
      

     if (response) {
      console.log('chat',response);
        yield put({ type: redTypes.CHAT_CREATE_SUCCESS, payload: response });
      
      }
      
      else{
        console.log('Something Error');
        yield put({
          type: redTypes.CHAT_FAILURE,
          errorMessage: 'Something went wrong',
          });
      }

}catch{
  yield put({
    type: redTypes.CLEAR_CHAT_CREATION,
    errorMessage: "Something went wrong",
  });
}
}


export default function* chatCreateSaga(): any {
    return yield takeEvery(redTypes.CHAT_CREATE_REQUEST, chatCreate);
  }