import {put, takeEvery, call,select} from 'redux-saga/effects';
import {redTypes} from '../../constants/reduxTypes';
import { verifyCodeRequestApi } from '../../services/apiCalls';
import { IVerifyCodeSuccessResponse, VerifyCodeResponse } from '../../types';


function* verifyCode(param:object) {

try {
   
 const response:IVerifyCodeSuccessResponse =yield verifyCodeRequestApi(param)

if (response?.status === true) {
  console.log('signupRes',response.status);
  
yield put({type: redTypes.CODE_VERIFY_SUCCESS, payload: response});
}else if (response.message) {
  yield put({
    type: redTypes.CODE_VERIFY_FAILURE,
    errorMessage: response.message,
  });
}
else {
  yield put({
    type: redTypes.CODE_VERIFY_FAILURE,
    errorMessage: "Something went wrong",
  });
}
} catch (e) {
yield put({
  type: redTypes.CODE_VERIFY_FAILURE,
  errorMessage: "Something went wrong",
});
}
}
export default function* verifyCodeSaga():any {
     return yield takeEvery (redTypes.CODE_VERIFY_REQUEST, verifyCode);
  }