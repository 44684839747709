import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import ImageViewers from "./imageViewer";
import { useSelector, useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import { redTypes } from "../../../constants/reduxTypes";
import { KTIcon } from "../../../../_metronic/helpers";
import "./style.css";
import LoadingIndicator from "./loadingIndicator";

interface ImageSelectorProps {
  data: any;
  // onSelectImages: (images: FileList | null) => void;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({ data }) => {
  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const multi_images: any = useSelector<any>(
    (state) => state.billListReducer?.multi_image
  );

  const loading: any = useSelector<any>(
    (state) => state.billListReducer?.loading
  );
  const [selectImg, setSelectImg] = useState(false);
  console.log("loading", loading);
  console.log("multi_images", multi_images);
  const dispatch = useDispatch();

  useEffect(() => {
    if (multi_images) {
      console.log("clear stATE SUCCSS");
      setSelectImg(false);
    }
  }, [multi_images]);

  const [count, setCount] = useState(0);

  const [selectedImg, setselectedImg] = useState<FileList | any>(null);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  console.log("imageUrl", imageUrls);
  const [fileList, setFileList] = useState<FileList | any>(null);

  //let selectedImg:any =[]
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectImg(true);
    const overAllLength: any = event.target.files?.length;
    console.log("overAllLength", overAllLength);

    if (overAllLength < 5) {
      const selectedImages = event.target.files;
      console.log("selectedImages", selectedImages);
      setselectedImg(selectedImages);

      const urls: string[] = [];

      if (selectedImages) {
        for (let i = 0; i < selectedImages.length; i++) {
          const reader = new FileReader();
          reader.onload = (event) => {
            if (event.target) {
              urls.push(event.target.result as string);
              if (urls.length === selectedImages.length) {
                setImageUrls(urls);
              }
            }
          };
          reader.readAsDataURL(selectedImages[i]);
        }
      }
      setFileList(selectedImages);
    } else {
      window.confirm("please select upto 4 images");
    }
    // selectedImg.push(selectedImages)

    //onSelectImages(selectedImages);
  };

  console.log("selectedImg", selectedImg);
  console.log("fileList", typeof fileList);

  const handleSubmit = async () => {
    dispatch({
      type: redTypes.IMAGE_UPLOADER_REQUEST,
      payload: {
        file: fileList,
        multiple: true,
        store_id: data.id,
        subdomains: loginSuccess.domain,
      },
    });
  };

  const handleDelete = (value: any) => {
    console.log("delete", value);
    imageUrls.splice(value, 1);
    const fileArray = Array.from(selectedImg);
    const indexesToDelete = [value];
    const newFileArray = fileArray.filter(
      (_, index) => !indexesToDelete.includes(index)
    );
    console.log("fileArray", newFileArray);
    setFileList(fileArray);
    //const newFileList = new FileList(fileArray);
    console.log("img url", imageUrls);
    setCount(count + 1);
    // SetSelectedFile(selectedfile)
  };

  return (
    <>
      {!loading ? (
        <>
          {selectImg && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {imageUrls[0] && (
                <div className="container">
                  <img
                    className="image"
                    src={imageUrls[0]}
                    //onClick={ () => openImageViewer(1) }
                    width="200"
                    key={0}
                    style={{ margin: "2px" }}
                    alt=""
                  />
                  <div className="middle">
                    <button
                      onClick={() => {
                        handleDelete(0);
                      }}
                    >
                      <KTIcon iconName="trash" className="fs-3" />
                    </button>
                  </div>
                </div>
              )}

              {imageUrls[1] && (
                <div className="container">
                  <img
                    className="image"
                    src={imageUrls[1]}
                    //onClick={ () => openImageViewer(1) }
                    width="200"
                    key={1}
                    style={{ margin: "2px" }}
                    alt=""
                  />
                  <div className="middle">
                    <button
                      onClick={() => {
                        handleDelete(1);
                      }}
                    >
                      <KTIcon iconName="trash" className="fs-3" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          {selectImg && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              {imageUrls[2] && (
                <div className="container">
                  <img
                    className="image"
                    src={imageUrls[2]}
                    //onClick={ () => openImageViewer(1) }
                    width="200"
                    key={2}
                    style={{ margin: "2px" }}
                    alt=""
                  />
                  <div className="middle">
                    <button
                      onClick={() => {
                        handleDelete(2);
                      }}
                    >
                      <KTIcon iconName="trash" className="fs-3" />
                    </button>
                  </div>
                </div>
              )}

              {imageUrls[3] && (
                <div className="container">
                  <img
                    className="image"
                    src={imageUrls[3]}
                    //onClick={ () => openImageViewer(1) }
                    width="200"
                    key={3}
                    style={{ margin: "2px" }}
                    alt=""
                  />
                  <div className="middle">
                    <button
                      onClick={() => {
                        handleDelete(3);
                      }}
                    >
                      <KTIcon iconName="trash" className="fs-3" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          {!selectImg ? (
            <ImageViewers data={data} image={multi_images} />
          ) : null}
          <div className="fileupload-view">
            <div className="row justify-content-center m-0">
              <div className="col-md-6">
                <div className="card mt-5">
                  <div className="card-body">
                    <div className="kb-data-box">
                      <div className="kb-file-upload">
                        <div className="file-upload-box">
                          <input
                            type="file"
                            id="fileupload"
                            className="file-upload-input"
                            onChange={handleImageChange}
                            multiple
                          />
                          <span className="file-link">Edit your files</span>
                        </div>
                      </div>

                      {selectImg && imageUrls.length > 0 && (
                        <div className="kb-buttons-box">
                          <button
                            onClick={handleSubmit}
                            className="btn btn-primary form-submit"
                          >
                            Upload
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </>
  );
};

export default ImageSelector;
