/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useLocation} from 'react-router-dom'
import "./index.css";
import { useSelector } from 'react-redux';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './style.css'
import SimpleImageSlider from "react-simple-image-slider";
import ImageViewer from 'react-simple-image-viewer';
import { Modal } from "react-bootstrap";
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
const noImg = require('../../../../../src/_metronic/images/noImg.png')
interface StoreHeaderProps {
    state:any
    
 // onSelectImages: (images: FileList | null) => void;
}
const StoreHeader: React.FC<StoreHeaderProps> = () => {
  const location:any = useLocation()

console.log('state in header',location?.state)
const [isViewerOpen, setIsViewerOpen] = React.useState(false);
const [currentImage, setCurrentImage] = React.useState(0);
const [show, setShow] = React.useState(false);
let sliderImages:any =[];
let slide:any =[]
  if(location?.state.review&&location?.state.gallery != null){
     sliderImages = location?.state.gallery.split(',');
     console.log('sliderImages',sliderImages)
     sliderImages.map((data:any,index:any)=>{
      console.log('each img',data)
      if(data != ""){
        slide.push(data)
      }

     })
  }
  console.log('newSlide',slide)

  const openImageViewer = React.useCallback((index:any) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
    setShow(true)
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
    setShow(false)
  };
 
  const handleClose =()=>{
    setShow(false)
  }

//   const customerData: any = useSelector<any>(
//     (state) => state.billListReducer?.customerState
//   );
  return (
    <>
    <div className='card mb-0 '>
      <div className='card-body pt-9 pb-0'>
        <Row >
           <Col sm={2} >

            <div className='me-7 mb-4' style={{display:'flex',alignItems:'center'}}>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
            <img src={location.state.url ? location.state.url : "https://bill2u-image-bucket.s3.ap-southeast-1.amazonaws.com/1692868445732kasturi.jpg"} alt='EmptyPlaceholder' style={{ border: '1px solid #ccc' }} />
              {/* <div className='image-input-wrapper w-125px h-125px' style={{backgroundImage:''}}></div> */}
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>
           </Col>
            <Col sm={4}  style={{display:'flex',justifyContent:'center',paddingTop:'10px'}} >
            <div style={{display:'flex',flexDirection:'column'}}>
            <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                   {location.state?location.state.name:""}
                  </a>
                  
                </div>

                <div className='d-flex align-items-center mb-2'>
                <a
                    href='#'
                    className='d-flex align-items-center  me-5 mb-2'style={{ color: "#A1A5B7" }}
                  >
                    <KTIcon iconName='geolocation' className='fs-4 me-1' />
                    {location.state?location.state.address1:""}
                  </a>
                </div>
                <div className='d-flex align-items-center mb-2'>
                <a
                    href='#'
                    className='d-flex align-items-center  mb-2'style={{ color: "#A1A5B7" }}
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {location.state?location.state.address2:""}
                  </a>
                  </div>
                  <div className='d-flex align-items-center mb-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center  mb-2'style={{ color: "#A1A5B7" }}
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {location.state?location.state.desc:""}
                  </a>
                  </div>
            </div>



            </Col>
             <Col sm={4} >
             <div >
          {slide.length>0?
          <SimpleImageSlider
            onClick={(index) => openImageViewer(index)}
            showBullets={false}
            width={500}
            height={300}
            images={slide}
            showNavs={true}
         />: <img src={toAbsoluteUrl(noImg)} style={{height:"300px",width:"500px"}} alt='' />
         
        }
        
           
             
            </div>

             </Col>
         

          
            
          
          
      
        </Row>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'style={{color:'#009ef7'}}>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/stores/view' && 'active')
                }
                to='/stores/review' state={location?.state}
                >
                  Reviews
              </Link>
            </li>
            <li className='nav-item'style={{color:'#009ef7'}}>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/stores/chat' && 'active')
                }
                to='/stores/chat' state={location?.state}
              >
                Chat
              </Link>
            </li>
          
          </ul>
        </div>
      </div>
    </div>
    <Modal
        id="kt_modal_create_admin"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-500px"
        show={show}
        onHide={handleClose}
        //onEntered={loadStepper}
        backdrop={true}
      >
    {isViewerOpen && (
         <ImageViewer
          src={ slide }
          currentIndex={ currentImage }
          disableScroll={ false }
          closeOnClickOutside={ true }
           onClose={ closeImageViewer }
         />
      )}
      </Modal>

    </>
  )
}

export {StoreHeader}
