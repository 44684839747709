/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
// import {getUserByToken, login} from '../core/_requests'
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
// import {useAuth} from '../core/Auth'
import { adminLogin, login } from "../../actions/apiRequests";
// import { LoginModel } from './models'
import { AuthUserModel } from "../../types";
import { Spinner } from "../spinner";
import { redTypes } from "../../constants/reduxTypes";
import { LoginHeader } from "./loginHeader";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  subdomains: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("SubDomain is required"),
});

const initialValues: AuthUserModel = {
  email: "",
  password: "",
  subdomains: "",
};

export function AdminLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  const adminLoginState: any = useSelector<any>(
    (state) => state.authReducer?.adminLoginState
  );
  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const errorAdmin: any = useSelector<any>((state) => state.authReducer?.errorAdmin);
  const loading: any = useSelector<any>((state) => state.authReducer?.loading);
  // React.useEffect(() => {
  //   console.log('errorClear');
  //   if (error) {
  //   dispatch({
  //     type: redTypes.CLEAR_ERROR,
  //   });
  // }
  // }, [error]);
  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    status,
    setStatus,
  } = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      console.log("on submit");
      const data = {
        email: values.email,
        password: values.password,
        subdomains: values.subdomains,
      };
      dispatch(adminLogin(data));
      //  setStatus('The login details are incorrect')
    },
  });
  React.useEffect(() => {
    if (errorAdmin) {
      setTimeout(() => {
        dispatch({
        type: redTypes.CLEAR_ADMIN_LOGIN_STATE,
      });
      }, 5000);
    }
  }, [errorAdmin]);
  React.useEffect(() => {

    if (adminLoginState) {
      navigate("/dashboard");
      dispatch({
        type: redTypes.CLEAR_ADMIN_LOGIN_STATE,
      });
    }
  }, [adminLoginState]);
  return (
    <>
    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
           <LoginHeader/>
      <form
        className="form w-100 mt-10"
        onSubmit={handleSubmit}
        noValidate
        id="kt_login_signin_form2"
      >
        {/* begin::Heading */}
        {/* <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Admin SignIn</h1>
      </div> */}

        <div className="fv-row mb-8">
          <label className="form-label fs-6 fw-bolder text-dark">Email</label>
          <input
            placeholder="Enter your email"
            name="email"
            type="email"
            className={clsx(
              "form-control bg-transparent",
              { "is-invalid": touched.email && errors.email },
              {
                "is-valid": touched.email && !errors.email,
              }
            )}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
          />
          {touched.email && errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert ">{errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-3">
          <label className="form-label fw-bolder text-dark fs-6 mb-0">
            Subdomain
          </label>
          <input
            autoComplete="off"
            placeholder="Subdomain"
            name="subdomains"
            type="text"
            className={clsx(
              "form-control bg-transparent",
              { "is-invalid": touched.subdomains && errors.subdomains },
              {
                "is-valid": touched.subdomains && !errors.subdomains,
              }
            )}
            value={values.subdomains}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.subdomains && errors.subdomains && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert ">{errors.subdomains}</span>
              </div>
            </div>
          )}
        </div>

        <div className="fv-row mb-3">
          <label className="form-label fw-bolder text-dark fs-6 mb-0">
            Password
          </label>
          <input
            autoComplete="off"
            placeholder="Password"
            name="password"
            type="password"
            className={clsx(
              "form-control bg-transparent",
              { "is-invalid": touched.password && errors.password },
              {
                "is-valid": touched.password && !errors.password,
              }
            )}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.password && errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert ">{errors.password}</span>
              </div>
            </div>
          )}
        </div>

        {errorAdmin && !loading && (
         <div className='notice d-flex rounded border-danger border border-dashed p-3'style={{backgroundColor:'#FCAEAE'}}>
         <span style={{color: "red" }}>
           <KTIcon iconName='information-5' className='fs-1 me-4' />
           </span>
           <div className='d-flex flex-stack flex-grow-1'>
             <div className='fw-bold'>
               <div className='fs-6'style={{color:'white'}}>
              
               {errorAdmin}
               </div>
             </div>
           </div>
         </div>
        )}
        {/* end::Form group */}

        {/* begin::Wrapper */}
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className="d-grid mb-7 mt-6">
          <button
            type="submit"
            id="kt_admin_sign_in_submit"
            className="btn btn-bg-primary "
            style={{ color: "white" }}
            //disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className="indicator-label">Log In</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>

      </div>
    </div>
    </div>
    </>
  );
}
