/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import { KTIcon } from '../../../../_metronic/helpers'
import clsx from 'clsx'
import { NotificationHeader } from '../../../../_metronic/partials/layout/header-menus/notificationHeader'

const NotificationChart: React.FC = () => {
  const location = useLocation()

  return (
    <>

        <div className='d-flex flex-column rounded-top'>

        <div className='scroll-y mh-325px  px-1 '>
          {/* {defaultAlerts.map((alert, index) => ( */}
            <div key={`alert$`} className='d-flex flex-stack '>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-4'>
                  <span className={clsx('symbol-label',)}>
                    {' '}
                    <KTIcon iconName='information-5' className={`fs-2 text-`} />
                  </span>
                </div>

                <div className='mb-0 me-2'>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                    prakash
                  </a>
                  <div className='text-gray-400 fs-7'>Confidential staff documents</div>
                </div>
              </div>

              <span className='badge badge-light fs-8'>1hr</span>
            </div>
            <div key={`alert$`} className='d-flex flex-stack '>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-4'>
                  <span className={clsx('symbol-label',)}>
                    {' '}
                    <KTIcon iconName='information-5' className={`fs-2 text-`} />
                  </span>
                </div>

                <div className='mb-0 me-2'>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                    prakash
                  </a>
                  <div className='text-gray-400 fs-7'>Confidential staff documents</div>
                </div>
              </div>

              <span className='badge badge-light fs-8'>1hr</span>
            </div>
          {/* ))} */}
        </div>

        <div className=' text-center border-top mt-6'>
          <Link
            to=''
            className='btn btn-color-gray-600 btn-active-color-primary'
          >
            View All <KTIcon iconName='arrow-right' className='fs-5' />
          </Link>
        </div>
      </div>
      </>

  )
}

export {NotificationChart}
