import React, { FC, useRef } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { redTypes } from "../../../constants/reduxTypes";
import moment from "moment";
import {
  IBillBuyerDetail,
  IBillListSuccessResponse,
} from "../../../types/commonTypes";
import { IBillItem } from "../../../types/commonTypes";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { ErrorBoundaryBills } from "./ErrorBoundary";
import { KTIcon } from "../../../../_metronic/helpers";
import "./index.css";
import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";

interface locationStateProps {
  open?: boolean;
  close?: () => void;
}
export const BillListPopUp: FC = () => {
  const pdfRef: any = useRef();

  const [isCopied, setIsCopied] = useState(false);

  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const customerDetailData: any = useSelector<any>(
    (state) => state.billListReducer?.customerSuccess
  );
  //console.log("customerDetailData", customerDetailData);

  // const billDetails: any = useSelector<any>(
  //   (state) => state.billListReducer?.billItems
  // );
  //console.log("billsDetails", billDetails);
  const billsInvoice: any = useSelector<any>(
    (state) => state.billListReducer?.billsInvoice
  );
  //console.log("billsInvoice", billsInvoice);
  const componentRef: any = useRef();

 // const { state }: any = useLocation();
  //console.log("stateLocation", state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const refreshPage = () => {
  //   window.location.reload();
  // };

  // React.useEffect(() => {
  //   console.log("useeffect");
  //   if (state) {
  //     dispatch({ type: redTypes.SET_STATE, payload: { state: state } });
  //   }
  // }, [state]);

  // React.useEffect(() => {
  //   // console.log('useeffect for call')
  //   dispatch({
  //     type: redTypes.CUSTOMER_DETAIL_REQUEST,
  //     payload: { state: state, domain: loginSuccess.domain },
  //   });
  // }, []);

  const handleClick = () => {
    //dispatch({ type: redTypes.RESET_CUSTOMER_DATA });
    dispatch({ type: redTypes.RESET_SET_STATE });
    navigate("/bills");
  };
  const downloadPdf = async () => {
    console.log("pdfFile");
    const input = pdfRef.current;
    await html2canvas(input,).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeigth = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeigth / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("invoice.pdf");
    });
  };
  const copy = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };
  const handleNavigate = () => {
    navigate("/customer/view");
  };
  return (
    <>
      {!billsInvoice ? (
        <ErrorBoundaryBills />
      ) : (
        <div>
          <div className="d-flex flex-direction-row justify-content-between ">
            <div
              className=" align-items-start d-flex flex-direction-row  mb-2"
              style={{ gap: 15, color: "#009ef7", fontSize: "24px" }}
            >
              <span onClick={handleClick}>
                <KTIcon
                  iconName="arrow-left hide-on-print"
                  className="fs-3x "
                />
              </span>
              <span className="card-label fw-bold hide-on-print">
                Bills View
              </span>
            </div>
            {/* <div className=" d-flex justify-content-end">
              <button
                type="button"
                className="btn mb-5 btn-bg-success fw-bold"
                style={{ color: "white" }}
                
              >
                <KTIcon iconName="arrow-left" className="fs-3" />
                back
              </button>
            </div> */}
          </div>
          <div className="" ref={componentRef}>
            <div className="html2canvas-container" ref={pdfRef}>
              <div className="row g-5 g-xxl-8  card-print">
                <div className="col-xl-10 col-md-10 col-sm-10 ">
                  <div className="card mb-5 mb-xl-10">
                    <div className="card-body pt-5 pb-0">
                      <div className="row g-5 g-xxl-8 h-100px">
                        <div className="col-xl-4 col-md-4 col-sm-4 justify-content-between  d-flex ">
                          <div className="d-flex flex-column px-10">
                            <a
                              // href="#"
                              className="text-gray-800 text-hover-primary  mt-2 fs-4 fw-semibold"
                            >
                              Order
                              <span className="fs-4 fw-bold">
                                {" "}
                                #{billsInvoice?.bill_number}
                              </span>
                            </a>

                            <span
                              className=" fw-semibold"
                              style={{ color: "#7e8299" }}
                            >
                              {moment(billsInvoice?.createdAt).format(
                                "MMM DD YYYY h:mm A"
                              )}
                            </span>
                          </div>
                          <div className="d-flex" style={{height:'60px'}}>
                         <div className="vr"></div>
                          </div>
                        </div>
                        {/* <div className="divider"></div> */}

                        <div className="col-xl-4 col-md-4 col-sm-4 justify-content-between  d-flex">
                          <div className="d-flex flex-column px-10">
                            <a
                              // href="#"
                              className="text-gray-800 text-hover-primary  mt-2 fs-4 fw-bold"
                            >
                              {billsInvoice.stores?.name}
                            </a>
                            <div className="d-flex flex-row gap-2">
                              <KTIcon
                                iconName="geolocation"
                                className="fs-5 text-danger mt-1 "
                              />
                              <span
                                className="fw-semibold"
                                style={{ color: "#7e8299" }}
                              >
                                {billsInvoice.stores?.address1}
                              </span>
                            </div>
                          </div>
                          {/* <span
                            className="bullet bullet-vertical h-50px "
                            style={{ backgroundColor: "#7b7d7e" }}
                          ></span> */}
                          <div className="d-flex" style={{height:'60px'}}>
                         <div className="vr"></div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-3 col-sm-3 justify-content-start px-13 d-flex">
                          <div className="d-flex flex-column">
                            <a
                              // href="#"
                              className="text-gray-800 text-hover-primary  mt-2 fs-4 fw-bold"
                            >
                              {customerDetailData
                                ? customerDetailData.firstname
                                : null}{" "}
                            </a>

                            <span
                              className=" fw-semibold"
                              style={{ color: "#7e8299" }}
                            >
                              
                              +{billsInvoice.bill_buyer_details?.phone}
                            </span>
                          </div>
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 justify-content-start  d-flex"
                          data-html2canvas-ignore="true"
                        >
                          {/* <KTIcon
                            iconName="arrow-right"
                            className="fs-1 text-danger mt-6 "
                          /> */}
                          {/* <i
                            className="ki-duotone ki-arrow-right hide-on-print mt-3 fs-3x"
                            onClick={handleNavigate}
                          >
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i> */}
                          <span onClick={handleNavigate}style={{ color: "#7e8299" }}>
                <KTIcon
                  iconName="arrow-right hide-on-print"
                  className="fs-3x mt-3"
                />
              </span>
                          {/* <span className=" fw-bold"style={{fontSize:35}}>{'>'}</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-md-2 col-sm-2 " data-html2canvas-ignore="true">
                  <div className="row  mb-5 mb-xl-5 " style={{}}>
                    {/* begin::Col */}
                    <div className="col-md-6 col-lg-6 col-xl-6 col-sm-6">
                      {/* <ReactToPrint
          trigger={() => */}
                      <button
                        className="hide-on-print hide-btn btn btn-bg-light  btn-flex mb-4 btn-sm gap-2 "
                        onClick={() => {
                          window.print();
                        }}
                        style={{ backgroundColor: "white" }}
                      >
                        <span style={{ fontSize: "0.8vw", color: "#1BBC9B" }}>
                          <KTIcon iconName="printer" className="fs-5" />
                        </span>
                        <span className="hide-text"> Print</span>
                       
                      </button>
                      {/* //         }
        //   content={() => componentRef.current}
        //   documentTitle="Invioce Details"
        //   pageStyle='print'
        // /> */}

                      <button
                        className="hide-on-print hide-btn btn btn-bg-light btn-flex btn-sm gap-2 "
                        style={{ backgroundColor: "white" }}
                        onClick={(e) => {
                          window.open(
                            "https://mail.google.com/mail/u/0/#inbox?compose=new"
                          );
                        }}
                      >
                        <span style={{ fontSize: "0.8vw", color: "#8E44AD" }}>
                          <KTIcon iconName="sms" className="fs-5" />
                        </span>
                         <span className="hide-text"> Mail</span>
                      </button>
                    </div>

                    <div className="col-md-6 col-lg-6 col-xl-6 col-sm-6">
                      <button
                        className="hide-on-print hide-btn btn btn-bg-light btn-flex mb-4 btn-sm gap-2 "
                        onClick={downloadPdf}
                        style={{ backgroundColor: "white" }}
                      >
                        <span style={{ fontSize: "0.8vw", color: "#D91E18" }}>
                          <KTIcon iconName="document" className="fs-5 " />
                        </span>
                         <span className="hide-text"> Pdf</span>
                      </button>
                      <button
                        className="hide-on-print hide-btn btn btn-bg-light btn-flex btn-sm gap-2 "
                        onClick={copy}
                        style={{ backgroundColor: "white" }}
                      >
                        <span style={{ fontSize: "0.8vw", color: "#F3C200" }}>
                          <KTIcon iconName="share" className="fs-5" />
                        </span>
                         <span className="hide-text"> Share</span>
                      </button>
                      {isCopied && (
                        <div className="d-flex justify-content-end" style={{}}>
                          <span
                            className="text-center fw-bolder fs-6"
                            style={{ color: "blue" }}
                          >
                            Link Copied !
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-5 g-xxl-8">
                <div className="col-xl-12">
                  <div className="card mb-5 mb-xl-10">
                    <div className="card-body pt-5 pb-0">
                      <div className=" table-responsive mb-9 py-5">
                        <table className="table  table-row-gray-300 table-hover gy-5">
                          <thead
                            className="table table-row-dashed"
                            style={{ color: "black" }}
                          >
                            <tr className="fw-bolder fs-6">
                              <th className="text-center">#</th>
                              <th className="text-center">ITEM</th>
                              <th className="text-center">QTY</th>
                              <th className="text-center">PRICE</th>
                              <th className="text-center">AMOUNT</th>
                              {/* <th className='text-center'>Discount</th>
                      <th className='text-center'>Warranty Date</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {!billsInvoice ? (
                              <div
                                className=" fw-bold fs-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: 105,
                                }}
                              >
                                Data not found!!
                              </div>
                            ) : (
                              <>
                                {billsInvoice &&
                                  billsInvoice.bill_items?.map(
                                    (val: IBillItem, index: any) => {
                                      // console.log("valuesBILLItem", val);
                                      return (
                                        <tr>
                                          <td
                                            className="text-center"
                                            style={{ color: "#7e8299" }}
                                          >
                                            {val.bill_id}
                                          </td>
                                          <td className="text-center fw-bolder">
                                            {val.item_name}
                                            <br />
                                            <span
                                              className=" fw-semibold"
                                              style={{ color: "#7e8299" }}
                                            >
                                              {
                                                billsInvoice.bill_buyer_details?.phone
                                                  
                                              }
                                            </span>
                                            <br />
                                            <span
                                              className=" fw-semibold"
                                              style={{ color: "#7e8299" }}
                                            >
                                              $
                                              {
                                                billsInvoice?.bill_amounts
                                                  ?.tax_rate
                                              }{" "}
                                              (
                                              {
                                                billsInvoice.bill_amounts
                                                  ?.cess_rate
                                              }
                                              ,
                                              {
                                                billsInvoice.bill_amounts
                                                  ?.assess_amount
                                              }{" "}
                                              )
                                            </span>
                                          </td>
                                          <td
                                            className="text-center"
                                            style={{ color: "#7e8299" }}
                                          >
                                            {val.qty}
                                          </td>
                                          <td
                                            className="text-center"
                                            style={{ color: "#7e8299" }}
                                          >
                                            {val.unit_price}
                                          </td>
                                          <td
                                            className="text-center"
                                            style={{ color: "#7e8299" }}
                                          >
                                            {val.unit_price}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-5 g-xxl-8">
                <div className="col-xl-6 col-md-6">
                  <div className="card mb-5 mb-xl-10  h-md-100">
                    <div className="card-body pt-5 pb-0">
                      <div className="row mb-7 px-3">
                        <div className="col-xl-6 col-md-6 d-flex justify-content-center flex-column">
                          <span
                            className="fs-6 fw-bolder mb-7 mt-5"
                            style={{ color: "#A1A5B7" }}
                          >
                            Billing Address
                          </span>
                          <span className="fs-6">
                          {/* {billsInvoice?.bill_buyer_details?
                          <> */}
                            {billsInvoice.bill_buyer_details? billsInvoice.bill_buyer_details.address1:'No data found'},
                            <br />
                            {billsInvoice.bill_buyer_details?billsInvoice.bill_buyer_details.address2:'No data found'},
                            <br />
                            {billsInvoice.bill_buyer_details?billsInvoice.bill_buyer_details.state:'No data found'},
                            <br />
                            {billsInvoice.bill_buyer_details?billsInvoice.bill_buyer_details.locality:'No data found'} -{" "}
                            {billsInvoice.bill_buyer_details?billsInvoice.bill_buyer_details.pin:''},
                            {/* </>
                            :'No data found'} */}
                          </span>
                        </div>
                        {/* <div className="divider py-1 bg-success "></div> Horizontal separator */}
                        <div className="col-xl-6 col-md-6 d-flex justify-content-center  flex-column">
                          <span
                            className="fs-6 fw-bolder mb-7 mt-5"
                            style={{ color: "#A1A5B7" }}
                          >
                            Shipping Address
                          </span>
                          <span className="fs-6">
                            Unit 1/23 Hastings Road,
                            <br />
                            Melbourne 3000,
                            <br />
                            Victoria,
                            <br />
                            Australia.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6">
                  <div className="card mb-5 mb-xl-10 h-md-100">
                    <div className="card-body pt-5 pb-0">
                      <div className="row mb-7 px-3">
                        <label
                          className="col-lg-3 "
                          style={{ color: "#A1A5B7" }}
                        >
                          PRE TAX VALUE
                        </label>

                        <div className="col-lg-6 ">
                          <span className=" fs-6 d-flex justify-content-end">
                            $ {billsInvoice.bill_amounts?.pretax_value}
                          </span>
                        </div>
                      </div>
                      <div className="row mb-7 px-3">
                        <label
                          className="col-lg-3 "
                          style={{ color: "#A1A5B7" }}
                        >
                          CGST
                        </label>

                        <div className="col-lg-6">
                          <span className=" fs-6 d-flex justify-content-end">
                            $ {billsInvoice.bill_amounts?.cess_rate}
                          </span>
                        </div>
                      </div>
                      <div className="row mb-7 px-3">
                        <label
                          className="col-lg-3 "
                          style={{ color: "#A1A5B7" }}
                        >
                          SGST
                        </label>

                        <div className="col-lg-6">
                          <span className=" fs-6  d-flex justify-content-end">
                            $ {billsInvoice.bill_amounts?.tax_rate}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-7 px-3">
                        <label
                          className="col-lg-3"
                          style={{ color: "#A1A5B7" }}
                        >
                          DISCOUNT
                        </label>

                        <div className="col-lg-6 ">
                          <span className=" fs-6 d-flex justify-content-end">
                            $ {billsInvoice.bill_amounts?.discount}
                          </span>
                        </div>
                      </div>
                      <div className="row mb-7 px-3">
                        <label
                          className="col-lg-3"
                          style={{ color: "#A1A5B7" }}
                        >
                          OTHER CHARGES
                        </label>

                        <div className="col-lg-6 ">
                          <span className=" fs-6 d-flex justify-content-end">
                            $ {billsInvoice.bill_amounts?.other_charges}
                          </span>
                        </div>
                      </div>
                      <div className="border-gray-300 border-bottom-dashed mb-4 "></div>
                      <div className="row mb-7 px-3">
                        <label
                          className="col-lg-3 col-md-3 fw-bold"
                          style={{ color: "#A1A5B7" }}
                        >
                          TOTAL INVOICE VALUE
                        </label>

                        <div className="col-lg-6 ">
                          <span className="fw-bolder fs-6 d-flex justify-content-end">
                            $ {billsInvoice.bill_amounts?.total_invoice_value}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
