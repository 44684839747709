import React, { FC } from "react";
import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { Modal } from "react-bootstrap";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { KTIcon } from "../../../../_metronic/helpers";
import { StoreCreation, apiKeysCreation } from "../../../actions/apiRequests";
import { IAdminUser, IAdminUserCreate } from "../../../types/commonTypes";
import { redTypes } from "../../../constants/reduxTypes";
import { CSVLink } from "react-csv";

type Props = {
  show: boolean;
  data?: any;
  handleClose: () => void;
};

const registrationSchema = Yup.object().shape({
  days: Yup.number().required(" Days is required"),
});

export const CreateApiKeys = ({ show, handleClose, data }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const keysGenerateSuccess: any = useSelector<any>(
    (state) => state.billListReducer?.keysGenerateSuccess
  );
  const apiKeys: any = useSelector<any>(
    (state) => state.billListReducer?.apiKeys
  );
  // function  generateCsvData(){
  // const keysDatas=[{
  //   merchant_secret_key: keysGenerateSuccess.merchant_secret_key ,
  //   merchant_access_key:keysGenerateSuccess.merchant_access_key
  // }]
  // return keysDatas
  // }
  const [csvData, setCsvData] = useState<any>();
  const [isCopied, setIsCopied] = useState(false);
  const [isCopied2, setIsCopied2] = useState(false);

  // const errorAdminCreate: any = useSelector<any>((state) => state.billListReducer?.errorAdminCreate);
  // const errorCodeAdminCreate: any = useSelector<any>(
  //   (state) => state.billListReducer?.errorCodeAdminCreate
  // );

  // React.useEffect( () => {
  //   if(typeof(storeCreationSuccess) === 'object'){
  //       console.log('useEffect')
  //       handleClose()

  //   }

  // }, [storeCreationSuccess]);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    status,
    setStatus,
  } = useFormik({
    initialValues: {
      access_key: keysGenerateSuccess
        ? keysGenerateSuccess.merchant_access_key
        : null,
      Secret_key: keysGenerateSuccess
        ? keysGenerateSuccess.merchant_secret_key
        : null,
      days: "",
    },
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      //  console.log('on submit for admin')
      const data = {
        days: values.days,
        domain: loginSuccess.domain,
        id: loginSuccess.id,
      };
      dispatch(apiKeysCreation(data));
    },
  });
  React.useEffect(() => {
    console.log("keysValues", values);
    setCsvData([
      {
        Accesskey: values.access_key,
        Secretkey: values.Secret_key,
      },
    ]);
  }, [values]);
  const handleClick = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };
  const handleClickSecretKey = () => {
    setIsCopied2(true);
    setTimeout(() => {
      setIsCopied2(false);
    }, 5000);
  };
  const handleCloseButton = () => {
    handleClose();
    dispatch({
      type: redTypes.CLEAR_APIKEYS_STATE,
    });
  };
  return (
    <>
      <Modal
        id="kt_modal_create_admin"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-550px"
        show={show}
        onHide={handleClose}
        onEntered={loadStepper}
        backdrop={true}
      >
        <div className="modal-header">
          <h2>Generate New Key</h2>
          {/* begin::Close */}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={handleCloseButton}
          >
            <KTIcon className="fs-1" iconName="cross" />
          </div>
          {/* end::Close */}
        </div>

        <div className="modal-body py-lg-8 px-lg-10 ">
          {/* <div className="d-flex  mb-4"> */}
            {!keysGenerateSuccess && (
              <>
               <div className="row mb-5">
                <label className=" fs-5 mt-2 fw-bold col-lg-2 ">Valid Till </label> 
                {/* <input
                type="text"
                placeholder="Days"
                name="days"
                // style={
                //   width: "60%",
                //   border: "1px solid",
                //   borderColor: "#e1e3ea",
                //   borderRadius: 8,
                //   padding: 10,
                // }}
                className={clsx(
                  'form-control form-control-solid',
                  { "is-invalid": touched.days && errors.days },
                  {
                    "is-valid": touched.days && !errors.days,
                  }
                )}
                value={values.days}
                onChange={handleChange}
                onBlur={handleBlur}
              /> */}
              <div className="col-lg-10">
                <select
                  aria-label="Select example"
                  name="days"
                  className={clsx(
                    "form-select form-select-solid",
                    { "is-invalid": touched.days && errors.days },
                    {
                      "is-valid": touched.days && !errors.days,
                    }
                  )}
                  value={values.days}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value='' disabled selected hidden>Days</option>
                  <option value="30">30</option>
                  <option value="60">60</option>
                  <option value="90">90</option>
                  <option value="120">120</option>
                  <option value="180">180</option>
                  <option value="365">365</option>
                </select>
                </div>
                </div>
              </>
            )}
    
          {touched.days && errors.days && (
            <div className="fv-plugins-message-container mb-2">
              <div className="fv-help-block">
                <span role="alert " style={{ paddingLeft: "85px" }}>
                  {errors.days}
                </span>
              </div>
            </div>
          )}
          {keysGenerateSuccess && (
            <div>
              <div className="d-flex flex-direction-row justify-content-end mb-4">
                
                  {csvData && (
                    <CSVLink data={csvData} filename={"apiKeys.csv"}>
                      <button
                        type="button"
                        className="btn fw-bolder"
                        style={{ backgroundColor: "#f1faff", color: "#009ef7"}}
                        // onClick={(e) => handleDownloadClick()}
                      >
                        <i className="bi bi-download fs-3"></i>
                        Download
                      </button>
                    </CSVLink>
                  )}
         
              </div>

              <label className=" fs-6 fw-semibold mb-2">Access Key</label>
              {/* <div className='d-flex flex-direction-column'> */}
              <div className="input-group mb-5">
                <input
                  type="text"
                  placeholder="Access Key"
                  name="access_key"
                  className="form-control"
                  disabled={true}
                  value={values.access_key}
                />

                <span
                  className="input-group-text  bg-hover-secondary  hover-scale"
                  onClick={() => {
                    navigator.clipboard.writeText(values.access_key);
                    handleClick();
                  }}
                  id="basic-addon1"
                >
                  <i className="ki-duotone ki-copy fs-4" style={{}}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  {/* {isCopied && (
                 <i className="ki-duotone ki-check fs-4" style={{}}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
              )} */}
                </span>
              </div>
              {isCopied && (
                <div className="d-flex justify-content-end" style={{}}>
                  <span
                    className="text-center fw-bolder fs-6"
                    style={{ color: "blue" }}
                  >
                    Copied!
                  </span>
                </div>
              )}

              <label className=" fs-6 fw-semibold mb-2 mt-2">Secret Key</label>
              <div className="input-group mb-5">
                <input
                  type="text"
                  name="Secret_key"
                  placeholder="Secret Key"
                  className="form-control"
                  disabled={true}
                  value={values.Secret_key}
                />
                <span
                  className="input-group-text bg-hover-secondary  hover-scale"
                  onClick={() => {
                    navigator.clipboard.writeText(values.Secret_key);
                    handleClickSecretKey();
                  }}
                  id="basic-addon2"
                >
                  <i className="ki-duotone ki-copy fs-4">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </span>
              </div>
              {isCopied2 && (
                <div className="d-flex justify-content-end" style={{}}>
                  <span
                    className="text-center fw-bolder fs-6 mb-2"
                    style={{ color: "blue" }}
                  >
                    Copied!
                  </span>
                </div>
              )}
              <div className="d-flex justify-content-center">
              {/* <div
                className="alert alert-dismissible  d-flex mb-5 px-5"
                style={{ color: "#ffffff", backgroundColor: "#F9BB6E" }}
              >
                <span className="text-center fw-bolder fs-6">
                 !! Please copy this key and keep it safe. You won't be able to see this again.
                </span>
              </div> */}
              
          <div className='notice d-flex rounded border-warning border border-dashed p-6'style={{backgroundColor:'#FFF8DD'}}>
          <span style={{color: "#deb232" }}>
            <KTIcon iconName='information-5' className='fs-1 me-4' />
            </span>
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                {/* <h4 className='text-gray-800 fw-bolder'>We need your attention!</h4> */}
                <div className='fs-6'style={{color:'#64656c'}}>
                 Please copy this key and keep it safe. You won't be able to see this again .
                  
                </div>
              </div>
            </div>
          </div>
              </div>
            </div>
          )}

          {/* {errorAdminCreate  || status   (
                  <div className="alert alert-dismissible bg-secondary justify-content-center d-flex flex-column flex-sm-row p-3 mb-5 px-5"style={{}}>
                        <div className="">
                       <span className="text-center fw-bolder fs-6"style={{color:'#813636'}}>
                       {errorAdminCreate ? ErrorCode - {errorCodeAdminCreate} : {errorAdminCreate} : status}
                        </span> 
                      </div>
                    </div>
                  )} */}
        </div>

        <div className="modal-footer flex-center">
          {!keysGenerateSuccess ? (
            <button
              onClick={() => handleSubmit()}
              className="btn btn-bg-primary fw-bold"
              style={{ color: "white" }}
            >
              Generate
            </button>
          ) : (
            <button
              className="btn fw-bold"
              onClick={handleCloseButton}
              style={{ color: "#e75d5d", backgroundColor: "#f1caca" }}
            >
              Close
            </button>
          )}
        </div>
      </Modal>
    </>
  );
};
