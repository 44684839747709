
  import React, { FC } from "react";
  import { useState, useRef } from "react";
  import { useSelector, useDispatch } from "react-redux";
  import { useNavigate, useLocation } from "react-router-dom";
  import moment from "moment";
  import { KTIcon } from "../../../../_metronic/helpers";
  import { Tab, Tabs } from "react-bootstrap";
  import { CustomerHeader } from "../customerList/customerHeader";
  import { redTypes } from "../../../constants/reduxTypes";
  import { ErrorBoundary } from "./ErrorBoundary";
  import clsx from 'clsx'
  import {
    defaultMessages,
    defaultUserInfos,
    MessageModel,
    UserInfoModel,
  } from '../../../../_metronic/helpers'
  import { IChat, IStores, ChatMessage } from "../../../types/commonTypes";



  type LocationState = {
    messagePayload: any; // Change the type according to your actual payload data type
  };

  export const Chat = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation() as { state: LocationState };
  const messageData = location.state?.messagePayload;
  console.log("chat Props: ", messageData );
  

    interface Store {
      id: number; // Use the appropriate type for store ID
      name: string; // Use the appropriate type for store name
    }

    const [message, setMessage] = useState<string>('')
    const [text, setText] = useState<string>('')
    const [store, setStore] = useState<Store>({
      id: 0,
      name: 'Store Name'
    });
    const [contents, setContents] = useState<IChat[]>()
    const [customerInfos] = useState<UserInfoModel[]>()
    const currentTimestamp = Date.now();


    const loginSuccess: any = useSelector<any>(
      (state) => state.authReducer?.loginSuccess
    );
    const customerState: any = useSelector<any>(
      (state) => state.billListReducer?.customerState
    );
    console.log("customerData", customerState);

    const storeData: any = useSelector<any>(
      (state) => state.billListReducer?.storeSuccess
    );
    console.log("StoreData:", storeData);

    const chatData: any = useSelector<any>(
      (state) => state.billListReducer?.chatSuccess
    );
    const chatCreateSuccess: any = useSelector<any>(
      (state) => state.billListReducer?.chatCreateSuccess
      );
      console.log("chatCreateSuccess", chatCreateSuccess);
  
    console.log("Chat Data", chatData);
    console.log("Messsage", message);
    console.log("Store:", store);

    React.useEffect(() => {
      dispatch({
        type: redTypes.CHAT_REQUEST,
        payload: {
          domain: loginSuccess.domain,
          user_id: customerState.user_id, store_id: store.id
        },
      });
    }, [store,chatCreateSuccess])

    

    function getRandomInt(min: number, max: number): number {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    
    
    const sendMessage = async () => { 
      const randomChatId = getRandomInt(10000, 99999); // Generate a 5-digit random number
      const newMessage = {
        chat_id: randomChatId,
        user_id: customerState.user_id,
        store_id: store.id,
        message: message,
        attachment_url: '',
        sent_by: "Store",
        read_by_user: true,
        read_by_store: true,
        subdomains: loginSuccess.domain,
      }
      await dispatch({
        type: redTypes.CHAT_CREATE_REQUEST,
        payload: {
          newMessage
        }
      });
    
      setMessage('');
    }

    const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.keyCode === 13 && e.shiftKey === false) {
        e.preventDefault()
        sendMessage()
      }
    }

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      console.log("event.target.value", event.target.value);
      let storevalue = JSON.parse(event.target.value);
      setStore(storevalue);


    };


    const handleClick = () => {
      navigate("/customers");
      dispatch({ type: redTypes.RESET_SET_STATE });
    };
    return (
      <>
        <CustomerHeader />

        {/* {store.id === 0 ? (
          <ErrorBoundary />
        ) : ( */}

        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">

          <div className="card-header cursor-pointer">
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Chat Details</h3>
            </div>
            <div className="card-toolbar">
              <select aria-label="Select Store" placeholder='Choose store' name='store' className={clsx("form-select form-select-solid")}
                onChange={handleChange} value={store.id == 0 ? "" : JSON.stringify(store)}
              >
                <option value="">Choose Store</option>
                {storeData.getStores?.map((val: any) => (
                  <option key={val.id} value={JSON.stringify({ id: val.id, name: val.name })}>{val.name}</option>
                ))}

              </select>
            </div>
          </div>

          <div
            className={clsx('scroll-y me-n5 p-9')}
            data-kt-element='messages'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
          >
            {store.id === 0 &&
            <ErrorBoundary />            
            }
            {chatData?.length === 0 &&
              <div className="text-dark fs-5 fw-bold d-flex justify-content-center align-items-center">
              There is no previous chat.
              </div>
            }

            {chatData?.map((data: IChat, index: number) => {

              const state = data.sent_by === 'User' ? 'info' : 'primary'
              const templateAttr = {}
            
              const contentClass = `d-flex justify-content-${data.sent_by === 'User' ? 'start' : 'end'
                } mb-10`
              return (
                <div
                  key={`message${index}`}
                  {...templateAttr}
                >
                  <div
                    className={clsx(
                      'd-flex flex-column align-items',
                      `align-items-${data.sent_by === 'User' ? 'start' : 'end'}`
                    )}
                  >
                    <div className='d-flex align-items-center mb-2'>
                      {data.sent_by === 'User' ? (
                        <>
                          <div className='symbol  symbol-35px symbol-circle '>
                            <img alt='Pic' src={require(`../../../images/customer1.png`)} />
                          </div>
                          <div className='ms-3'>
                            <a
                              href='#'
                              className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                            >
                              {customerState.user.firstname}
                            </a>
                            <span className='text-muted fs-7 mb-1'>
                              {moment(data.createdat).isBefore(moment().subtract(24, 'hours')) ?
                                moment(data.createdat).format('MM/DD/YYYY hh:mm A') :
                                moment(data.createdat).fromNow()
                              }</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='me-3'>
                            <span className='text-muted fs-7 mb-1'>
                              {moment(data.createdat).isBefore(moment().subtract(24, 'hours')) ?
                                moment(data.createdat).format('MM/DD/YYYY hh:mm A') :
                                moment(data.createdat).fromNow()
                              }</span>
                            <a
                              href='#'
                              className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                            >
                              {store.name}
                            </a>
                          </div>
                          <div className='symbol  symbol-35px symbol-circle '>
                            <img alt='Store' src={require(`../../../images/shopping_cart.png`)} />
                          </div>
                        </>
                      )}
                    </div>

                    <div
                      className={clsx(
                        'p-5 rounded',
                        // `bg-light-${state}`,
                        'text-dark mw-lg-400px',
                        `text-${data.sent_by === 'User' ? 'start' : 'end'}`
                      )}
                      data-kt-element='message-text'
                      // dangerouslySetInnerHTML={{ __html: data.message }}
                      style={{
                        backgroundColor: data.sent_by === 'User' ? '#fbfdb6' : '#e4f2f9'
                      }}
                    >
                      {data.message && (
                        <div
                          dangerouslySetInnerHTML={{ __html: data.message }}
                        ></div>
                      )}
                    </div>

                  </div>
                </div>
              )
            })}
          </div>


          <div className='card-footer pt-4 d-flex  justify-content-between' >
            <textarea
              className='form-control form-control-flush mx-3'
              rows={1}
              data-kt-element='input'
              placeholder='Type a message'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={onEnterPress}
            ></textarea>  


            <div className="">
              <button
                className='btn btn-bg-primary'
                type='button'
                data-kt-element='send'
                onClick={sendMessage}
                disabled={store.id === 0 }
                
              >
                <span style={{ color: "white" }}>
              <KTIcon  iconName="send" className="fs-2 text-white" />
              </span>
              </button>

            </div>
          </div>
        </div>
        {/* )} */}
      </>
    );
  };
