// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyA5MUFeBp9tnKZdf34mD7-MXoygkW29oYg",
  authDomain: "b2u-adol.firebaseapp.com",
  projectId: "b2u-adol",
  storageBucket: "b2u-adol.appspot.com",
  messagingSenderId: "560269199043",
  appId: "1:560269199043:web:2402427327ba77a495c476",
  measurementId: "G-6YV59LVT4P"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);