import { getMessaging, getToken, } from "firebase/messaging";
import { firebaseConfig } from "../firebaseConfig/firebaseConfig";
import { initializeApp } from "firebase/app";

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.

export const getOrRegisterServiceWorker = () => {
    if (
        "serviceWorker" in navigator &&
        typeof window.navigator.serviceWorker !== "undefined"
    ) {
        return window.navigator.serviceWorker
            .getRegistration("/firebase-push-notification-scope")
            .then((serviceWorker) => {
                if (serviceWorker) return serviceWorker;
                return window.navigator.serviceWorker.register(
                    "/firebase-messaging-sw.js",
                    {
                        scope: "/firebase-push-notification-scope",
                    }
                );
            });
    }
    throw new Error("The browser doesn`t support service worker.");
};

async function getFcmToken() {
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    try {
        const serviceWorker = await getOrRegisterServiceWorker()
        console.log("service Worker", serviceWorker)
        const getTok = await getToken(messaging, { vapidKey: 'BD29X0-IYpkjlA3emH4pS1uYinZueFFf_EJVaUPsQlYIpsN5Lq6kiQllCUVb6k9evlGlBzv5MH-um873G6lR5P0', serviceWorker })
        if (getTok) {
            console.log("currentFcm", getTok)
            // Send the token to your server and update the UI if necessary
            // ...
            return getTok
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
        }
    }
    catch (err) {
        console.log('An error occurred while retrieving token. ', err);
        // ...
    };
}

export { getFcmToken }