/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { redTypes } from "../../../constants/reduxTypes";
import moment from "moment";
import { IApiKeys, IStores } from "../../../types/commonTypes";
import { CreateApiKeys } from "./createApiKeys";
import { ErrorBoundaryApiKeys } from "./ErrorBoundary";
import { Modal } from "react-bootstrap";
import ReactPaginate from 'react-paginate';
import "./index.css";


type Props = {
  className: string;
};

const ApiKeys: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiKeyModal, setApiKeyModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [data, setData] = useState<number>();
  const [currentPages, setCurrentPages] = useState<number>(0)
  const [pageCount, setPageCount] = useState<number>(0)
  const [totalData, setTotalData] = useState<number>(0)


  // console.log("data", data);

  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const apiKeysSuccess: any = useSelector<any>(
    (state) => state.billListReducer?.apiKeysSuccess
  );
   console.log("apiKeysSuccess", apiKeysSuccess);
  const keysGenerateSuccess: any = useSelector<any>(
    (state) => state.billListReducer?.keysGenerateSuccess
  );
  const errorApiKeys: any = useSelector<any>(
    (state) => state.billListReducer?.errorApiKeys
  );
  //   // console.log("errorStore", errorStore);
  const keysGenerateDelete: any = useSelector<any>(
    (state) => state.billListReducer?.keysGenerateDelete
  );
  // console.log('delete',keysGenerateDelete) 
  React.useEffect(() => {
    dispatch({
      type: redTypes.API_KEYS_REQUEST,
      payload: {id:loginSuccess.id,currentPages:currentPages},
    });
  }, [keysGenerateSuccess,keysGenerateDelete,currentPages]);
  const handleClose = () => {
    setApiKeyModal(false);
    setShowModal(false);
     setData(undefined)
  };

  const handleCreateButton = () => {
    setApiKeyModal(true);

  };
  const handleDeleteData = (val: number) => {
    setShowModal(true);
    setData(val);
    // dispatch({
    //   type: redTypes.DELETE_API_KEYS,
    //   payload: val
    // });
  };
 

  const deletePopUp = () => {
    dispatch({
      type: redTypes.DELETE_API_KEYS,
      payload: data
    });
  };
  React.useEffect( () => {
    // console.log('useEffect',typeof(keysGenerateDelete) )
    if(keysGenerateDelete === true){
        handleClose()
        dispatch({
          type: redTypes.CLEAR_APIKEYS_STATE
        }); 
    }
     
  }, [keysGenerateDelete]);
  React.useEffect(() => {
    const perPage = apiKeysSuccess.count
          setTotalData(perPage)
          const total = Math.ceil(perPage / 10)
          setPageCount(total)
  }, [apiKeysSuccess]);
  const handlePageClick = (e:any) => {
    const selectedPage = e.selected * 1;
    console.log("selectedPage", selectedPage)
    setCurrentPages(selectedPage)
  };
  // React.useEffect(() => {
  //   if(keysGenerateSuccess){
  //   dispatch({
  //     type: redTypes.CLEAR_APIKEYS_STATE
  //   }); 
  // }
  // }, []);
  return (
    <>
      {apiKeyModal ? (
        <CreateApiKeys show={apiKeyModal} handleClose={handleClose} />
      ) : null}
      {errorApiKeys ? (
        <ErrorBoundaryApiKeys />
      ) : (
        <>
        <div className="d-flex flex-direction-row justify-content-between px-3">
        <div className=" align-items-start d-flex flex-direction-row px-2 mb-2"style={{gap:15,color:'#009ef7',fontSize:'24px'}}>
                {/* <h3 className="card-title align-items-start flex-row"style={{gap:5}}> */}
                <KTIcon iconName="key" className="fs-1 mt-2" />
                  <span className="card-label fw-bold">
                  Api Keys
                  </span>
                {/* </h3> */}
               
              </div>
              <div className=" d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn mb-4"
                    style={{ backgroundColor: "#c2e5f9", color: "#009ef7" }}
                    onClick={handleCreateButton}
              >
                <KTIcon iconName="plus" className="fs-3" />
                 Generate New key
                  </button>
                </div>
                </div>
        <div className="card mb-5 mb-xl-10">
          {/* begin::Header */}
          {/* <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-row"style={{gap:5}}>
            <KTIcon iconName="key" className="fs-1" />
              <span className="card-label fw-bold fs-3 mb-1">Api Keys</span>
            </h3>
            <div className="card-toolbar"> */}
             
              {/* <button type='button' className='btn'
            style={{backgroundColor:'#f1faff',color:'#009ef7'}}
            // onClick={(e) => handleDownloadClick()}
            >
                  {data && (
                      <a style={{ textDecoration: 'none' }} 
                      href={`data:text/csv;charset=utf-8,${escape(data)}`}
                      download="transaction.csv"  > 
                          <KTIcon iconName='cloud-download' className='fs-2'  />
                      Download
                      </a>
                    )} 
      </button> */}
              {/* <button
                type="button"
                className="btn"
                style={{ backgroundColor: "#f1faff", color: "#009ef7" }}
                onClick={handleCreateButton}
              >
                <KTIcon iconName="plus" className="fs-3" />
                 Generate New key
              </button>
            </div>
          </div> */}

          <div className="card-body py-3">
            {/* begin::Table container */}
            <div className="table-responsive">
              {/* begin::Table */}
              <table className="table table-row-dashed table-row-gray-300 table-hover gs-0 gy-3">
                {/* begin::Table head */}
                <thead>
                  <tr className="fw-bold fs-4"
                        style={{ color: "#454548" }}>
                    <th className="text-center">#</th>
                    <th className="text-center">Access key</th>
                    <th className="text-center">Created At</th>
                    <th className="text-center">Valid At</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {!apiKeysSuccess.getKey ? (
                    <div
                      className=" fw-bold fs-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 105,
                      }}
                    >
                      Data not found!!
                    </div>
                  ) : (
                    <>
                      {apiKeysSuccess.getKey?.map((val: IApiKeys, index: any) => {
                        return (
                          <tr key={index}className=" fs-6 text-center">
                            <td className="">
                              {val.id}
                            </td>
                            <td className="">
                              {val.merchant_access_key}
                            </td>

                            <td className="">
                              {moment(val.createdAt).format(
                                "MMM DD YYYY h:mm A"
                              )}
                            </td>
                            <td className="">
                              {moment(val.validAt).format("MMM DD YYYY h:mm A")}
                            </td>
                            <td className="">
                              {/* <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTIcon iconName='switch' className='fs-3' />
                  </a>
                  <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTIcon iconName='pencil' className='fs-3' />
                  </a> */}
                              <a
                                href="#"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"style={{color:'red',backgroundColor:'#f3e7e6'}}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                data-bs-trigger="hover"
                                title="Delete"
                                onClick={() => {
                                  handleDeleteData(val.id);
                                }}
                              >
                                <KTIcon iconName="trash" className="fs-3" />
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
          <div className="card-footer border-0 pt-5  d-flex justify-content-between">
           <div className=" ">
            <span className="fs-3"> Total - {totalData}</span>
            <span className='text-dark px-1 fs-6 lh-0'>keys</span>
           </div>
           <div className="d-flex px-15 "style={{color:''}}>
           <ReactPaginate
            previousLabel={ <span
            
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Previous"
            >
               <KTIcon iconName='arrow-left' className='fs-1 fw-bold' /> </span>}
            nextLabel={<span
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Next"
            >
            <KTIcon iconName='arrow-right' className='fs-1 fw-bold' /></span>}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel={"..."}
            breakClassName="break-me"
            breakLinkClassName="page-link"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
            />
           </div>
          </div>
        </div>
      </>
      )}
      {showModal ? (
        <Modal
          id="kt_modal_create_app"
          tabIndex={-1}
          aria-hidden="true"
          dialogClassName="modal-dialog modal-dialog-centered mw-350px"
          show={showModal}
          onHide={handleClose}
          backdrop={true}
        >
          <div className="modal-title fs-6 fw-bold d-flex justify-content-center py-5">
            <span style={{ paddingTop: 8 }}> Are you sure want to Delete?</span>
          </div>
          <div className="modal-body d-flex justify-content-center fw-semibold py-2 mb-4"style={{gap:5}}>
            <a
              href="#"
              className="btn btn-bg-primary"
              style={{ color: 'white' }}
              onClick={deletePopUp}
            >
              Yes
            </a>
            <a
              href="#"
              className="btn btn-bg-secondary"
              style={{ color:"#7E8299"  }}
              onClick={handleClose}
            >
              No
            </a>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export { ApiKeys };
