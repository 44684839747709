/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { redTypes } from "../../../constants/reduxTypes";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { CreateOffers } from "./createOffers";
import {  Ioffers } from "../../../types/commonTypes";
import { ErrorBoundaryOffers } from "./ErrorBoundary";
import ReactPaginate from 'react-paginate';
import "./index.css";

type Props = {
  className: string;
};

const Offers: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [storeDatas, setStoreDatas] = useState();
  // console.log("storeDatas", storeDatas);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [offerUpdateModal, setofferUpdateModal] = useState<boolean>(false);
  const [data, setData] = useState<Ioffers>();
  const [deleteData, setDeleteData] = useState<number>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [currentPages, setCurrentPages] = useState<number>(0)
  const [pageCount, setPageCount] = useState<number>(0)
  const [totalData, setTotalData] = useState<number>(0)


  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const storeData: any = useSelector<any>(
    (state) => state.billListReducer?.storeSuccess
  );
  const customerDetailData: any = useSelector<any>(
    (state) => state.billListReducer?.customerOverall
  );
  const offerSuccess: any = useSelector<any>(
    (state) => state.billListReducer?.offerSuccess
  );
  //console.log('offerSuccess',offerSuccess);
  
  const offersCreationSuccess: any = useSelector<any>(
    (state) => state.billListReducer?.offersCreationSuccess
  );
  const errorOffers: any = useSelector<any>(
    (state) => state.billListReducer?.errorOffers
  );
  const offersDelete: any = useSelector<any>(
    (state) => state.billListReducer?.offersDelete
  );
  React.useEffect(() => {
    dispatch({
      type: redTypes.OFFERS_REQUEST,
      payload: {domain: loginSuccess.domain,currentPages:currentPages},
    });
  }, [offersCreationSuccess,currentPages,offersDelete]);
  const handleClose = () => {
    setShowModal(false);
    setofferUpdateModal(false);
    setDeleteModal(false)
  };

  const handleCreateButton = () => {
    setShowModal(true);
  };
  const handleUpdate = (row: Ioffers) => {
    setofferUpdateModal(true);
    setData(row);
  };
  const handleDeleteData = (val: number) => {
    setDeleteModal(true);
    setDeleteData(val);
  };
 

  const deletePopUp = () => {
    dispatch({
      type: redTypes.DELETE_OFFERS_REQUEST,
      payload: {domain: loginSuccess.domain,id:deleteData}
    });
  };
  React.useEffect( () => {
    // console.log('useEffect',typeof(keysGenerateDelete) )
    if(offersDelete === true){
        handleClose()
        dispatch({
          type: redTypes.CLEAR_OFFERS_CREATION
        }); 
    }
     
  }, [offersDelete]);
  React.useEffect(() => {
    const perPage = offerSuccess.count
          setTotalData(perPage)
          const total = Math.ceil(perPage / 10)
          setPageCount(total)
  }, [offerSuccess]);
  const handlePageClick = (e:any) => {
    const selectedPage = e.selected * 1;
    console.log("selectedPage", selectedPage)
    setCurrentPages(selectedPage)
  };
  return (
    <>
       <div className="d-flex flex-direction-row justify-content-between px-3">
        <div className=" align-items-start d-flex flex-direction-row px-2 mb-2"style={{gap:15,color:'#009ef7',fontSize:'24px'}}>
                {/* <h3 className="card-title align-items-start flex-row"style={{gap:5}}> */}
                <KTIcon iconName="abstract-26" className="fs-1 mt-2" />
                  <span className="card-label fw-bold">
                  Offers
                  </span>
                {/* </h3> */}
               
              </div>
              <div className=" d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn mb-4"
                    style={{ backgroundColor: "#c2e5f9", color: "#009ef7" }}
                    onClick={handleCreateButton}
                    >
                      <KTIcon iconName="plus" className="fs-3" />
                      New Offers
                  </button>
                </div>
                </div>
         {showModal ? (
        <CreateOffers show={showModal} handleClose={handleClose} />
      ) : null}
      {offerUpdateModal ? (
        <CreateOffers show={offerUpdateModal} data={data} handleClose={handleClose} />
      ) : null}
      {errorOffers ? (
        <ErrorBoundaryOffers />
      ) : (
        <>
        <div className="card mb-5 mb-xl-10">
          {/* begin::Header */}
          {/* <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-row"style={{gap:5}}>
            <KTIcon iconName="abstract-26" className="fs-1" />
              <span className="card-label fw-bold fs-3 mb-1">Offers</span>
            </h3>
            <div className="card-toolbar"style={{gap:8}}> */}
              {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button> */}
              {/* <select 
              className='form-select form-select-solid fw-bolder'style={{width:130,backgroundColor:'#f1faff',color:'#009ef7'}}
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              // onChange={(e) => setCreateDate(e.target.value)}
              // onClick={(e) => handleDownloadClick()}
              // value={createDate}
            >
              <option value=''>Store</option>
              {storeData &&
         storeData.map((survey:IStores) => (
            <option key={survey.name} value={survey.id}>
                {survey.name}
            </option>
        ))
     }
            </select>
            <select 
              className='form-select form-select-solid fw-bolder'style={{width:130,backgroundColor:'#f1faff',color:'#009ef7'}}
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              // onChange={(e) => setCreateDate(e.target.value)}
              // onClick={(e) => handleDownloadClick()}
              // value={createDate}
            >
              <option value=''>Customer</option>
              {customerDetailData &&
         customerDetailData.map((survey:ICustomerOverallDetail) => (
            <option key={survey.user?.firstname} value={survey.user?.id}>
                {survey.user?.firstname}
            </option>
            ))
        }
            </select> */}
              {/* <button
                type="button"
                className="btn"
                style={{ backgroundColor: "#f1faff", color: "#009ef7" }}
                onClick={handleCreateButton}
              >
                <KTIcon iconName="plus" className="fs-3" />
                New Offers
              </button>
            </div>
          </div> */}

          <div className="card-body py-3">
            {/* begin::Table container */}
            <div className="table-responsive">
              {/* begin::Table */}
              <table className="table table-row-dashed table-row-gray-300 table-hover gs-0 gy-3">
                {/* begin::Table head */}
                <thead>
                  <tr className="fw-bold fs-4"
                        style={{ color: "#454548" }}>
                    <th className="text-center">#</th>
                    <th className="text-center">Offer Title</th>
                    <th className="text-center">Offer Description</th>
                    {/* <th className="text-center">Store</th>
                    <th className="text-center">Customer</th> */}
                    <th className="text-center">Valid Till</th>
                    <th className="text-center">Offer Code</th>
                    {/* <th className="text-center">Created By</th> */}
                    {/* <th className="text-center">UpdateBy</th> */}
                    <th className="text-center">Created At</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {!offerSuccess.getOffers? (
                    <div
                      className=" fw-bold fs-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 105,
                      }}
                    >
                      Data not found!!
                    </div>
                  ) : (
                    <>
                      {offerSuccess &&
                      offerSuccess.getOffers?.map(
                        (val:Ioffers, index: any) => {
                        return (
                          <tr key={index}className="text-center fs-6">
                            <td className="">
                              {val.id}
                            </td>
                            <td className="">
                               {val.offer_title} 
                            </td>
                            <td className="">
                               {val.offer_description}
                            </td>
                            {/* <td className="">
                              {val.store_id}
                            </td>
                            <td className="">
                              {val.customer_id}
                            </td> */}
                            <td className="">
                              {moment(val.valid_till).format(
                                "YYYY MMM DD Thh:mm"
                              )}
                            </td>
                            <td className="">
                               {val.offer_code} 
                            </td>
                            {/* <td className="">
                             {val.created_by} 
                            </td> */}
                            {/* <td className="">
                             {val.updated_by} 
                            </td> */}
                            <td className="">
                              {moment(val.createdat).format("MMM DD YYYY h:mm A")}
                            </td>
                            <td className="">
                              {/* <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTIcon iconName='switch' className='fs-3' />
                  </a>*/}
                             <a
                                        href="#"
                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"style={{color:'green',backgroundColor:'#d4ebde'}}
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Edit"
                                        onClick={() => handleUpdate(val)}
                                      >
                    <KTIcon iconName='pencil' className='fs-3' />
                  </a> 
                              <a
                                href="#"
                                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"style={{color:'red',backgroundColor:'#f3e7e6'}}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                data-bs-trigger="hover"
                                title="Delete"
                                onClick={() => {
                                  handleDeleteData(val.id);
                                }}
                              >
                                <KTIcon iconName="trash" className="fs-3" />
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
          <div className="card-footer border-0 pt-5  d-flex justify-content-between">
           <div className=" ">
            <span className="fs-3"> Total - {totalData}</span>
            <span className='text-dark px-1 fs-6 lh-0'>Offers</span>
           </div>
           <div className="d-flex px-15 "style={{color:'black'}}>
           <ReactPaginate
            previousLabel={ <span
            
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Previous"
            >
               <KTIcon iconName='arrow-left' className='fs-1 fw-bold' /> </span>}
            nextLabel={<span
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Next"
            >
            <KTIcon iconName='arrow-right' className='fs-1 fw-bold' /></span>}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel={"..."}
            breakClassName="break-me"
            breakLinkClassName="page-link"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
            />
           </div>
          </div>
        </div>
        </>
      )} 
      {deleteModal ? (
        <Modal
          id="kt_modal_create_app"
          tabIndex={-1}
          aria-hidden="true"
          dialogClassName="modal-dialog modal-dialog-centered mw-350px"
          show={deleteModal}
          onHide={handleClose}
          backdrop={true}
        >
          <div className="modal-title fs-6 fw-bold d-flex justify-content-center py-5">
            <span style={{ paddingTop: 8 }}> Are you sure want to Delete?</span>
          </div>
          <div className="modal-body d-flex justify-content-center fw-semibold py-2 mb-4"style={{gap:5}}>
            <a
              href="#"
              className="btn btn-bg-primary"
              style={{ color: 'white' }}
              onClick={deletePopUp}
            >
              Yes
            </a>
            <a
              href="#"
              className="btn btn-bg-secondary"
              style={{ color:"#7E8299"  }}
              onClick={handleClose}
            >
              No
            </a>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export { Offers };
