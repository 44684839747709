export const redTypes = {
    SIGNUP_REQUEST:' SIGNUP_REQUEST',
    SIGNUP_SUCCESS:'SIGNUP_SUCCESS',
    SIGNUP_FAILURE:"SIGNUP_FAILURE",
    SIGN_OUT_SUCCESS:"SIGN_OUT_SUCCESS",
    CODE_VERIFY_REQUEST:"CODE_VERIFY_REQUEST",
    CODE_VERIFY_SUCCESS:"CODE_VERIFY_SUCCESS",
    CODE_VERIFY_FAILURE:"CODE_VERIFY_FAILURE",
    MERCHANTS_CREATE_REQUEST:'MERCHANTS_CREATE_REQUEST',
    MERCHANTS_CREATE_PROFILE_SUCCESS:"MERCHANTS_CREATE_PROFILE_SUCCESS",
    MERCHANTS_CREATE_PROFILE_FAILURE:"MERCHANTS_CREATE_PROFILE_FAILURE",
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS:'LOGIN_SUCCESS',
    LOGIN_FAILURE:'LOGIN_FAILURE',
    CLEAR_ERROR:'CLEAR_ERROR',
    CLEAR_MERCHANT_LOGIN_STATE:'CLEAR_MERCHANT_LOGIN_STATE',
    ADMIN_LOGIN_REQUEST: 'ADMIN_LOGIN_REQUEST',
    ADMIN_LOGIN_SUCCESS:'ADMIN_LOGIN_SUCCESS',
    ADMIN_LOGIN_FAILURE:'ADMIN_LOGIN_FAILURE',
    CLEAR_ADMIN_LOGIN_STATE:'CLEAR_ADMIN_LOGIN_STATE',
    LOGOUT: 'LOGOUT',
    BILL_LIST_TABLE_REQUEST:'BILL_LIST_TABLE_REQUEST',
    BILL_LIST_TABLE_SUCCESS:'BILL_LIST_TABLE_SUCCESS',
    BILL_LIST_TABLE_FAILURE:'BILL_LIST_TABLE_FAILURE',
    BILLS_INVIOCE_REQUEST:'BILLS_INVIOCE_REQUEST',
    BILLS_INVIOCE_SUCCESS:'BILLS_INVIOCE_SUCCESS',
    BILLS_INVIOCE_FAILURE:'BILLS_INVIOCE_FAILURE',
    SET_STATE:'SET_STATE',
    RESET_SET_STATE:"RESET_SET_STATE",
    CUSTOMER_DETAIL_REQUEST:'CUSTOMER_DETAIL_REQUEST',
    CUSTOMER_DETAIL_SUCCESS:'CUSTOMER_DETAIL_SUCCESS',
    CUSTOMER_DETAIL_FAILURE:'CUSTOMER_DETAIL_FAILURE',
    RESET_CUSTOMER_DATA:'RESET_CUSTOMER_DATA',
    ADMIN_USER_REQUEST:'ADMIN_USER_REQUEST',
    ADMIN_USER_SUCCESS:'ADMIN_USER_SUCCESS',
    ADMIN_USER_FALIURE:'ADMIN_USER_FALIURE',
    CUSTOMER_OVERALL_DETAIL_REQUEST:'CUSTOMER_OVERALL_DETAIL_REQUEST',
    CUSTOMER_OVERALL_DETAIL_SUCCESS:'CUSTOMER_OVERALL_DETAIL_SUCCESS',
    CUSTOMER_OVERALL_DETAIL_FAILURE:'CUSTOMER_OVERALL_DETAIL_FAILURE',
    CUSTOMER_SET_STATE:'CUSTOMER_SET_STATE',
    ADMIN_USER_CREATION_REQUEST:'ADMIN_USER_CREATION_REQUEST',
    ADMIN_USER_CREATION_SUCCESS:'ADMIN_USER_CREATION_SUCCESS',
    ADMIN_USER_CREATION_FAILURE:'ADMIN_USER_CREATION_FAILURE',
    RESET_ADMIN_USER:'RESET_ADMIN_USER',
    ROLE_REQUEST:'ROLE_REQUEST',
    ROLE_SUCCESS:'ROLE_SUCCESS',
    ROLE_FAILURE:'ROLE_FAILURE',
    GET_ROLES:'GET_ROLES',
    GET_ROlES_SUCCESSFULLY:'GET_ROlES_SUCCESSFULLY',
    GET_ROLES_FAILURE:'GET_ROLES_FAILURE',
    ADD_ROLES:'ADD_ROLES',
    ADD_ROLES_SUCCESSFULLY:'ADD_ROLES_SUCCESSFULLY',
    CLEAR_ROLES:'CLEAR_ROLES',
    DELETE_ROLES:'DELETE_ROLES',
    DELETE_ROLE_SUCCESSFULLY:'DELETE_ROLES_SUCCESSFULLY',
    CHANGE_PASSWORD_WITH_EMAIL:'CHANGE_PASSWORD_WITH_EMAIL',
    CHANGE_PASSWORD_WITH_EMAIL_SUCCESS:'CHANGE_PASSWORD_WITH_EMAIL_SUCCESS',
    CLEAR_CHANGE_PASSWORD_WITH_EMAIL:' CLEAR_CHANGE_PASSWORD_WITH_EMAIL',
    CHANGE_PASSWORD_WITH_OTP:'CHANGE_PASSWORD_WITH_OTP',
    CHANGE_PASSWORD_WITH_OTP_SUCCESS:'CHANGE_PASSWORD_WITH_OTP_SUCCESS',
    CLEAR_PASSWORD_WITH_OTP:'CLEAR_PASSWORD_WITH_OTP',
    GET_MERCHANT:'GET_MERCHANT',
    GET_MERCHANT_SUCCESSFULLY:'GET_MERCHANT_SUCCESSFULLY',
    UPDATE_ADMIN_USERS_SUCCESSFULLY:'UPDATE_ADMIN_USERS_SUCCESSFULLY',
    UPDATE_ADMIN_USERS:'UPDATE_ADMIN_USERS',
    CLEAR_UPDATE_ADMIN_USERS:'CLEAR_UPDATE_ADMIN_USERS',
    ADMIN_USER_GET_SUCCESS:'ADMIN_USER_GET_SUCCESS',
    CUSTOMER_BILLS_DETAILS_REQUEST:'CUSTOMER_BILLS_DETAILS_REQUEST',
    CUSTOMER_BILLS_DETAILS_SUCCESS:'CUSTOMER_BILLS_DETAILS_SUCCESS',
    CUSTOMER_BILLS_DETAILS_FAILURE:'CUSTOMER_BILLS_DETAILS_FAILURE',
    BILLS_DASHBOARD_REQUEST:'BILLS_DASHBOARD_REQUEST',
    BILLS_DASHBOARD_SUCCESS:'BILLS_DASHBOARD_SUCCESS',
    BILLS_DOWNLOAD_REQUEST:'BILLS_DOWNLOAD_REQUEST',
    BILLS_DOWNLOAD_SUCCESS:'BILLS_DOWNLOAD_SUCCESS',
    STORE_REQUEST:'STORE_REQUEST',
    STORE_SUCCESS:'STORE_SUCCESS',
    STORE_FAILURE:'STORE_FAILURE',
    STORE_CREATION_REQUEST:'STORE_CREATION_REQUEST',
    STORE_CREATION_SUCCESS:'STORE_CREATION_SUCCESS',
    STORE_CREATION_FAILURE:'STORE_CREATION_FAILURE',
    IMAGE_UPLOADER_REQUEST:'IMAGE_UPLOADER_REQUEST',
    IMAGE_UPLOADER_SUCCESS:'IMAGE_UPLOADER_SUCCESS',
    IMAGE_UPLOADER_FAILURE:'IMAGE_UPLOADER_FAILURE',
    CLEAR_IMAGE_UPLOADER:'CLEAR_IMAGE_UPLOADER',
    CLEAR_STORE_CREATION:'CLEAR_STORE_CREATION',
    GET_SETTING_REQUEST:'GET_SETTING_REQUEST',
    SETTING_SUCCESS:'SETTING_SUCCESS',
    SETTING_FAILURE:'SETTING_FAILURE',
    SETTING_UPDATION_REQUEST:'SETTING_UPDATION_REQUEST',
    SETTING_UPDATION_SUCCESS:'SETTING_UPDATION_SUCCESS',
    SETTING_UPDATION_FAILURE:'SETTING_UPDATION_FAILURE',
    CLEAR_SETTING_UPDATION:'CLEAR_SETTING_UPDATION',
    CHAT_REQUEST:'CHAT_REQUEST',
    CHAT_SUCCESS:'CHAT_SUCCESS',
    CHAT_FAILURE:'CHAT_FAILURE',
    CHAT_CREATE_REQUEST:'CHAT_CREATE_REQUEST',
    CHAT_CREATE_SUCCESS:'CHAT_CREATE_SUCCESS',
    CHAT_CREATE_FAILURE:'CHAT_CREATE_FAILURE',
    CLEAR_CHAT_CREATION:'CLEAR_CHAT_CREATION',
    API_KEYS_REQUEST:'API_KEYS_REQUEST',
    API_KEYS_SUCCESS:'API_KEYS_SUCCESS',
    API_KEYS_FAILURE:'API_KEYS_FAILURE',
    DELETE_API_KEYS:'DELETE_API_KEYS',
    DELETE_API_KEYS_SUCCESS:'DELETE_API_KEYS_SUCCESS',
    CLEAR_APIKEYS_STATE:'CLEAR_APIKEYS_STATE',
    API_KEYS_GENERATE_REQUEST:'API_KEYS_GENERATE_REQUEST',
    API_KEYS_GENERATE_SUCCESS:'API_KEYS_GENERATE_SUCCESS',
    API_KEYS_GENERATE_FAILURE:'API_KEYS_GENERATE_FAILURE',
    OFFERS_REQUEST:'OFFERS_REQUEST',
    OFFERS_SUCCESS:'OFFERS_SUCCESS',
    OFFERS_FAILURE:'OFFERS_FAILURE',
    OFFERS_CREATION_REQUEST:'OFFERS_CREATION_REQUEST',
    OFFERS_CREATION_SUCCESS:'OFFERS_CREATION_SUCCESS',
    OFFERS_CREATION_FAILURE:'OFFERS_CREATION_FAILURE',
    CLEAR_OFFERS_CREATION:'CLEAR_OFFERS_CREATION',
    DELETE_OFFERS_REQUEST:'DELETE_OFFERS_REQUEST',
    DELETE_OFFERS_SUCCESS:'DELETE_OFFERS_SUCCESS',
    MULTI_IMAGE_UPLOADER_SUCCESS:'MULTI_IMAGE_UPLOADER_SUCCESS',
    GET_GALLERY_IMAGES:'GET_GALLERY_IMAGES',
    GET_GALLERY_SUCCESSFULLY:'GET_GALLERY_SUCCESSFULLY',
    GET_REVIEW_REQUEST:'GET_REVIEW_REQUEST',
    GET_REVIEW_SUCCESSFULLY:"GET_REVIEW_SUCCESSFULLY"
}