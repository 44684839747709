import React, { FC, useState } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useSelector, useDispatch } from "react-redux";
import { redTypes } from "../../../constants/reduxTypes";
import moment from "moment";
import { IAdminUser } from "../../../types/commonTypes";
import { AdminUserPopUp } from "./adminUserPopUp";
import { CreateAdminUser } from "./createAdminUser";
import { useNavigate } from "react-router-dom";
import { ErrorBoundaryAdminUser } from "./ErrorBoundary";
import ReactPaginate from 'react-paginate';
import "./index.css";

export const AdminUser: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [adminModal, setAdminModal] = useState<boolean>(false);
  const [adminUpdateModal, setAdminUpdateModal] = useState<boolean>(false);
  const [data, setData] = useState<IAdminUser>();
  const [currentPages, setCurrentPages] = useState<number>(0)
  const [pageCount, setPageCount] = useState<number>(0)
  const [totalData, setTotalData] = useState<number>(0)

  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  // console.log('AdminUserlOGIN', loginSuccess);
  const adminUserData: any = useSelector<any>(
    (state) => state.billListReducer?.adminSuccess
  );
  const adminCreationSuccess: any = useSelector<any>(state => state.billListReducer?.adminCreationSuccess);

  const errorAdminUser: any = useSelector<any>((state) => state.billListReducer?.errorAdminUser);
  // console.log("errorAdminUser", errorAdminUser);
  const errorCodeAdminUser: any = useSelector<any>(
    (state) => state.billListReducer?.errorCodeAdminUser
  );
  React.useEffect(() => {
    dispatch({
      type: redTypes.ADMIN_USER_REQUEST,
      payload: {domain:loginSuccess.domain,currentPages:currentPages},
    });
  }, [adminCreationSuccess,currentPages]);

  const handleClick = (row: IAdminUser) => {
    setShowModal(true);
    setData(row);
  };

  const handleClose = () => {
    setShowModal(false);
    setAdminModal(false);
    setAdminUpdateModal(false);
  };

  const handleAdminButton = () => {
    setAdminModal(true);
    dispatch({ type: redTypes.ROLE_REQUEST, payload: loginSuccess.domain });
  };

  const handleUpdate = (row: IAdminUser) => {
    dispatch({ type: redTypes.ROLE_REQUEST, payload: loginSuccess.domain });
    setAdminUpdateModal(true);
    setData(row);
  };
  const handleErrorClick = () => {
    dispatch({ type: redTypes.RESET_SET_STATE });
    navigate("/dashboard");
  };
  React.useEffect(() => {
    if(adminUserData){
    const perPage = adminUserData.count
          setTotalData(perPage)
          const total = Math.ceil(perPage / 10)
          setPageCount(total)
    }
  }, [adminUserData]);
  const handlePageClick = (e:any) => {
    const selectedPage = e.selected * 1;
    setCurrentPages(selectedPage)
  };
  return (
    <>
      {showModal ? (
        <AdminUserPopUp
          show={showModal}
          handleClose={handleClose}
          data={data}
        />
      ) : null}
      {adminModal ? (
        <CreateAdminUser show={adminModal} handleClose={handleClose} />
      ) : null}
      {adminUpdateModal ? (
        <CreateAdminUser
          show={adminUpdateModal}
          handleClose={handleClose}
          data={data}
        />
      ) : null}

      {errorAdminUser ? (
        <ErrorBoundaryAdminUser/>
      ) : (
        <>
        <div className="d-flex flex-direction-row justify-content-between px-3">
        <div className=" align-items-start d-flex flex-direction-row px-2 mb-2"style={{gap:15,color:'#009ef7',fontSize:'24px'}}>
                {/* <h3 className="card-title align-items-start flex-row"style={{gap:5}}> */}
                <KTIcon iconName="user-tick" className="fs-1 mt-2" />
                  <span className="card-label fw-bold">
                    Admin Users
                  </span>
                {/* </h3> */}
               
              </div>
              <div className=" d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn mb-4"
                    style={{ backgroundColor: "#c2e5f9", color: "#009ef7" }}
                    onClick={(e) => handleAdminButton()}
                  >
                    <KTIcon iconName="plus" className="fs-3" />
                    Add User
                  </button>
                </div>
                </div>
        <div className="card mb-5 mb-xl-8">
              
              <div className="card-body py-3">
                <div className="table-responsive py-5">
                  <table className="table table-row-dashed table-row-gray-300 table-hover gy-5">
                    <thead>
                      <tr
                        className="fw-bold fs-4"
                        style={{ color: "#454548" }}
                      >
                        <th className="text-center">#</th>
                        <th className="text-center">User Name</th>
                        <th className="text-center">Email</th>
                        <th className="text-center">City</th>
                        <th className="text-center">State</th>
                        <th className="text-center">Country</th>
                        <th className="text-center">Created At</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!adminUserData.getAdimnUser ? (
                        <div
                          className=" fw-bold fs-3"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 105,
                          }}
                        >
                          Data not found!!
                        </div>
                      ) : (
                        <>
                          {adminUserData.getAdimnUser.length > 0 &&
                            adminUserData.getAdimnUser.map((val: IAdminUser) => {
                              // console.log("valuesAdminUser", val);
                              return (
                                <tr className=" fs-6">
                                  <td className="text-center">{val.id}</td>
                                  <td className="text-center ">
                                    {val.username}
                                  </td>
                                  <td className="text-center ">{val.email}</td>
                                  <td className="text-center ">{val.city}</td>
                                  <td className="text-center ">{val.state}</td>
                                  <td className="text-center ">{val.country}</td>
                                  <td className="text-center">
                                    {moment(val.createdAt).format(
                                      "MMM DD YYYY h:mm A"
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <div className="d-flex justify-content-center flex-shrink-0">
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"style={{backgroundColor:'#e8e8eb'}}
                                        data-bs-toggle="tooltip "
                                        title="View"
                                        onClick={(e) => handleClick(val)}
                                      >
                                        <KTIcon
                                          iconName="eye"
                                          className="fs-3"
                                        />
                                      </a>
                                      <a
                                        href="#"
                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"style={{color:'green',backgroundColor:'#d4ebde'}}
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Edit"
                                        onClick={() => handleUpdate(val)}
                                      >
                                        <KTIcon
                                          iconName="pencil"
                                          className="fs-3"
                                        />
                                      </a>
                                      {/* <a
                                        href="#"
                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"style={{color:'red',backgroundColor:'#f3e7e6'}}
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Delete"
                                      >
                                        <KTIcon
                                          iconName="trash"
                                          className="fs-3"
                                        />
                                      </a> */}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer border-0 pt-5  d-flex justify-content-between">
           <div className=" ">
            <span className="fs-3"> Total - {totalData}</span>
            <span className='text-dark px-1 fs-6 lh-0'> users</span>
           </div>
           <div className="d-flex px-15 "style={{color:'black'}}>
           <ReactPaginate
            previousLabel={ <span
            
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Previous"
            >
               <KTIcon iconName='arrow-left' className='fs-1 fw-bold' /> </span>}
            nextLabel={<span
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Next"
            >
            <KTIcon iconName='arrow-right' className='fs-1 fw-bold' /></span>}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel={"..."}
            breakClassName="break-me"
            breakLinkClassName="page-link"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
            />
           </div>
          </div>
            </div>
           </> 
      )}
    </>
  );
};