import { put, takeEvery, select } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";
import { loginRequestApi } from "../../services/apiCalls";
import { ILoginSuccessResponse } from "../../types";

export function* login(param: object) {
  console.log("payloadlogin", param);
  //return true
  try {
    const response: ILoginSuccessResponse = yield loginRequestApi(param);

    if (response?.status === true) {
      localStorage.setItem("currentUserId", JSON.stringify(response.id));
      localStorage.setItem("currentUser", JSON.stringify(response.email));
      localStorage.setItem("currentToken", JSON.stringify(response.token));
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(response.refreshToken)
      );
      yield put({ type: redTypes.LOGIN_SUCCESS, payload: response });
    } else if (response.message) {
      yield put({
        type: redTypes.LOGIN_FAILURE,
        errorMessage: response.message,
      });
    }
    else {
      yield put({
        type: redTypes.LOGIN_FAILURE,
        errorMessage: "Something went wrong",
      });
    }
    // else {
    //   localStorage.setItem("currentUserId", JSON.stringify(response.id));
    //   localStorage.setItem("currentUser", JSON.stringify(response.email));
    //   localStorage.setItem("currentToken", JSON.stringify(response.token));
    //   localStorage.setItem(
    //     "refreshToken",
    //     JSON.stringify(response.refreshToken)
    //   );
    //   yield put({ type: redTypes.LOGIN_SUCCESS, payload: response });
    // }
  } catch (e) {
    yield put({
      type: redTypes.LOGIN_FAILURE,
      errorMessage: "Something went wrong",
    });
  }
}

export default function* loginSaga(): any {
  return yield takeEvery(redTypes.LOGIN_REQUEST, login);
}
