import { FC } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import { useSelector } from "react-redux";

const Error403: FC = () => {
  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  return (
    <>
      <div
        className="d-flex flex-column flex-root"
        // style={{ backgroundColor: "#b5b5c3" }}
      >
        <div className="d-flex flex-column flex-center flex-column-fluid">
          <div className="d-flex flex-column flex-center text-center p-10">
            <div className="card card-flush  w-lg-650px py-5">
              <div className="card-body py-15 py-lg-20">
                {/* begin::Title */}
                <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Error!</h1>
                {/* end::Title */}

                {/* begin::Text */}
                <div className="fw-semibold fs-6 text-gray-500 mb-7">
                  We can't accept in the page.
                </div>
                {/* end::Text */}

                {/* begin::Illustration */}
                <div className="mb-3">
                  <img
                    src={require("../images/error403.png")}
                    className="mw-100 mh-300px theme-light-show"
                    alt=""
                  />
                  {/* <img
          src={require('../../_metronic/images/error403.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        /> */}
                </div>
                {/* end::Illustration */}

                {/* begin::Link */}
                <div className="mb-0">
                    <Link
                      to="/dashboard"
                      className="btn  btn-bg-success"
                      style={{ color: "white" }}
                    >
                      Return Home
                    </Link>
                </div>
                {/* end::Link */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Error403 };
