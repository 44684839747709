import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from "react-redux";
import { redTypes } from "../../../../modules/constants/reduxTypes";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import {FC} from 'react'


type Props = {
    show: boolean
    handleClose: () => void
  }

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required")
  })




const ChangePasswordPopup =({show, handleClose}: Props) =>{
    const dispatch = useDispatch();


    
     // console.log("rolesSuccessfor popup", rolesSuccess);
    //  useEffect(() => {
    //     dispatch({
    //         type: redTypes.CLEAR_PASSWORD_WITH_OTP,
    //     })
        
    //   }, []);
    
       // payload: loginSuccess.domain,
      
   useEffect(() => {
        dispatch({
          type: redTypes.CLEAR_CHANGE_PASSWORD_WITH_EMAIL,
         // payload: loginSuccess.domain,
        });
        
        
      }, []);

      const loginSuccess: any = useSelector<any>(
        (state) => state.authReducer?.loginSuccess
      );
      const sendPassword: any = useSelector<any>(
        (state) => state.billListReducer?.sendPassword
      );
      const sendPasswordOtp: any = useSelector<any>(
        (state) => state.billListReducer?.sendOtp
      );
    //   console.log('sendPassword',sendPassword)
     console.log('sendPasswordOtp',sendPasswordOtp)

      const rolesSuccess: any = useSelector<any>(
        (state) => state.authReducer?.roles
      );

      useEffect(() => {
        
        if(sendPasswordOtp === true){
            console.log('clear')

            handleClose()
            dispatch({
                       type: redTypes.CLEAR_PASSWORD_WITH_OTP,
                   })
        }
    
        
      }, [sendPasswordOtp]);

  
   

    const {
        handleSubmit,
        values,
        handleChange,
        errors,
        touched,
        handleBlur,
        status,
        setStatus,
      } = useFormik({
        initialValues:{
            email:'',
            otp:'',
            password:''
        },
        validationSchema: loginSchema,
        onSubmit: async (values) => {
          console.log("on submit",values);
          if(rolesSuccess){
            if(!sendPassword){
                const data = {
                    email: values.email,
                  };
                  dispatch({
                    type: redTypes.CHANGE_PASSWORD_WITH_EMAIL,
                    payload: {
                        email:values.email,
                        subdomains:loginSuccess.domain
                    },
                  });
              }
              else{
                console.log('values',values)
                dispatch({
                    type: redTypes.CHANGE_PASSWORD_WITH_OTP,
                    payload: {
                        email:values.email,
                        otp:values.otp,
                        password:values.password,
                        subdomains:loginSuccess.domain
                    },
                  });
    
    
              }
          }
          else{
            console.log('this is for merchant chnage password')
            if(!sendPassword){
                const data = {
                    email: values.email,
                  };
                  dispatch({
                    type: redTypes.CHANGE_PASSWORD_WITH_EMAIL,
                    payload: {
                        email:values.email,
                        subdomains:loginSuccess.domain,
                        merchant:true
                    },
                  });
              }
              else{
                console.log('values',values)
                dispatch({
                    type: redTypes.CHANGE_PASSWORD_WITH_OTP,
                    payload: {
                        email:values.email,
                        otp:values.otp,
                        password:values.password,
                        subdomains:loginSuccess.domain,
                        merchant:true
                    },
                  });
    
    
              }
          }
         
         // setLoading(true);
         
          //dispatch(login(data));
          //  setStatus('The login details are incorrect')
        },
      });
  //const [show, setShow] = useState(false);
  const handleClickOpen =( )=>{



  }


  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {sendPassword?
        <>
        <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      OTP
                    </label>
                    <input
                      placeholder="Enter your Otp "
                      name="otp"
                      type="otp"
                      className={clsx(
                        "form-control bg-transparent",
                        // { "is-invalid": touched.otp && errors.email },
                        // {
                        //   "is-valid": touched.email && !errors.email,
                        // }
                      )}
                      value={values.otp}
                      onChange={handleChange}
                      //onBlur={handleBlur}
                      autoComplete="off"
                    />
                    {/* {touched.email && errors.email && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert ">{errors.email}</span>
                        </div>
                      </div>
                    )} */}
        </div>
        <div className="fv-row mb-8">
        <label className="form-label fs-6 fw-bolder text-dark">
         New Password
        </label>
        <input
          placeholder="Enter your new password "
          name="password"
          type="password"
          className={clsx(
            "form-control bg-transparent",
            // { "is-invalid": touched.otp && errors.email },
            // {
            //   "is-valid": touched.email && !errors.email,
            // }
          )}
          value={values.password}
          onChange={handleChange}
          //onBlur={handleBlur}
          autoComplete="off"
        />
        {/* {touched.email && errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert ">{errors.email}</span>
            </div>
          </div>
        )} */}
</div>
</>
        
                  :
                  <div className="fv-row mb-8">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Email
                    </label>
                    <input
                      placeholder="Enter your email "
                      name="email"
                      type="email"
                      className={clsx(
                        "form-control bg-transparent",
                        { "is-invalid": touched.email && errors.email },
                        {
                          "is-valid": touched.email && !errors.email,
                        }
                      )}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    {/* {touched.email && errors.email && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert ">{errors.email}</span>
                        </div>
                      </div>
                    )} */}
                  </div>  }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>{handleSubmit()}}>
            confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export  {ChangePasswordPopup};