// import React from 'react';


// export const Dashboard:React.FC=()=> {
//   return (
//     <div className="">hi
//     </div>
//   );
// }

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { useSelector } from 'react-redux'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
  ChartsWidget1,
  ChartsWidget2,
  ChartsWidget4,
  ChartsWidget3,
  ChartsWidget7,
  ChartsWidget8,
  MixedWidget13,
  MixedWidget14,
  MixedWidget15,
} from '../../../_metronic/partials/widgets'
import { Bills } from './bills'
import { Customers } from './customer'
import { useEffect } from 'react'
import '../firebaseConfig/notificationPermision'
import { requestPermission } from '../firebaseConfig/notificationPermision'
import { getMessaging, onBackgroundMessage } from "firebase/messaging/sw";
import { onMessage } from "firebase/messaging";
import { firebaseConfig } from "../firebaseConfig/firebaseConfig";
import { initializeApp } from "firebase/app";
import { Billschart } from './chartMixWidget13'

const DashboardPage: FC = () => (
  <>
    <div className='row gy-5 g-xl-10'>
      {/*begin::Col*/}
      <div className='col-xl-4'>
        <MixedWidget13
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#F7D9E3'
          chartHeight='100px'
        />
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      <div className='col-xl-4'>
        <MixedWidget14
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#CBF0F4'
          chartHeight='100px'
        />
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      <div className='col-xl-4'>
        <MixedWidget15 className='card-xl-stretch mb-xl-10' backGroundColor='#CBD4F4' />
      </div>
      {/*end::Col*/}
    </div>

    <div className='row gy-5 g-xl-10'>
      {/*begin::Col*/}
      <div className='col-xl-5'>
        <Bills />
      </div>
      <div className='col-xl-7'>
        <Customers />
      </div>
    </div>
    {/* <div className='row gy-5 g-xl-10'>
  
          <Billschart  className='card-xl-stretch mb-xl-10'
          backGroundColor='#F7D9E3'
          /> 
          
          </div>*/}
    {/* <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'> */}
    {/* <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>

      <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex justify-content-start'>
        <CardsWidget20
          className='h-md-100 mb-5 mb-xl-10 w-100'
          description='Active Projects'
          color='#dc7991'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        /> */}
    {/* <CardsWidget7
          className='h-md-50 mb-5 mb-xl-10'
          description='User'
          icon={false}
          stats={4}
          labelColor='dark'
          textColor='gray-300'
        /> */}
    {/* </div>

      <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex justify-content-start'>
        <CardsWidget17 className='h-md-100 mb-5 mb-xl-10 w-md-100' />
      </div>

      <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 d-flex justify-content-start '>
        <MixedWidget8
          className='h-md-100 w-md-100'
          chartColor='success'
          chartHeight='50px'
        />
      </div>
     
    </div> */}
    {/* </div>
    </div> */}
    {/* end::Row */}



    {/* begin::Row */}
    {/* <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <ChartsWidget3 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-6'>
          <ChartsWidget4 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}



    {/* <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <ChartsWidget7 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-6'>
          <ChartsWidget8 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
    {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
      </div>
    </div> */}
    {/* end::Row */}

    <div className='row g-5 gx-xxl-8'>
      {/* <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
       
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div> */}
    </div>
  </>
)

const DashboardWrapper: FC = () => {
  console.log("dashboard")
  // const app = initializeApp(firebaseConfig);
  // const messaging = getMessaging(app);


  // onMessage(messaging, (payload: any) => {
  //   console.log('Message received. ', payload);
  //   // ...
  // });
  return (
    <>
      <div className=" align-items-start d-flex flex-direction-row px-2 mb-1" style={{ gap: 15, color: '#009ef7', fontSize: '24px' }}>
        <KTIcon iconName="element-11" className="fs-1 mt-2" />
        <span className=" fw-bold  ">Dashboard</span>

      </div>
      {/* <div className='mb-3'>
      <span className=' mb-3 px-3 fw-bold fs-6'style={{color: "#A1A5B7"}}>Home-Dashboards</span>
      </div> */}
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <DashboardPage />
      {/* <h3>hii</h3> */}
    </>
  )
}

export { DashboardWrapper }


