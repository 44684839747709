import React, { FC } from "react";
import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { Modal } from "react-bootstrap";
import { StepperComponent } from "../../../../_metronic/assets/ts/components";
import { KTIcon } from "../../../../_metronic/helpers";
import { StoreCreation } from "../../../actions/apiRequests";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ImageSelector from './ImageSelector';
import LoadingIndicator from "./loadingIndicator";
import {
  IAdminUser,
  IAdminUserCreate,
  IStores,
} from "../../../types/commonTypes";
import { redTypes } from "../../../constants/reduxTypes";
import "./index.css";
type Props = {
  show: boolean;
  data?: any;
  handleClose: () => void;
};

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("First name is required"),
  desc: Yup.string().required("Description name is required"),
  address1: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Address Line 1  is required"),
  address2: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Address Line 2  is required"),
});

export const CreateStore = ({ show, handleClose, data }: Props) => {
  // console.log("data", data);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const [uploadedFileName, setUploadedFileName] = useState<any | null>(null);
  const [selectedImages, setSelectedImages] = useState<FileList | null>(null);
  const [loading,setLoading] =useState(false)

  // console.log("uploadedFileName", uploadedFileName);

  const inputRef = useRef<HTMLInputElement | any>(null);

  const handleUpload = () => {
    inputRef.current?.click();
  };
  const handleDisplayFileDetails = () => {
    inputRef.current?.files && setUploadedFileName(inputRef.current.files[0]);
    // const file =  inputRef.current.files[0]
    // console.log('file',file);
  };
  // const errorAdminCreate: any = useSelector<any>((state) => state.billListReducer?.errorAdminCreate);
  // const errorCodeAdminCreate: any = useSelector<any>(
  //   (state) => state.billListReducer?.errorCodeAdminCreate
  // );
  const storeCreationSuccess: any = useSelector<any>(
    (state) => state.billListReducer?.storeCreationSuccess
  );
  //console.log('storeCreationSuccess',storeCreationSuccess)

  const ImageUploadSuccess: any = useSelector<any>(
    (state) => state.billListReducer?.ImageUploadSuccess
  );

  // console.log("ImageUploadSuccess", ImageUploadSuccess);

  React.useEffect(() => {
    dispatch({ type: redTypes.CLEAR_STORE_CREATION });
    dispatch({ type: redTypes.CLEAR_IMAGE_UPLOADER });
  }, [storeCreationSuccess]);

  React.useEffect(() => {
    if (typeof storeCreationSuccess === "object") {
      // console.log("useEffect");
      handleClose();
    }
  }, [storeCreationSuccess]);

  React.useEffect(() => {
    console.log("call image upload");
    if (uploadedFileName) {
      dispatch({
        type: redTypes.IMAGE_UPLOADER_REQUEST,
        payload: uploadedFileName,
      });
    }
  }, [uploadedFileName]);

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };
  const [input, setInput] = useState<IStores>(
    data
      ? data
      : {
          id: 0,
          name: "",
          desc: "",
          address1: "",
          address2: "",
        }
  );
  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    status,
    setStatus,
  } = useFormik({
    initialValues: {
      id: input.id,
      name: input.name,
      desc: input.desc,
      address1: input.address1,
      address2: input.address2,
    },
    validationSchema: registrationSchema,
    onSubmit: async (values) => {
      //  console.log('on submit for admin')
      if (values?.id == 0) {
        console.log("Create");
        const data = {
          id: values.id,
          name: values.name,
          desc: values.desc,
          address1: values.address1,
          address2: values.address2,
          created_by:loginSuccess.id,
          subdomains: loginSuccess.domain,
        };
        {
          loginSuccess.email
            ? dispatch(StoreCreation(data))
            : setStatus("Invalid Email");
        }
      } else {
        console.log("Update");

        const updateData = {
          id: values.id,
          name: values.name,
          desc: values.desc,
          address1: values.address1,
          address2: values.address2,
          updated_by: loginSuccess.id,
          subdomains: loginSuccess.domain,
          url: ImageUploadSuccess,
        };
        dispatch(StoreCreation(updateData));
      }
    },
  });
  // const handleImageSelection = (images: FileList | null) => {
  //   setSelectedImages(images);
  // };
  //   React.useEffect(() => {
  //     console.log("successfully adminCreation !!");
  //     if(adminCreationSuccess){
  //       navigate('/success')
  //       dispatch({ type: redTypes.RESET_SET_STATE });
  //        dispatch({ type: redTypes.RESET_ADMIN_USER  });
  //         }
  //       },[adminCreationSuccess])
  return (
    <>
      <Modal
        id="kt_modal_create_admin"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-500px"style={{}}
        show={show}
        onHide={handleClose}
        onEntered={loadStepper}
        backdrop={true}
      >

  <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
     
      <Tab eventKey="profile"  title = {!data?'Store creation':'Info'}>
      <div className="modal-body py-lg-10 px-lg-10 ">
          <label className=" fs-6 fw-semibold mb-2">Name</label>
          <input
            type="text"
            placeholder="name"
            name="name"
            className={clsx(
              "form-control form-control-solid",
              { "is-invalid": touched.name && errors.name },
              {
                "is-valid": touched.name && !errors.name,
              }
            )}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.name && errors.name && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert ">{errors.name}</span>
              </div>
            </div>
          )}
          <label className=" fs-6 fw-semibold mb-2 mt-2">Description</label>
          <input
            type="text"
            name="desc"
            placeholder="description"
            className={clsx(
              "form-control form-control-solid",
              { "is-invalid": touched.desc && errors.desc },
              {
                "is-valid": touched.desc && !errors.desc,
              }
            )}
            value={values.desc}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.desc && errors.desc && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert ">{errors.desc}</span>
              </div>
            </div>
          )}

          <label className=" fs-6 fw-semibold mb-2 mt-2">Address Line 1</label>
          <input
            type="text"
            placeholder="address1"
            name="address1"
            className={clsx(
              "form-control form-control-solid",
              { "is-invalid": touched.address1 && errors.address1 },
              {
                "is-valid": touched.address1 && !errors.address1,
              }
            )}
            value={values.address1}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.address1 && errors.address1 && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert ">{errors.address1}</span>
              </div>
            </div>
          )}
          <label className=" fs-6 fw-semibold mb-2 mt-2">Address Line 2</label>
          <input
            type="text"
            placeholder="address2"
            name="address2"
            className={clsx(
              "form-control form-control-solid",
              { "is-invalid": touched.address2 && errors.address2 },
              {
                "is-valid": touched.address2 && !errors.address2,
              }
            )}
            value={values.address2}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.address2 && errors.address2 && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert ">{errors.address2}</span>
              </div>
            </div>
          )}

          {data ? (
            <>
              <label className=" fs-6 fw-semibold mb-2 mt-2">
                Logo
              </label>{" "}
              <br></br>
              <div className="wrapper" onClick={handleUpload}>
                <div className="image">
                {uploadedFileName ? (
                  <img
                    src={URL.createObjectURL(uploadedFileName)}
                    className="store-logo "
                    alt="logo"
                  />
                ) : data.url ? (
                  <img src={data.url} className="store-logo" alt="logo" />
                ) : (
                  <img
                    src={require("../../../images/Emptyplaceholder.png")}
                    className="store-logo "
                    alt="logo"
                  />
                )}
                {/* <div className="overlay"> */}
                  <div className="content ">
                    <h6 >Change</h6>
                  {/* </div> */}
            </div>
                <input
                  type="file"
                  name="file"
                  ref={inputRef}
                  onChange={handleDisplayFileDetails}
                  className=""
                  style={{ display: "none" }}
                />
              </div>
              </div>
            </>
          ) : null}
          {/* {errorAdminCreate  &&  (
                      <div className='notice d-flex rounded border-warning border border-dashed p-3'style={{backgroundColor:'#FFF8DD'}}>
                      <span style={{color: "#deb232" }}>
                        <KTIcon iconName='information-5' className='fs-1 me-4' />
                        </span>
                        <div className='d-flex flex-stack flex-grow-1'>
                          <div className='fw-bold'>
                            <div className='fs-6'style={{color:'#b5b5c3'}}>
                           
                            ErrorCode - {errorCodeAdminCreate} : {errorAdminCreate}
                            </div>
                          </div>
                        </div>
                      </div>
                  )} */}
        </div>

        <div className="modal-footer flex-center">
          <button
            onClick={() => handleSubmit()}
            className="btn btn-bg-primary fw-bold"
            style={{ color: "white" }}
          >
            {data ? "Update" : "Create"}
          </button>
          <button
            className="btn fw-bold"
            onClick={handleClose}
            style={{ color: "#7E8299", backgroundColor: "#F9F9F9" }}
          >
            Cancel
          </button>
        </div>
      </Tab>
      <Tab eventKey="gallery" title="Gallery" >
      <div style={{paddingLeft:"10px"}}>
      <h6>Please add upto 4 images to describe your store</h6>
      <ImageSelector data={data}/>
      <div>
        <div style={{height:'10px'}}></div>
       
        {/* {selectedImages && (
          <ul>
            {[...selectedImages].map((image, index) => (
              <li key={index}>{image.name}</li>
            ))}
          </ul>
        )} */}
      </div>
    </div>
      </Tab>
    </Tabs>

       
     
      </Modal>
    </>
  );
};
