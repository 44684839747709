
import React, { FC } from "react";
import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { KTIcon } from "../../../../_metronic/helpers";
import { Tab, Tabs } from "react-bootstrap";
import { StoreHeader } from "./storeHeader";
import { redTypes } from "../../../constants/reduxTypes";
// import { ErrorBoundary } from "./ErrorBoundary";
import clsx from 'clsx'
import {
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
} from '../../../../_metronic/helpers'
import { IChat, IStores, ChatMessage } from "../../../types/commonTypes";



const bufferMessages = defaultMessages

interface chatProps{
  storeId:any;
  userId:any;
}


export const ChatView:FC<chatProps> = ({storeId,userId}) => {
  console.log('storeId in chat',storeId)
  console.log('userId in chat',userId)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  interface Store {
    id: number; // Use the appropriate type for store ID
    name: string; 
   // Use the appropriate type for store name
  }

  const [message, setMessage] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [store, setStore] = useState<Store>({
    id: 0,
    name: 'Store Name'
  });
  const [contents, setContents] = useState<IChat[]>()
  const [customerInfos] = useState<UserInfoModel[]>()
  const currentTimestamp = Date.now();


  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const customerState: any = useSelector<any>(
    (state) => state.billListReducer?.customerState
  );
  console.log("customerData", customerState);

  const storeData: any = useSelector<any>(
    (state) => state.billListReducer?.storeSuccess
  );
  console.log("StoreData:", storeData);

  const chatData: any = useSelector<any>(
    (state) => state.billListReducer?.chatSuccess
  );
console.log('chatData in new view',chatData)
  console.log('chatData',chatData)
  const chatCreateSuccess: any = useSelector<any>(
    (state) => state.billListReducer?.chatCreateSuccess
    );
    console.log("chatCreateSuccess", chatCreateSuccess);

  console.log("Chat Data", chatData);
  console.log("Messsage", message);
  console.log("Store:", store);

  React.useEffect(() => {
    console.log('useefffect called')
    dispatch({
      type: redTypes.CHAT_REQUEST,
      payload: {
        domain: loginSuccess.domain,
        user_id: userId, store_id: storeId
      },
    });
  }, [storeId,userId])

  

  function getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  
  
  const sendMessage = async () => { 
    const randomChatId = getRandomInt(10000, 99999); // Generate a 5-digit random number
    const newMessage = {
      chat_id: randomChatId,
      user_id: userId,
      store_id: storeId,
      message: message,
      attachment_url: '',
      sent_by: "Store",
      read_by_user: true,
      read_by_store: true,
      subdomains: loginSuccess.domain,
    }
    await dispatch({
      type: redTypes.CHAT_CREATE_REQUEST,
      payload: {
        newMessage
      }
    });
  
    setMessage('');
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log("event.target.value", event.target.value);
    let storevalue = JSON.parse(event.target.value);
    setStore(storevalue);


  };


  const handleClick = () => {
    navigate("/customers");
    dispatch({ type: redTypes.RESET_SET_STATE });
  };
  return (
    <>
      {/* <StoreHeader state={{name:"cholai",address1:'coimatore',address2:'saravanampatti'}} /> */}

      {/* {store.id === 0 ? (
        <ErrorBoundary />
      ) : ( */}

      <div >

      

        <div style={{height:'50px'}}>
          {/* {store.id === 0 &&
        //   <ErrorBoundary />            
          } */}
          {chatData?.length === 0 &&
            <div className="text-dark fs-5 fw-bold d-flex justify-content-center align-items-center" style={{display:'flex',justifyContent:'center',alignItems:"center"}}>
            There is no previous chat.
            </div>
          }

          {chatData?.map((data: IChat, index: number) => {

            const state = data.sent_by === 'User' ? 'info' : 'primary'
            const templateAttr = {}
          
            const contentClass = `d-flex justify-content-${data.sent_by === 'User' ? 'start' : 'end'
              } mb-10`
            return (
              <div 
                // key={`message${index}`}
                // {...templateAttr}
              >

          <div className="d-flex flex-row justify-content-start">
            {data.sent_by=='User'&&
                      <img
                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava6-bg.webp"
                        alt="avatar 1"
                        style={{ width: "45px", height: "100%" }}
                      />}
                      {data.sent_by=='User'&&
                      <div>
                        <p
                          className="small p-2 ms-3 mb-1 rounded-3"
                          style={{ backgroundColor: "#f5f6f7" }}
                        >
                          {data.message}
                        </p>
                        <p className="small ms-3 mb-3 rounded-3 text-muted float-end">
                          12:00 PM | Aug 13
                        </p>
                      </div>
          }
                    </div>

                    <div className="d-flex flex-row justify-content-end">

                      {data.sent_by=='Store'&&
                       <div>
                       <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary">
                         {data.message}
                       </p>
                       <p className="small me-3 mb-3 rounded-3 text-muted">
                         12:00 PM | Aug 13
                       </p>
                     </div>}
                     {data.sent_by=='Store'&&
                      <img
                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                        alt="avatar 1"
                        style={{ width: "45px", height: "100%" }}
                      />
        }
                    </div>

                   
                {/* <div
                  className={clsx(
                    'd-flex flex-column align-items',
                    `align-items-${data.sent_by === 'User' ? 'start' : 'end'}`
                  )}
                >
                  <div className='d-flex align-items-center mb-2'>
                    {data.sent_by === 'User' ? (
                      <>
                        <div className='symbol  symbol-35px symbol-circle '>
                          <img alt='Pic' src={require(`../../../images/customer1.png`)} />
                        </div>
                        <div className='ms-3'>
                          <a
                            href='#'
                            className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                          >
                            {customerState.user.firstname}
                          </a>
                          <span className='text-muted fs-7 mb-1'>
                            {moment(data.createdat).isBefore(moment().subtract(24, 'hours')) ?
                              moment(data.createdat).format('MM/DD/YYYY hh:mm A') :
                              moment(data.createdat).fromNow()
                            }</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='me-3'>
                          <span className='text-muted fs-7 mb-1'>
                            {moment(data.createdat).isBefore(moment().subtract(24, 'hours')) ?
                              moment(data.createdat).format('MM/DD/YYYY hh:mm A') :
                              moment(data.createdat).fromNow()
                            }</span>
                          <a
                            href='#'
                            className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                          >
                            {store.name}
                          </a>
                        </div>
                        <div className='symbol  symbol-35px symbol-circle '>
                          <img alt='Store' src={require(`../../../images/shopping_cart.png`)} />
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={clsx(
                      'p-5 rounded',
                      // `bg-light-${state}`,
                      'text-dark mw-lg-400px',
                      `text-${data.sent_by === 'User' ? 'start' : 'end'}`
                    )}
                    data-kt-element='message-text'
                    // dangerouslySetInnerHTML={{ __html: data.message }}
                    style={{
                      backgroundColor: data.sent_by === 'User' ? '#fbfdb6' : '#e4f2f9'
                    }}
                  >
                    {data.message && (
                      <div
                        dangerouslySetInnerHTML={{ __html: data.message }}
                      ></div>
                    )}
                  </div>

                </div> */}
              </div>
            )
          })}
          
        </div>


        {/* <div  style={{display:'flex',flexDirection:'row',justifyContent:'space-between',position:'fixed',bottom:'9%',backgroundColor:'#E4F2F9',width:'-webkit-fill-available'}}>
          <textarea color="green"
            className='form-control form-control-flush mx-3'
            rows={1}
            data-kt-element='input'
            placeholder='Type a message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={onEnterPress}
          ></textarea>  


          <div  >
            <button
              className='btn btn-bg-primary'
              type='button'
              data-kt-element='send'
              onClick={sendMessage}
              disabled={store.id === 0 }
              
            >
              <span style={{ color: "white" }}>
            <KTIcon  iconName="send" className="fs-2 text-white" />
            </span>
            </button>

          </div>
        </div> */}
      </div>
      {/* )} */}
    </>
  );
};
