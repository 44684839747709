import axios from "axios";
import { api } from "../constants/api";
import {
  ICustomerOverallDetail,
  ICustomers,
  IAdminUser,
  IBillListSuccessResponse,
  IAdminUserCreateResponse,
  ICustomerBills,
  IReport,
  IStores,
  ISettings,
  IChat,
  IApiKeys,
  Ioffers,
} from "../types/commonTypes";
import { authHeader } from "../helper/authHeader";
import { useSelector } from "react-redux";

export const billListRequestApi = (
  param: any
): IBillListSuccessResponse | any => {
  async function fetchUser(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;
    const storeId = param.payload.storeId;
    const startDate = param.payload.startDate;
    const endDate = param.payload.endDate;

    if (param.payload.overAll) {
      console.log("billsGet");
      var uploadUrl =
        api.baseUrl +
        api.billList +
        "?" +
        "&subdomains=" +
        param.payload.domain +
        "&currentPages=" +
        param.payload.currentPages +
        "&overAll=" +
        param.payload.overAll;

      const data = await axios
        .get<IBillListSuccessResponse[]>(uploadUrl, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          //console.log('billListapi',response.data);

          return response.data;
        })
        .catch((err) => {
          // console.log('billapiError',err.response.data);
          return err.response.data;
        });
      return data;
    } else if (param.payload.billsNumber) {
      console.log("billsInvioce");

      var uploadUrl =
        api.baseUrl +
        api.billList +
        "?" +
        "&subdomains=" +
        param.payload.domain +
        "&billsNumber=" +
        param.payload.billsNumber;

      const data = await axios
        .get<IBillListSuccessResponse[]>(uploadUrl, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          //console.log('billListapi',response.data);

          return response.data;
        })
        .catch((err) => {
          // console.log('billapiError',err.response.data);
          return err.response.data;
        });
      return data;
    } else if (param.payload.dummyData) {
      console.log("dummyData");
      var uploadUrl =
        api.baseUrl +
        api.billList +
        "?" +
        "&subdomains=" +
        param.payload.domain +
        "&dummyData=" +
        param.payload.dummyData;

      const data = await axios
        .get<IBillListSuccessResponse[]>(uploadUrl, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          //console.log('billListapi',response.data);

          return response.data;
        })
        .catch((err) => {
          // console.log('billapiError',err.response.data);
          return err.response.data;
        });
      return data;
    } else if (storeId && startDate && endDate) {
      console.log("StoresAndDate");

      var uploadUrl =
        api.baseUrl +
        api.billList +
        "?" +
        "&subdomains=" +
        param.payload.domain +
        "&currentPages=" +
        param.payload.currentPages +
        "&store_id=" +
        storeId +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate;

      const data = await axios
        .get<IBillListSuccessResponse[]>(uploadUrl, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          console.log("billListapi", response.data);

          return response.data;
        })
        .catch((err) => {
          // console.log('billapiError',err.response.data);
          return err.response.data;
        });
      return data;
    } else if (storeId && startDate == "" && endDate == "") {
      console.log("store");
      var uploadUrl =
        api.baseUrl +
        api.billList +
        "?" +
        "&subdomains=" +
        param.payload.domain +
        "&currentPages=" +
        param.payload.currentPages +
        "&store_id=" +
        storeId +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate;
      // "&sort=" + true
      // param.payload.sort

      const data = await axios
        .get<IBillListSuccessResponse[]>(uploadUrl, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          // console.log('billListapi',response.data);

          return response.data;
        })
        .catch((err) => {
          // console.log('billapiError',err.response.data);
          return err.response.data;
        });
      return data;
    } else if (startDate && endDate && storeId == "") {
      console.log("endDate");

      var uploadUrl =
        api.baseUrl +
        api.billList +
        "?" +
        "&subdomains=" +
        param.payload.domain +
        "&currentPages=" +
        param.payload.currentPages +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&store_id=" +
        storeId;

      const data = await axios
        .get<IBillListSuccessResponse[]>(uploadUrl, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          // console.log('billListapi',response.data);

          return response.data;
        })
        .catch((err) => {
          // console.log('billapiError',err.response.data);
          return err.response.data;
        });
      return data;
    }
  }
  return fetchUser(param);
};

export const customerRequestApi = (param: any): ICustomers | any => {
  // console.log('customerRequestApi',param.payload)
  async function fetchUser(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;
    var uploadUrl =
      api.baseUrl +
      api.customerDetail +
      "?" +
      "&id=" +
      param.payload.customer_id +
      "&subdomains=" +
      param.payload.domain;
    return axios
      .get<ICustomers[]>(uploadUrl, {
        headers: { Authorization: `Bearer ${CurrentToken}` },
      })
      .then((response) => {
        // console.log('datacustomerApi',response.data)
        // console.log('res',response)
        return response.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  }
  return fetchUser(param);
};

export const adminUserRequestApi = (param: any): IAdminUser | any => {
  async function fetchUser(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;
    if (param.payload.get_admin_user) {
      var getUrl =
        api.baseUrl +
        api.adminUser +
        "?" +
        "&subdomains=" +
        param.payload.subdomains +
        "&id=" +
        param.payload.id +
        "&currentPages=" +
        param.payload.currentPages;
      return axios
        .get<IAdminUser[]>(getUrl, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          // console.log('getinvidividual',response.data)
          return response.data;
        })
        .catch((err) => {
          return err.response.data;
        });
    } else {
      var uploadUrl =
        api.baseUrl +
        api.adminUser +
        "?" +
        "&subdomains=" +
        param.payload.domain +
        "&currentPages=" +
        param.payload.currentPages;
      return axios
        .get<IAdminUser[]>(uploadUrl, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          // console.log('adminUserData',response.data)
          return response.data;
        })
        .catch((err) => {
          return err.response.data;
        });
    }
  }
  return fetchUser(param);
};

export const customerDetailsRequestApi = (
  param: any
): ICustomerOverallDetail | any => {
  async function fetchUser(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;

    if (param.payload.dummyData) {
      console.log("dummyData");

      var uploadUrl =
        api.baseUrl +
        api.customerOverallDetail +
        "?merchant_id=" +
        param.payload.id +
        "&dummyData=" +
        param.payload.dummyData;

      return axios
        .get<ICustomerOverallDetail[]>(uploadUrl, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          // console.log('response',response);
          return response.data;
        })
        .catch((err) => {
          //  console.log('errorRes',err.response);
          return err.response.data;
        });
    } else {
      console.log("getData");
      var uploadUrl =
        api.baseUrl +
        api.customerOverallDetail +
        "?merchant_id=" +
        param.payload.id +
        "&currentPages=" +
        param.payload.currentPages;
      return axios
        .get<ICustomerOverallDetail[]>(uploadUrl, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          //  console.log('response',response);
          return response.data;
        })
        .catch((err) => {
          //  console.log('errorRes',err.response);
          return err.response.data;
        });
    }
  }
  return fetchUser(param);
};

export const adminUserCreationRequestApi = (
  param: any
): IAdminUserCreateResponse | any => {
  async function fetch(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;
    if (param.payload.id == 0) {
      delete param.payload.id;
      var uploadUrl = api.baseUrl + api.adminUser;
      // console.log('uploadUrl',uploadUrl)
      return axios
        .post<IAdminUserCreateResponse[]>(uploadUrl, param.payload, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          //  console.log('adminCreationData',response.data)
          return response.data;
        })
        .catch((err) => {
          return err.response.data;
        });
    } else {
      console.log("update");
      var uploadUrl = api.baseUrl + api.adminUser;
      return axios
        .put<IAdminUserCreateResponse[]>(uploadUrl, param.payload, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          return err.response.data;
        });
    }
  }
  return fetch(param);
};

export const settingUpdationRequestApi = (param: any): ISettings | any => {
  console.log("updateParamServices", param);
  async function fetch(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;
    // var updateUrl = `${api.baseUrl}${api.settings}`;
    var updateUrl = api.baseUrl + api.settings;
    console.log("uploadUrl", updateUrl);

    return axios
      .put<ISettings[]>(updateUrl, param.payload, {
        headers: { Authorization: `Bearer ${CurrentToken}` },
      })
      .then((response) => {
        // console.log('res',response)
        console.log("ISettings Api", response.data);
        return response.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  }
  return fetch(param);
};

export const settingRequestApi = (param: any): ISettings | any => {
  async function fetch(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;

    console.log("setting Get");
    var uploadUrl =
      api.baseUrl + api.settings + "?" + "&subdomains=" + param.payload.domain;
    return axios
      .get<ISettings[]>(uploadUrl, {
        headers: { Authorization: `Bearer ${CurrentToken}` },
      })
      .then((response) => {
        console.log("settingResponse", response);

        return response.data;
      })
      .catch((err) => {
        // console.log('settingError',err.response.data);

        return err.response.data;
      });
  }
  return fetch(param);
};

export const chatRequestApi = (param: any): IChat | any => {
  async function fetch(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;

    console.log("Get Chat", param.payload);
    var uploadUrl =
      api.baseUrl +
      api.chats +
      "?" +
      "&subdomains=" +
      param.payload.domain +
      "&user_id=" +
      param.payload.user_id +
      "&store_id=" +
      param.payload.store_id;
    return axios
      .get<IChat[]>(uploadUrl, {
        headers: { Authorization: `Bearer ${CurrentToken}` },
      })
      .then((response) => {
        console.log("Chat Response", response);
        return response.data;
      })
      .catch((err) => {
        console.log("get chat Error", err.response.data);
        return err.response.data;
      });
  }
  return fetch(param);
};

export const chatCreateApi = (param: any): IChat | any => {
  async function fetch(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;

    console.log("create Chat", param.payload);
    var uploadUrl = api.baseUrl + api.chats;
    return axios
      .post<IChat[]>(uploadUrl, param.payload.newMessage, {
        headers: { Authorization: `Bearer ${CurrentToken}` },
      })
      .then((response) => {
        console.log("Create Chat Response", response);
        return response.data;
      })
      .catch((err) => {
        console.log("get chat Error", err.response.data);
        return err.response.data;
      });
  }
  return fetch(param);
};

export const roleRequestApi = (param: any): IBillListSuccessResponse | any => {
  //  console.log('roleRequestParams',param.payload)
  async function fetch(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;
    var uploadUrl =
      api.baseUrl + api.roles + "?" + "&subdomains=" + param.payload;
    return axios
      .get<any[]>(uploadUrl, {
        headers: { Authorization: `Bearer ${CurrentToken}` },
      })
      .then((response) => {
        //  console.log('roleRequestApi',response.data)
        return response.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  }
  return fetch(param);
};

export const customerBillsApi = (param: any): ICustomerBills | any => {
  async function fetch(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;
    var uploadUrl =
      api.baseUrl +
      api.customerBills +
      "?id=" +
      param.payload.id +
      "&subdomains=" +
      param.payload.domain;
    return axios
      .get<ICustomerBills[]>(uploadUrl, {
        headers: { Authorization: `Bearer ${CurrentToken}` },
      })
      .then((response) => {
        // console.log('responseCustomerBills',response.data);
        return response.data;
      })
      .catch((err) => {
        // console.log('errorRes',err.response);
        return err.response.data;
      });
  }
  return fetch(param);
};

export const billsDashboardApi = (param: any): IReport | any => {
  async function fetch(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;
    if (param.payload.downloadCsv == true) {
      var uploadUrl =
        api.baseUrl +
        api.reportBills +
        "?period=" +
        param.payload.data +
        "&subdomains=" +
        param.payload.domain +
        "&downloadCSV=" +
        param.payload.downloadCsv;
      return axios
        .get<IReport[]>(uploadUrl, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          // console.log('errorRes',err.response);
          return err.response.data;
        });
    } else {
      var uploadUrl =
        api.baseUrl +
        api.reportBills +
        "?period=" +
        param.payload.data +
        "&subdomains=" +
        param.payload.domain +
        "&downloadCSV=" +
        param.payload.downloadCsv;
      // console.log('uploadUrl',uploadUrl);
      return axios
        .get<IReport[]>(uploadUrl, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          // console.log('responseBills',response.data);
          return response.data;
        })
        .catch((err) => {
          // console.log('errorRes',err.response);
          return err.response.data;
        });
    }
  }
  return fetch(param);
};

export const billsDownloadApi = (param: any): IReport | any => {
  async function fetch(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;
    var uploadUrl =
      api.baseUrl +
      api.reportBills +
      "?period=" +
      param.payload.data +
      "&subdomains=" +
      param.payload.domain +
      "&downloadCSV=" +
      param.payload.downloadCsv;
    // console.log('uploadUrl',uploadUrl);
    return axios
      .get<IReport[]>(uploadUrl, {
        headers: { Authorization: `Bearer ${CurrentToken}` },
      })
      .then((response) => {
        // console.log('responseApi',response.data);
        return response.data;
      })
      .catch((err) => {
        // console.log('errorRes',err.response);
        return err.response.data;
      });
  }
  return fetch(param);
};

export const storeRequestApi = (param: any): IStores | any => {
  async function fetch(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;
    if (!param.payload.name) {
      console.log("storeGet");
      var uploadUrl =  !param.payload.chat?
        api.baseUrl +
        api.stores +
        "?" +
        "&subdomains=" +
        param.payload.domain +
        "&currentPages=" +
        param.payload.currentPages: api.baseUrl +
        api.stores +
        "?" +
        "&subdomains=" +
        param.payload.domain ;
      return axios
        .get<IStores[]>(uploadUrl, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          // console.log('storeResponse',response);

          return response.data;
        })
        .catch((err) => {
          // console.log('storeError',err.response.data);

          return err.response.data;
        });
    } else if (param.payload.id == 0) {
      console.log("storeCreate");
      delete param.payload.id;
      var uploadUrl = api.baseUrl + api.stores;
      // console.log('storeCreateuploadUrl',uploadUrl);
      return axios
        .post<IStores[]>(uploadUrl, param.payload, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          // console.log('storeCreateResponse',response);

          return response.data;
        })
        .catch((err) => {
          // console.log('storeError',err.response.data);

          return err.response.data;
        });
    } else {
      console.log("storeUpdate");
      var uploadUrl = api.baseUrl + api.stores;
      // console.log('storeCreateuploadUrl',uploadUrl);
      return axios
        .put<IStores[]>(uploadUrl, param.payload, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          // console.log('storeUpdateResponse',response.data);

          return response.data;
        })
        .catch((err) => {
          // console.log('storeError',err.response.data);

          return err.response.data;
        });
    }
  }
  return fetch(param);
};

export const apiKeysApi = (param: any): IApiKeys | any => {
  async function fetch(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;
    if (!param.payload.days) {
      console.log("get");
      var uploadUrl =
        api.baseUrl +
        api.apiKeys +
        "?merchant_id=" +
        param.payload.id +
        "&currentPages=" +
        param.payload.currentPages;
      // console.log('uploadUrl',uploadUrl);

      return axios
        .get<IApiKeys[]>(uploadUrl, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          // console.log('responseApiKeys',response.data);

          return response.data;
        })
        .catch((err) => {
          console.log("errorRes", err.response);
          return err.response.data;
        });
    } else {
      console.log("post");
      //console.log('days',param.payload.days)
      var postUrl =
        api.baseUrl +
        api.apiKeys +
        "?merchant_id=" +
        param.payload.id +
        "&subdomains=" +
        param.payload.domain +
        "&days=" +
        param.payload.days;
      // console.log('postUrl',postUrl)
      return axios
        .post<IApiKeys[]>(postUrl, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          // console.log('postresponseApiKeys',response.data);

          return response.data;
        })
        .catch((err) => {
          console.log("errorRes", err.response);
          return err.response.data;
        });
    }
  }
  return fetch(param);
};

export const apiKeysDeleteApi = (param: any): IApiKeys | any => {
  async function fetch(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;
    var deleteUrl = `${api.baseUrl}${api.apiKeys}` + "?id=" + param.payload;
    console.log("deleteUrl", deleteUrl);

    return axios
      .delete<IApiKeys[]>(deleteUrl, {
        headers: { Authorization: `Bearer ${CurrentToken}` },
      })
      .then((response) => {
        // console.log('res',response)
        console.log("deleteApi", response.data);
        return response.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  }
  return fetch(param);
};

export const offersRequestApi = (param: any): Ioffers | any => {
  async function fetch(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;
    var uploadUrl =
      api.baseUrl +
      api.offers +
      "?subdomains=" +
      param.payload.domain +
      "&currentPages=" +
      param.payload.currentPages;
    //  console.log('uploadUrl',uploadUrl)
    return axios
      .get<Ioffers[]>(uploadUrl, {
        headers: { Authorization: `Bearer ${CurrentToken}` },
      })
      .then((response) => {
        //  console.log('offersData',response.data)
        return response.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  }
  return fetch(param);
};
export const offersCreationRequestApi = (param: any): Ioffers | any => {
  console.log("offersRequestApi", param);

  async function fetch(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;
    if (param.payload.id == 0) {
      console.log("post");
      delete param.payload.id;
      var uploadUrl = api.baseUrl + api.offers;
      //  console.log('uploadUrl',uploadUrl)
      return axios
        .post<Ioffers[]>(uploadUrl, param.payload, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          console.log("offersCreationData", response.data);
          return response.data;
        })
        .catch((err) => {
          return err.response.data;
        });
    } else {
      console.log("update");
      var uploadUrl = api.baseUrl + api.offers;
      return axios
        .put<Ioffers[]>(uploadUrl, param.payload, {
          headers: { Authorization: `Bearer ${CurrentToken}` },
        })
        .then((response) => {
          console.log("offersCreationupdate", response.data);
          return response.data;
        })
        .catch((err) => {
          return err.response.data;
        });
    }
  }
  return fetch(param);
};

export const offersDeleteApi = (param: any): Ioffers | any => {
  console.log("delParam", param);

  async function fetch(param: any) {
    var authToken = await authHeader();
    var CurrentToken = authToken.currentToken;
    var deleteUrl =
      `${api.baseUrl}${api.offers}` +
      "?id=" +
      param.payload.id +
      "&subdomains=" +
      param.payload.domain;
    console.log("deleteUrl", deleteUrl);

    return axios
      .delete<Ioffers[]>(deleteUrl, {
        headers: { Authorization: `Bearer ${CurrentToken}` },
      })
      .then((response) => {
        // console.log('res',response)
        console.log("deleteApi", response.data);
        return response.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  }
  return fetch(param);
};

export const imageUploaderApi = (param: any): any | any => {
  console.log("parammultiple", param);
  if (param.payload?.multiple) {
    console.log("param", param);
    console.log("mutltiple photos uploaded start", param.payload.file);
    const formData = new FormData();
    for (let i = 0; i < param.payload.file.length; i++) {
      formData.append("files", param.payload.file[i]);
    }
    const data = {
      store_id: param.payload.store_id,
      subdomains: param.payload.subdomains,
    };
    formData.append("data", JSON.stringify(data));
    console.log("formData", formData);
    var uploadUrl = api.baseUrl + api.imageUploaderMultiple;
    return axios
      .post<any[]>(uploadUrl, formData)
      .then((response) => {
        console.log("responseApiData", response.data);
        // console.log('responseApi',response);

        return {
          response: response.data,
          multipleUploads: true,
        };
      })
      .catch((err) => {
        // console.log('errorRes',err.response);
        return err.response.data;
      });
  } else {
    async function fetch(param: any) {
      var authToken = await authHeader();
      var CurrentToken = authToken.currentToken;
      var uploadUrl = api.baseUrl + api.imageUploader;

      const formData = new FormData();
      formData.append("file", param.payload);

      return axios
        .post<any[]>(uploadUrl, formData)
        .then((response) => {
          // console.log('responseApiData',response.data);
          // console.log('responseApi',response);

          return response.data;
        })
        .catch((err) => {
          // console.log('errorRes',err.response);
          return err.response.data;
        });
    }
    return fetch(param);
  }
};
