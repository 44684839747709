import { createRoleForApiResponse } from "../../types/commonTypes";
import { createRoleApi } from "../../services/apiCalls";
import { put, takeEvery, call, select } from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";

function* createRolesSaga(param: object) {
//   console.log("createbillParam", param);
  try {
    const response: createRoleForApiResponse = yield createRoleApi(param);
    if (response && !response.message) {
    //   console.log("response", response);
      yield put({ type: redTypes.ADD_ROLES_SUCCESSFULLY, payload: response });
    }else if (response.message) {
        // console.log('billError',response.message);
       yield put({
         type: redTypes.ADMIN_USER_CREATION_FAILURE,
         errorMessage: response.message,
         errorCode: response.errorCode,
       });
      }
      
      else {
        console.log('Something Error');
       yield put({
         type: redTypes.ADMIN_USER_CREATION_FAILURE,
         errorMessage: "Something went wrong",
       });
      }
  } catch (e) {
    console.log("errorForroles", e);
  }
}
export default function* createRoleSaga(): any {
  return yield takeEvery(redTypes.ADD_ROLES, createRolesSaga);
}
