import {getRoles} from '../../types/commonTypes'
import { updateAdminUsersApi } from '../../services/apiCalls'
import {put, takeEvery, call,select} from 'redux-saga/effects';
import {redTypes} from '../../constants/reduxTypes';

function* updateAdminUserSaga(param:object){
    console.log('updateParam',param)
    try{

        const response:getRoles =yield updateAdminUsersApi(param)
        console.log('responseforMerchant',response)
        if(response){
            console.log('response',response)
            yield put({type: redTypes.UPDATE_ADMIN_USERS_SUCCESSFULLY, payload: response});


        }


    }
    catch(e){
        console.log('errorForbills',e)

    }


}
export default function* updateAdminUsersSaga():any {
    return yield takeEvery (redTypes.UPDATE_ADMIN_USERS, updateAdminUserSaga);
 }