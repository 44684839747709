import { FC } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { redTypes } from "../../constants/reduxTypes";

const ErrorBoundary: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
 
  const errorBills: any = useSelector<any>((state) => state.billListReducer?.errorBills);
  // console.log("errorBills", errorBills);
  const errorCode: any = useSelector<any>(
    (state) => state.billListReducer?.errorCodeBills
  );
  const errorOverAllCustomer: any = useSelector<any>(
    (state) => state.billListReducer?.errorOverAllCustomer
  );
  const errorCodeOverAllCustomer: any = useSelector<any>(
    (state) => state.billListReducer?.errorCodeOverAllCustomer
  );
  const handleReturnClick = () => {
    dispatch({ type: redTypes.RESET_SET_STATE });
    window.location.reload();  
  };

  return (
    <>
      <div className="d-flex  justify-content-center align-item-center text-center p-10">
        <div className="card card-flush  w-lg-650px py-5">
          <div className="card-body py-15 py-lg-20">
            {/* begin::Title */}
            <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Error!</h1>
            {/* end::Title */}
            {errorBills ? (
              <div className="fw-semibold fs-4 text-gray-500 mb-5">
              ErrorCode - {errorCode} : {errorBills}
            </div>
            ): errorOverAllCustomer ? (
            <div className="fw-semibold fs-4 text-gray-500 mb-5">
             ErrorCode - {errorCodeOverAllCustomer} : {errorOverAllCustomer}
            </div>
             ) : (
              <div className="fw-semibold fs-4 text-gray-500 mb-5">
                Data not found
              </div>
            )}
            <div className="mb-3">
              <img
                src={require("../../images/error.png")}
                className="mw-50 mh-150px theme-light-show"
                alt=""
              />
            </div>
            <div className="mb-0">
             <button
                type="button"
                className="btn btn-bg-success"
                style={{ color: "white" }}
                onClick={handleReturnClick}
              >
                Return Home
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ErrorBoundary };
