import { getFcmToken } from "./getFcmToken";


async function requestPermission() {
    console.log('Requesting permission...');
    var permission = await Notification.requestPermission()
    if (permission === 'granted') {
        console.log('Notification permission granted.');
        return await getFcmToken();

    }
    else {
        console.log("permission", permission)
        return ""
    }

}

export { requestPermission }