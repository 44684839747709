import React, { useState, useCallback, useEffect } from 'react';
import { render } from 'react-dom';
import ImageViewer from 'react-simple-image-viewer';
import { useSelector, useDispatch } from "react-redux";
import { redTypes } from "../../../constants/reduxTypes";


interface ImageSelectorProps {
    data:any
    image:any
 // onSelectImages: (images: FileList | null) => void;
}
const ImageViewers: React.FC<ImageSelectorProps>=({data,image})=> {
    console.log('propsImage',image)
    console.log('propsData',data)
  const dispatch = useDispatch();
  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );

  const gallery: any = useSelector<any>(
    (state) => state.billListReducer?.gallery
  );
  console.log('gallery',gallery)
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  //const [images,setImages]=useState([])

  useEffect(()=>{

 dispatch({
        type: redTypes.GET_GALLERY_IMAGES,
        payload: {store_id:data.id,subdomains:loginSuccess.domain},
      });
  },[image])
  let imgUrl=[];

  if(gallery.gallery!=null){
    imgUrl = gallery.gallery.split(',');
  }
 
  console.log('imgurl',imgUrl)
//   const images = [
//     'https://bill2u-image-bucket.s3.ap-southeast-1.amazonaws.com/1692686502025IMG20230707200309.jpg',
//     'https://bill2u-image-bucket.s3.ap-southeast-1.amazonaws.com/1692686502111IMG20230707163334.jpg',
//     'https://bill2u-image-bucket.s3.ap-southeast-1.amazonaws.com/1692686502159IMG20230707163408.jpg',
//     'https://bill2u-image-bucket.s3.ap-southeast-1.amazonaws.com/1692686502195IMG20230707200257.jpg',
//   ];

  const openImageViewer = useCallback((index:any) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
    {imgUrl.length>0&&
    <div>
      <div style={{display:'flex',flexDirection:'row'}}>

    
        
     {imgUrl[0]&&   <img
          src={ imgUrl[0] }
          onClick={ () => openImageViewer(0) }
          width="200"
          key={ 0 }
          style={{ margin: '2px' }}
          alt=""
        />}

{imgUrl[1] &&  <img
          src={ imgUrl[1] }
          onClick={ () => openImageViewer(1) }
          width="200"
          key={ 1 }
          style={{ margin: '2px' }}
          alt=""
        />}
        </div>
        <div style={{display:'flex',flexDirection:'row'}}>
        {imgUrl[2] &&   <img
          src={ imgUrl[2] }
          onClick={ () => openImageViewer(2) }
          width="200"
          key={ 2 }
          style={{ margin: '2px' }}
          alt=""
        />}

{imgUrl[3] &&    <img
          src={ imgUrl[3] }
          onClick={ () => openImageViewer(3) }
          width="200"
          key={ 3 }
          style={{ margin: '2px' }}
          alt=""
        />}
        </div> 
       {isViewerOpen && (
         <ImageViewer
          src={ imgUrl }
          currentIndex={ currentImage }
          disableScroll={ false }
          closeOnClickOutside={ true }
           onClose={ closeImageViewer }
         />
      )}
      
    </div>
       }
       </>
  );
}
export default ImageViewers;