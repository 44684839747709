import {put, takeEvery, call,select} from 'redux-saga/effects';
import {redTypes} from '../../constants/reduxTypes';
import { ICustomers } from '../../types/commonTypes';
import {  customerRequestApi } from '../../services/commonApiCall';


function* customerList(param:object) {
  console.log('customerID',param);

try {
   
 const response:ICustomers =yield customerRequestApi(param)
// console.log('responseVerify',response.id);


if (response) {
  // console.log('billnumber',response);
yield put({type: redTypes.CUSTOMER_DETAIL_SUCCESS, payload: response});

}
}  catch (e) {
yield put({
type: redTypes.CUSTOMER_DETAIL_FAILURE,
errorMessage: 'Something went wrong',
});
}

}
export default function* customerListSaga():any {
     return yield takeEvery (redTypes.CUSTOMER_DETAIL_REQUEST, customerList);
  }