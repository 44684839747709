import {put, takeEvery, call,select} from 'redux-saga/effects';
import {redTypes} from '../../constants/reduxTypes';
import { ICustomerOverallDetail } from '../../types/commonTypes';
import {  customerDetailsRequestApi } from '../../services/commonApiCall';


function* customerOverAllDetail(param:object) {

try {
   
 const response:ICustomerOverallDetail =yield customerDetailsRequestApi(param)
//  console.log('responseVerify',response.data);

if (response && !response.message) {
yield put({type: redTypes.CUSTOMER_OVERALL_DETAIL_SUCCESS, payload: response});

}
else if (response.message){
  yield put({
    type: redTypes.CUSTOMER_OVERALL_DETAIL_FAILURE,
    errorMessage:response.message,
    errorCode: response.errorCode,
    });
}
else{
  console.log('Something Error');
  yield put({
    type: redTypes.CUSTOMER_OVERALL_DETAIL_FAILURE,
    errorMessage: 'Something went wrong',
    });
}
}  catch (e) {
yield put({
type: redTypes.CUSTOMER_OVERALL_DETAIL_FAILURE,
errorMessage: 'Something went wrong',
});
}

}
export default function* customerOverAllDetailSaga():any {
     return yield takeEvery (redTypes.CUSTOMER_OVERALL_DETAIL_REQUEST, customerOverAllDetail);
  }