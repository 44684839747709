import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { RotatingLines, ThreeDots } from "react-loader-spinner";
import { IBillListSuccessResponse } from "../../types/commonTypes";
import { KTIcon } from "../../../_metronic/helpers";
import { ErrorBoundary } from "./ErrorBoundary";
import { Billschart } from "./chartMixWidget13";


export const Bills: FC = () => {
    const loginSuccess: any = useSelector<any>(
        (state) => state.authReducer?.loginSuccess
      );
      const loading: any = useSelector<any>(
        (state) => state.billListReducer?.loading
      );
      const billListData: any = useSelector<any>(state => state.billListReducer?.response);
      const errorBills: any = useSelector<any>((state) => state.billListReducer?.errorBills);

    return (
        <>
         {errorBills ? (
       <ErrorBoundary/>
      ) : (
              <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5"> 
              <div className=" align-items-start d-flex flex-direction-row px-2 mb-2"style={{gap:15,color:'#009ef7',fontSize:'24px'}}>
        <KTIcon iconName="document" className="fs-1 mt-2" />
              <span className=" fw-bold  ">Bills</span>
            </div>
            </div>
      <div className="card-body py-1">
         <div className="table-responsive ">
            <table className="table table-row-dashed table-row-gray-300 table-hover  gs-0 gy-5">
            {loading ? (
                    <div
                      className=" fw-bold fs-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap:40
                      }}
                    >   
                    <ThreeDots 
                        height="80" 
                        width="80" 
                        radius="9"
                        color="#ADC4CE" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        visible={true}
                        />
                      Loading...
                    </div>
                  ) : (
                    <>
            <thead>
                  <tr className="fw-bold fs-4" style={{ color: "#454548" ,}}>
                  <th className="text-center">#</th>
                          <th className="text-center">Store</th>
                          <th className="text-center">Bill Number</th>
                          <th className="text-center">currency</th>
                          <th className="text-center">Irn</th>
                          <th className="text-center">Created At</th>
                  </tr>
                </thead>
                <tbody>
                 {!billListData.getBill ? (
                    <div
                      className=" fw-bold fs-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 105,
                      }}
                    >
                      Data not found!!
                    </div>
                  ) : (
                    <>
                      {billListData &&
                        billListData.getBill?.map(
                          (val: IBillListSuccessResponse, index: any) => {
                            // console.log('values1', val)
                            return (
                              <tr key={index}>
                                <td className="text-center fs-6">
                                        {val.id}
                                      </td>
                                      <td className="text-center fs-6">
                                        {val.stores?.name}
                                      </td>
                                      <td className="text-center  fs-6">
                                        {val.bill_number}
                                      </td>
                                      <td className="text-center  fs-6">
                                        {val.currency?val.currency:'null'}
                                      </td>
                                      <td className="text-center  fs-6">
                                        {val.irn}
                                      </td>
                                <td className="text-center fs-6">
                                  {moment(val.createdAt).format(
                                    "MMM DD YYYY h:mm A"
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </>
                  )}
                </tbody>
                </>
                  )}
             </table>
         </div>
         </div>    
        
        </div>

      )}
        </>
    )
}