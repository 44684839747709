/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { redTypes } from '../../../constants/reduxTypes';
import moment from 'moment';
import { IStores } from '../../../types/commonTypes';
import { ErrorBoundaryStore } from './ErrorBoundary';
import { CreateStore } from './createStore';
import ReactPaginate from 'react-paginate';
import "./index.css";
import { ThreeDots } from 'react-loader-spinner';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { Value } from 'sass';

type Props = {
  className: string
}

const Stores: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [storeModal, setStoreModal] = useState<boolean>(false);
    const [storeUpdateModal, setStoreUpdateModal] = useState<boolean>(false);
    const [data, setData] = useState<IStores>();
    const [currentPages, setCurrentPages] = useState<number>(0)
    const [pageCount, setPageCount] = useState<number>(0)
    const [totalData, setTotalData] = useState<number>(0)

    const loginSuccess: any = useSelector<any>(
        (state) => state.authReducer?.loginSuccess
      );
      const loading: any = useSelector<any>(
        (state) => state.billListReducer?.loading
      );
      const storeData: any = useSelector<any>(
        (state) => state.billListReducer?.storeSuccess
      );
   //   console.log('storeData',storeData)
      const storeCreationSuccess: any = useSelector<any>(
        (state) => state.billListReducer?.storeCreationSuccess
      );
      //  console.log('storeData',storeData)
      const errorStore: any = useSelector<any>((state) => state.billListReducer?.errorStore);
      // console.log("errorStore", errorStore);  
      React.useEffect(() => {
        dispatch({
          type: redTypes.STORE_REQUEST,
          payload:{domain:loginSuccess.domain,currentPages:currentPages},
        });
      }, [storeCreationSuccess,currentPages]);
      const handleClose = () => {
        setStoreModal(false);
        setStoreUpdateModal(false)
      };
      const handleUpdate = (row: IStores) => {
        // dispatch({ type:'' });
        setStoreUpdateModal(true);
        setData(row);
      };
      const handleAdminButton = () => {
        setStoreModal(true);
      };
      React.useEffect(() => {
        if(storeData){
        const perPage = storeData.count
              setTotalData(perPage)
              const total = Math.ceil(perPage / 10)
              setPageCount(total)
        }
      }, [storeData]);
      const handlePageClick = (e:any) => {
        const selectedPage = e.selected * 1;
        setCurrentPages(selectedPage)
      };

      const handleNavigate=(val:any)=>{
         console.log('reviewStoreData',val)
         val.review =true
         navigate("/stores/view",{state:val});
      }
  return (
    <>
         {storeModal ? (
        <CreateStore show={storeModal} handleClose={handleClose} />
      ) : null}
      {storeUpdateModal ? (
        <CreateStore show={storeUpdateModal} handleClose={handleClose}  data={data}/>
      ) : null}
    {errorStore ?(
      <ErrorBoundaryStore/>
      ) : (
        <>
        <div className="d-flex flex-direction-row justify-content-between px-3">
        <div className=" align-items-start d-flex flex-direction-row px-2 mb-2"style={{gap:15,color:'#009ef7',fontSize:'24px'}}>
        <KTIcon iconName="shop" className="fs-1 mt-2" />
          <span className='card-label fw-bold'>Stores</span>
          </div>
          <div className=" d-flex justify-content-end">
          <button
                type="button"
                className="btn mb-5 fw-bold" style={{backgroundColor:'#c2e5f9',color:'#009ef7'}}
                 onClick={handleAdminButton}
              >
                 <KTIcon iconName="plus" className="fs-3" />
                    Create
              </button>
              
          </div>
          </div>  
    <div className='card mb-5 mb-xl-10'>
      {/* begin::Header */}
      {/* <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start  flex-row'style={{gap:5}}>
        <KTIcon iconName="shop" className="fs-1" />
          <span className='card-label fw-bold fs-3 mb-1'>Stores</span>
         </h3>
        <div className='card-toolbar'> */}
          {/* begin::Menu */}
          {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button> */}
          {/* <button
                type="button"
                className="btn" style={{backgroundColor:'#f1faff',color:'#009ef7'}}
                 onClick={handleAdminButton}
              >
                 <KTIcon iconName="plus" className="fs-3" />
                    Create
              </button>
        </div>
      </div> */}

      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 table-hover gs-0 gy-3'>
          {loading ? (
                    <div
                      className=" fw-bold fs-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap:40
                      }}
                    >   
                    <ThreeDots
                        height="80" 
                        width="80" 
                        radius="9"
                        color="#ADC4CE" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        visible={true}
                        />
                      Loading...
                    </div>
                  ) : (
                    <>
            <thead>
              <tr className='fw-bold fs-4'style={{color: "#454548"}}>
                <th className='text-center'>#</th>
                <th className='text-center'>Logo</th>
                <th className='text-center'>Name</th>
                <th className='text-center'>Description</th>
                <th className='text-center'>Address Line 1</th>
                <th className='text-center'>Address Line 2</th>
                <th className='text-center'>Created At</th>
                {/* <th className='text-center'>Created BY</th> */}
                <th className='text-center'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
             {!storeData.getStores ? (
                    <div
                      className=" fw-bold fs-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 105,
                      }}
                    >
                      Data not found!!
                    </div>
                  ) : (
                    <>
                      
                      {storeData.getStores.length > 0 && 
                      storeData.getStores?.map(
                          (val: IStores, index: any) => {
                            return (
                              <tr key={index} className="text-center fs-6"> 
   
                
                <td className=''>
                 {val.id}
                </td>
                <td className=''>
                {val.url?<img
                src={val.url}
                className="profile-image"
                alt="logo"
              /> :
               <img
                src={require("../../../images/Emptyplaceholder.png")}
                className="profile-image"
                alt="logo"
              /> }
              {/* <div className="image-input image-input-outline image-input-circle" id="kt_image_3">
	<div className="image-input-wrapper"></div>

	<label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
	<KTIcon
                                          iconName="pencil"
                                          className="fs-3"
                                        />
		<input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg"/>
		<input type="hidden" name="profile_avatar_remove"/>
	</label>

	<span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
  <KTIcon className='fs-1' iconName='cross' />
	</span>
</div>  */}
                </td>
                <td className=''>
                 {val.name}       
                </td>
               
                <td className=''>
                {val.desc}  
                </td>
                <td className=''>
                {val.address1}  
                </td>
                <td className=''>
                {val.address2}  
                </td>
                <td className=''>
                {moment(val.createdAt).format("MMM DD YYYY h:mm A")}                      
                </td>
                {/* <td className=''>
                {val.created_by}  
                </td> */}
                <td  style={{display:"flex",flexDirection:'row'}}className=''>
                  {/* <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTIcon iconName='switch' className='fs-3' />
                  </a>*/}
                  <a
                    // href='#'
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"style={{color:'green',backgroundColor:'#d4ebde'}}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-trigger="hover"
                    title="Edit"
                    onClick={() => handleUpdate(val)}
                    >
                    <KTIcon iconName='pencil' className='fs-3' />
                  </a> 
                  <a onClick={(e) => handleNavigate(val)}  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <KTIcon iconName='eye' className='fs-3' />
                  </a>
                </td>
              </tr> 
                       ) }
                        )}
                    </>
                  )}
            </tbody>
            </>
                  )}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <div className="card-footer border-0 pt-5  d-flex justify-content-between">
           <div className=" ">
            <span className="fs-3"> Total - {totalData}</span>
            <span className='text-dark px-1 fs-6 lh-0'>Stores</span>
           </div>
           <div className="d-flex px-15 "style={{color:'black'}}>
           <ReactPaginate
            previousLabel={ <span
            
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Previous"
            >
               <KTIcon iconName='arrow-left' className='fs-1 fw-bold' /> </span>}
            nextLabel={<span
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Next"
            >
            <KTIcon iconName='arrow-right' className='fs-1 fw-bold' /></span>}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel={"..."}
            breakClassName="break-me"
            breakLinkClassName="page-link"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
            />
           </div>
          </div>
    </div>
    </>
    )} 
    </>
  )
}

export {Stores}