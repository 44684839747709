import { Ioffers } from "../../types/commonTypes";
import { put, takeEvery} from "redux-saga/effects";
import { redTypes } from "../../constants/reduxTypes";
import { offersDeleteApi } from "../../services/commonApiCall";

function* offersDelete(param: object) {
  console.log("offersDelete", param);
  try {
    const response: Ioffers = yield offersDeleteApi(param);
    if (response) {
      console.log(" delete response", response);
      yield put({ type: redTypes.DELETE_OFFERS_SUCCESS, payload: response });
    }
  } catch (e) {
    console.log("error", e);
  }
}
export default function* offersDeleteSaga(): any {
  return yield takeEvery(redTypes.DELETE_OFFERS_REQUEST, offersDelete);
}
