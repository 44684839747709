import { redTypes } from "../constants/reduxTypes";
import { ICommonState } from "../types/commonTypes";

const initialState: ICommonState = {
  loading: false,
  response: [],
  customerSuccess: [],
  adminSuccess: [],
  customerOverall: [],
  adminCreationSuccess: [],
  error: "",
  roleSuccess: [],
  getRoles: [],
  Roles: [],
  sendPassword: [],
  sendOtp: [],
  customerBills: [],
  updateAdminUser: false,
  billsDashboard: [],
  billsDownload: [],
  storeSuccess: [],
  storeCreationSuccess: [],
  settingSuccess: [],
  settingUpdationSuccess: [],
  chatSuccess: [],
  chatCreateSuccess: [],
  apiKeysSuccess: [],
  keysGenerateSuccess: {},
  offerSuccess: [],
  offersCreationSuccess: [],
  errorCode: 0,
  errorCodeBills: 0,
  errorBills: "",
  errorOverAllCustomer: "",
  errorCodeOverAllCustomer: 0,
  errorAdminUser: "",
  errorCodeAdminUser: 0,
  errorGetRoles: "",
  errorCodeRoles: 0,
  errorAdminCreate: "",
  errorCodeAdminCreate: 0,
  errorCustomerBills: "",
  errorCodeCustomerBills: 0,
  errorStore: "",
  errorCodeStore: 0,
  errorSetting: "",
  errorCodeSetting: 0,
  errorChat: "",
  errorCodeChat: 0,
  errorApiKeys: "",
  errorCodeApiKeys: 0,
  errorOffers: "",
  errorCodeOffers: 0,
  errorCreationSuccess: '',
  errorCodeCreationSuccess: 0,
  multi_image:'',
  gallery:'',
  reviewLoader:false,
  reviews:""

};

export const billListReducer = (state = initialState, action: any) => {
  // console.log("actionBill", initialState);
  switch (action.type) {
    case redTypes.BILL_LIST_TABLE_REQUEST:
      return { ...state, loading: true };
    case redTypes.BILL_LIST_TABLE_SUCCESS:
      return { ...state, loading: false, response: action.payload };
    case redTypes.BILL_LIST_TABLE_FAILURE:
      return {
        ...state,
        loading: false,
        errorBills: action.errorMessage,
        errorCodeBills: action.errorCode,
      };
    case redTypes.BILLS_INVIOCE_SUCCESS:
      return { ...state, loading: false, billsInvoice: action.payload };
    case redTypes.BILLS_INVIOCE_FAILURE:
      return {
        ...state,
        loading: false,
        errorBillsInvoice: action.errorMessage,
        errorCodeBillsInvoice: action.errorCode,
      };
    case redTypes.CUSTOMER_DETAIL_REQUEST:
      return { ...state, loading: true };
    case redTypes.CUSTOMER_DETAIL_SUCCESS:
      return { ...state, loading: false, customerSuccess: action.payload };
    case redTypes.CUSTOMER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.errorMessage,
        errorCode: action.errorCode,
      };
    case redTypes.RESET_CUSTOMER_DATA:
      return { ...state, loading: false, customerSuccess: null };
    case redTypes.ADMIN_USER_REQUEST:
      return { ...state, loading: true };
    case redTypes.ADMIN_USER_SUCCESS:
      return { ...state, loading: false, adminSuccess: action.payload };
    case redTypes.ADMIN_USER_GET_SUCCESS:
      return { ...state, loading: false, adminUserGetSuccess: action.payload };
    case redTypes.ADMIN_USER_FALIURE:
      return {
        ...state,
        loading: false,
        errorAdminUser: action.errorMessage,
        errorCodeAdminUser: action.errorCode,
      };
    case redTypes.CUSTOMER_OVERALL_DETAIL_REQUEST:
      return { ...state, loading: true };
    case redTypes.CUSTOMER_OVERALL_DETAIL_SUCCESS:
      return { ...state, loading: false, customerOverall: action.payload };
    case redTypes.CUSTOMER_OVERALL_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        errorOverAllCustomer: action.errorMessage,
        errorCodeOverAllCustomer: action.errorCode,
      };
    case redTypes.SET_STATE:
      return { ...state, billItems: action.payload.state };
    case redTypes.CUSTOMER_SET_STATE:
      return { ...state, customerState: action.payload.customerState };
    case redTypes.RESET_SET_STATE:
      return {
        ...state,
        billItems: null,
        //customerState:null,
        // billsInvoice:null,
        customerBills: "",
        error: null,
        errorCode: null,
        errorBills: null,
        errorCodeBills: null,
        errorCodeBillsInvoice: "",
        errorBillsInvoice: "",
        errorOverAllCustomer: null,
        errorCodeOverAllCustomer: null,
        errorAdminUser: null,
        errorCodeAdminUser: null,
        errorGetRoles: null,
        errorCodeRoles: null,
        errorAdminCreate: "",
        errorCodeAdminCreate: "",
        errorCustomerBills: "",
        errorCodeCustomerBills: "",
        errorStore: "",
        errorCodeStore: "",
        errorSetting: "",
        errorCodeSetting: "",
        errorSettingUpdate: "",
        errorCodeSettingUpdate: "",
        errorChat: "",
        errorCodeChat: null,
        errorApiKeys: "",
        errorCodeApiKeys: "",
        errorOffers: "",
        errorCodeOffers: "",
      };
    case redTypes.ADMIN_USER_CREATION_REQUEST:
      return { ...state, loading: true };
    case redTypes.ADMIN_USER_CREATION_SUCCESS:
      return { ...state, loading: false, adminCreationSuccess: action.payload };
    case redTypes.ADMIN_USER_CREATION_FAILURE:
      return {
        ...state,
        loading: false,
        errorAdminCreate: action.errorMessage,
        errorCodeAdminCreate: action.errorCode,
      };
    case redTypes.RESET_ADMIN_USER:
      return { ...state, loading: false, adminCreationSuccess: null };
    case redTypes.ROLE_REQUEST:
      return { ...state, loading: true };
    case redTypes.ROLE_SUCCESS:
      return { ...state, loading: false, roleSuccess: action.payload };
    case redTypes.ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.errorMessage,
        errorCode: action.errorCode,
      };
    case redTypes.GET_ROlES_SUCCESSFULLY:
      return { ...state, loading: false, getRoles: action.payload };
    case redTypes.GET_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        errorGetRoles: action.errorMessage,
        errorCodeRoles: action.errorCode,
      };
    case redTypes.ADD_ROLES_SUCCESSFULLY:
      return { ...state, loading: false, Roles: action.payload };
    case redTypes.DELETE_ROLE_SUCCESSFULLY:
      return { ...state, loading: false, deleteRoles: true };
    case redTypes.CLEAR_ROLES:
      return { ...state, loading: false, Roles: "", deleteRoles: false };
    case redTypes.CHANGE_PASSWORD_WITH_EMAIL_SUCCESS:
      return { ...state, loading: false, sendPassword: true };
    case redTypes.CLEAR_CHANGE_PASSWORD_WITH_EMAIL:
      return { ...state, loading: false, sendPassword: false };
    case redTypes.CHANGE_PASSWORD_WITH_OTP_SUCCESS:
      return { ...state, loading: false, sendOtp: true };
    case redTypes.CLEAR_PASSWORD_WITH_OTP:
      return { ...state, loading: false, sendOtp: false };
    case redTypes.UPDATE_ADMIN_USERS_SUCCESSFULLY:
      return { ...state, loading: false, updateAdminUser: true };
    case redTypes.CLEAR_UPDATE_ADMIN_USERS:
      return { ...state, loading: false, updateAdminUser: false };
    case redTypes.CUSTOMER_BILLS_DETAILS_REQUEST:
      return { ...state, loading: true };
    case redTypes.CUSTOMER_BILLS_DETAILS_SUCCESS:
      return { ...state, loading: false, customerBills: action.payload };
    case redTypes.CUSTOMER_BILLS_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorCustomerBills: action.errorMessage,
        errorCodeCustomerBills: action.errorCode,
      };
    case redTypes.BILLS_DASHBOARD_SUCCESS:
      return { ...state, billsDashboard: action.payload };
    case redTypes.BILLS_DOWNLOAD_SUCCESS:
      return { ...state, billsDownload: action.payload };
      case redTypes.STORE_REQUEST:
        return { ...state, loading: true };
    case redTypes.STORE_SUCCESS:
      return { ...state, loading: false, storeSuccess: action.payload };
    case redTypes.STORE_FAILURE:
      return {
        ...state,
        loading: false,
        errorStore: action.errorMessage,
        errorCodeStore: action.errorCode,
      };
    case redTypes.STORE_CREATION_SUCCESS:
      return { ...state, loading: false, storeCreationSuccess: action.payload };
    case redTypes.STORE_CREATION_FAILURE:
      return {
        ...state,
        loading: false,
        errorStoreCreation: action.errorMessage,
        errorCodeStoreCreation: action.errorCode,
      };
    case redTypes.CLEAR_STORE_CREATION:
      return { ...state, loading: false, storeCreationSuccess: "" };

              case redTypes.IMAGE_UPLOADER_REQUEST:
              return { ...state, loading:true };
              case redTypes.IMAGE_UPLOADER_SUCCESS:
                return { ...state, loading: false,ImageUploadSuccess: action.payload };
                case redTypes.MULTI_IMAGE_UPLOADER_SUCCESS:
                  return { ...state, loading: false,multi_image: action.payload };
                  case redTypes.GET_GALLERY_SUCCESSFULLY:
                  return { ...state, loading: false,gallery: action.payload };
                  case redTypes.GET_REVIEW_REQUEST:
                    return { ...state, reviewLoader: true };
                  case redTypes.GET_REVIEW_SUCCESSFULLY:
                    return { ...state, reviewLoader: false,reviews: action.payload };
              case redTypes.IMAGE_UPLOADER_FAILURE:
                return {
                  ...state,
                  loading: false,
                  errorImageUpload: action.errorMessage,
                  errorCodeImageUpload: action.errorCode,
                };
                case redTypes.CLEAR_IMAGE_UPLOADER:
                  return { ...state, loading: false, ImageUploadSuccess: "" };
              case redTypes.SETTING_SUCCESS:
          return { ...state, loading: false, settingSuccess: action.payload };
        case redTypes.SETTING_FAILURE:
          return {
            ...state,
            loading: false,
            errorSetting: action.errorMessage,
            errorCodeSetting: action.errorCode,
          };
          case redTypes.SETTING_UPDATION_SUCCESS:
            return { ...state, loading: false, settingUpdationSuccess: action.payload, settingStatus:true };
          case redTypes.SETTING_UPDATION_FAILURE:
            return {
              ...state,
              loading: false,
              errorSettingUpdate: action.errorMessage,
              errorCodeSettingUpdate: action.errorCode,
            };
            case redTypes.CLEAR_SETTING_UPDATION:
              return { ...state, loading: false,settingUpdationSuccess: '',  settingStatus:false  };

    case redTypes.CHAT_SUCCESS:
      return { ...state, loading: false, chatSuccess: action.payload };
    case redTypes.CHAT_FAILURE:
      return {
        ...state,
        loading: false,
        errorchat: action.errorMessage,
        errorCodeChat: action.errorCode,
      };
    case redTypes.CHAT_CREATE_SUCCESS:
      return { ...state, loading: false, chatCreateSuccess: action.payload };
    case redTypes.CLEAR_CHAT_CREATION:
      return {
        ...state,
        loading: false,
        errorChatCreate: action.errorMessage,
        errorCodeChatCreate: action.errorcode,
      };

    case redTypes.API_KEYS_SUCCESS:
      return { ...state, loading: false, apiKeysSuccess: action.payload };
    case redTypes.API_KEYS_FAILURE:
      return {
        ...state,
        loading: false,
        errorApiKeys: action.errorMessage,
        errorCodeApiKeys: action.errorCode,
      };
    case redTypes.API_KEYS_GENERATE_SUCCESS:
      return { ...state, loading: false, keysGenerateSuccess: action.payload };
    case redTypes.API_KEYS_GENERATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorkeysGenerate: action.errorMessage,
        errorCodekeysGenerate: action.errorCode,
      };
    case redTypes.DELETE_API_KEYS_SUCCESS:
      return { ...state, loading: false, keysGenerateDelete: true };
    case redTypes.CLEAR_APIKEYS_STATE:
      return {
        ...state,
        loading: false,
        keysGenerateDelete: false,
        keysGenerateSuccess: "",
      };
    case redTypes.OFFERS_SUCCESS:
      return { ...state, loading: false, offerSuccess: action.payload };
    case redTypes.OFFERS_FAILURE:
      return {
        ...state,
        loading: false,
        errorOffers: action.errorMessage,
        errorCodeOffers: action.errorCode,
      };
    case redTypes.OFFERS_CREATION_SUCCESS:
      return {
        ...state,
        loading: false,
        offersCreationSuccess: action.payload,
      };
    case redTypes.OFFERS_CREATION_FAILURE:
      return {
        ...state,
        loading: false,
        errorCreationSuccess: action.errorMessage,
        errorCodeCreationSuccess: action.errorCode,
      };

    case redTypes.DELETE_OFFERS_SUCCESS:
      return { ...state, loading: false, offersDelete: true };
    case redTypes.CLEAR_OFFERS_CREATION:
      return {
        ...state,
        loading: false,
        offersCreationSuccess: "",
        offersDelete: false,
      };
    default:
      return state || [];
  }
};
