import React, { FC, useState } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useDispatch, useSelector } from "react-redux";
import { redTypes } from "../../../constants/reduxTypes";
import { ICustomerOverallDetail } from "../../../types/commonTypes";
import { CustomerPopUp } from "./customerPopUp";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "./ErrorBoundary";
import ReactPaginate from 'react-paginate';
import "./index.css";
import { ThreeDots } from "react-loader-spinner";

export const CustomerDetails: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [data, setData] = useState<ICustomerOverallDetail>();
  const [currentPages, setCurrentPages] = useState<number>(0)
  const [pageCount, setPageCount] = useState<number>(0)
  const [totalData, setTotalData] = useState<number>(0)
  const loginSuccess: any = useSelector<any>(
    (state) => state.authReducer?.loginSuccess
  );
  const loading: any = useSelector<any>(
    (state) => state.billListReducer?.loading
  );
  const customerDetailData: any = useSelector<any>(
    (state) => state.billListReducer?.customerOverall
  );
   //console.log("customerDetailData", customerDetailData);
  const customerBillsData: any = useSelector<any>(
    (state) => state.billListReducer?.customerBills
  );
  //  console.log("custmorlll", customerBillsData);
  const errorOverAllCustomer: any = useSelector<any>(
    (state) => state.billListReducer?.errorOverAllCustomer
  );
  // console.log("errorOverAllCustomer", errorOverAllCustomer);
 
  // const customerData: any = useSelector<any>(
  //   (state) => state.billListReducer?.customerState
  // );
  // console.log("bilDetails", customerData);

  React.useEffect(() => {
    dispatch({
      type: redTypes.CUSTOMER_OVERALL_DETAIL_REQUEST,
      payload: {id:loginSuccess.id,currentPages:currentPages},
    });
  }, [currentPages]);

  const handleClick = (row: ICustomerOverallDetail) => {
   
    dispatch({
      type: redTypes.CUSTOMER_BILLS_DETAILS_REQUEST,
      payload: {id:row.user_id,domain: loginSuccess.domain},
    });
    navigate("/customer/bills", { state: row });
  };

  // const handleClose = () => {
  //   setShowModal(false);
  // };
  React.useEffect(() => {
    if(customerDetailData){
    const perPage = customerDetailData.count
          setTotalData(perPage)
          const total = Math.ceil(perPage / 10)
          setPageCount(total)
    }
  }, [customerDetailData]);
  const handlePageClick = (e:any) => {
    const selectedPage = e.selected * 1;
    setCurrentPages(selectedPage)
  };

  return (
    <>
      {/* {showModal ? (
        <CustomerPopUp show={showModal} handleClose={handleClose} data={data} />
      ) : null} */}
      {errorOverAllCustomer ? (
        <ErrorBoundary/>
      ) : (
        <>
        <div className=" align-items-start d-flex flex-direction-row px-2 mb-2"style={{gap:15,color:'#009ef7',fontSize:'24px'}}>
            {/* <h3 className="card-title align-items-start flex-row"style={{gap:5}}> */}
            <KTIcon iconName="profile-user" className="fs-1 mt-2" />
              <span className="card-label fw-bold">
                Customers
              </span>
            {/* </h3> */}
          </div>
        <div className="card mb-5 mb-xl-8">
          
          <div className="card-body py-3">
            <div className="table-responsive py-5">
              <table className="table table-row-dashed table-row-gray-300 table-hover gy-5">
              {loading ? (
                    <div
                      className=" fw-bold fs-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >   
                    <ThreeDots 
                        height="80" 
                        width="80" 
                        radius="9"
                        color="#ADC4CE" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        visible={true}
                        />
                      Loading...
                    </div>
                  ) : (
                    <>
                <thead>
                  <tr className="fw-bold fs-4" style={{color: "#454548"}}>
                    <th className="text-center">#</th>
                    <th className="text-center">Email</th>
                    <th className="text-center">First Name</th>
                    <th className="text-center">Last Name</th>
                    <th className="text-center">Locality</th>
                    <th className="text-center">State</th>
                    <th className="text-center">City</th>
                    <th className="text-center">Country</th>
                    <th className="text-center">Created At</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {!customerDetailData.getUserMerchant ? (
                    <div
                      className=" fw-bold fs-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 105,
                      }}
                    >
                      Data not found!!
                    </div>
                  ) : (
                    <>
                      {customerDetailData.getUserMerchant.length > 0 &&
                        customerDetailData.getUserMerchant?.map(
                          (val: ICustomerOverallDetail | any) => {
                            return (
                              <tr className="text-center fs-6">
                                <td className="">{val.id}</td>
                                <td className="">
                                  {val.user.email}
                                </td>
                                <td className="">
                                  {val.user.firstname}
                                </td>
                                <td className=" ">
                                  {val.user.lastname}
                                </td>
                                <td className=" ">
                                  {val.user.locality}
                                </td>
                                <td className=" ">
                                  {val.user.state}
                                </td>
                                <td className=" ">{val.user.city}</td>
                                <td className="">
                                  {val.user.country}
                                </td>
                                <td className="">
                                  {moment(val.user.createdAt).format(
                                    "MMM DD YYYY h:mm A"
                                  )}
                                </td>
                                <td className="">
                                  <div className="d-flex justify-content-center flex-shrink-0">
                                    <a
                                      //href="#"
                                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"style={{backgroundColor:'#e8e8eb'}}
                                      data-bs-toggle="tooltip "
                                      data-bs-trigger="hover"
                                      // data-bs-toggle="modal"
                                      title="View"
                                      onClick={(e) => handleClick(val)}
                                    >
                                      <KTIcon iconName="eye" className="fs-3" />
                                    </a>
                                    {/* <a
                                      href="#"
                                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      data-bs-trigger="hover"
                                      title="Edit"
                                    >
                                      <KTIcon
                                        iconName="pencil"
                                        className="fs-3"
                                      />
                                    </a> */}
                                    {/* <a
                                      href="#"
                                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      data-bs-trigger="hover"
                                      title="Delete"
                                    >
                                      <KTIcon
                                        iconName="trash"
                                        className="fs-3"
                                      />
                                    </a> */}
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </>
                  )}
                </tbody>
                </>
                  )}
              </table>
            </div>
          </div>
          <div className="card-footer border-0 pt-5  d-flex justify-content-between">
           <div className=" ">
            <span className="fs-3"> Total - {totalData}</span>
            <span className='text-dark px-1 fs-6 lh-0'>Customers</span>
           </div>
           <div className="d-flex px-15 "style={{color:'black'}}>
           <ReactPaginate
            previousLabel={ <span
            
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Previous"
            >
               <KTIcon iconName='arrow-left' className='fs-1 fw-bold' /> </span>}
            nextLabel={<span
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Next"
            >
            <KTIcon iconName='arrow-right' className='fs-1 fw-bold' /></span>}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel={"..."}
            breakClassName="break-me"
            breakLinkClassName="page-link"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
            />
           </div>
          </div>
        </div>
        </>
      )}
    </>
  );
};
{/* <ReactPaginate
            previousLabel={
            <span
            
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Previous"
            >
               <KTIcon iconName='arrow-left' className='fs-1 fw-bold' /> </span>}
            nextLabel={ <span
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
              data-bs-toggle="tooltip "
              title="Previous"
            >
            <KTIcon iconName='arrow-right' className='fs-1 fw-bold' /></span>}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}

            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            // subContainerClassName={"pages pagination"}
            activeClassName={"active"} /> */}